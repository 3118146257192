import { Destination } from '../../../../../../../typings/Destination.interface';
import LINKS from '../../../../configs/links';
import { Truncatable } from '../../../common/Truncatable';
import { ClickableArea, ClickableAreaProps } from '../../../common/base/Button';
import { StyledCard } from '../../../common/base/Card';
import Icon, { IconName } from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import { Div } from '../../../common/helpers/StyledUtils';

interface Props extends Pick<ClickableAreaProps, 'to' | 'onClick'> {
  destination: Destination;
}

export const getDestinationIcon = (destination: Destination): IconName => {
  if (!destination.url) {
    return 'terminal';
  }
  if (destination.url === LINKS.mock_api) {
    return 'api';
  }
  return 'http';
};

const DestinationCard: React.FC<Props> = ({ destination, onClick, to }) => {
  return (
    <StyledCard muted={!!destination.disabled_at} overflow_hidden>
      <ClickableArea
        p={{ x: 2, y: 1.5 }}
        flex={{ justify: 'space-between', align: 'center' }}
        onClick={onClick}
        to={to}>
        <Div flex={{ align: 'center', justify: 'space-between' }} w={100}>
          <Div flex={{ align: 'center' }} w={100}>
            <Icon icon={getDestinationIcon(destination)} muted left />
            <Text w={100} ellipsis monospace muted={!!destination.disabled_at}>
              <Truncatable text={destination.name} />
            </Text>
          </Div>
          {destination.disabled_at && (
            <Tooltip tooltip={`Disabled`} placement="bottom-end">
              <Icon muted icon="disable" right />
            </Tooltip>
          )}
          {destination.rate_limit && !destination.disabled_at && (
            <Tooltip
              placement="bottom-end"
              tooltip={
                destination.rate_limit_period === 'concurrent'
                  ? `Max delivery rate of ${destination.rate_limit} concurrent`
                  : `Max delivery rate of ${destination.rate_limit} per ${destination.rate_limit_period}`
              }>
              <Icon icon="rate_limit" right muted />
            </Tooltip>
          )}
        </Div>
      </ClickableArea>
    </StyledCard>
  );
};

export default DestinationCard;
