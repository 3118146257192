import { useContext, useMemo } from 'react';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { Destination } from '../../../../../../../../typings/Destination.interface';

import { IssueType } from '../../../../../../../../typings/Issue.interface';
import { IssueTrigger } from '../../../../../../../../typings/IssueTrigger.interface';
import { Transformation } from '../../../../../../../../typings/Transformation.interface';
import { Webhook } from '../../../../../../../../typings/Webhook.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { issue_type_configs } from '../../../../../utils/issues';
import WithLoader from '../../../../common/WithLoader';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Container from '../../../../common/base/Container';
import Icon from '../../../../common/base/Icon';
import Skeleton from '../../../../common/base/Skeleton';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import IssueTriggersGroup from './IssueTriggersGroup';
import { APIList } from '../../../../../../../../typings/API.interface';
import Divider from '../../../../common/base/Divider';

export interface IssueTriggersRenderData {
  transformations_by_id: Record<string, Transformation>;
  webhooks_by_id: Record<string, Webhook>;
  destinations_by_id: Record<string, Destination>;
}

const IssueTriggersListSkeleton: React.FC = () => {
  return (
    <Div p={{ b: 4 }}>
      {Object.entries(issue_type_configs).map(([key, { icon }]) => {
        return (
          <>
            <Div p={{ t: 14, x: 8, b: 2 }} key={key}>
              <Div>
                <Div flex={{ align: 'center' }}>
                  <Icon icon={icon} left />
                  <Text heading as="h2" m={0}>
                    {issue_type_configs[key].label} Issue Triggers
                  </Text>
                </Div>
                <Text as="p" muted m={0}>
                  {issue_type_configs[key].description}
                </Text>
              </Div>
              <Divider m={{ t: 2 }} />
              <Div m={{ y: 4 }}>
                <StyledCard>
                  <StyledCardSection
                    p={{ x: 2, y: 3.5 }}
                    flex={{ justify: 'space-between', align: 'center' }}>
                    <Div flex={{ justify: 'space-between', align: 'center' }}>
                      <Skeleton
                        m={{ r: 2, l: 2 }}
                        w={{ px: 20 }}
                        h={{ px: 20 }}
                        border_radius={'normal'}
                      />
                      <Skeleton
                        m={{ r: 2 }}
                        w={{ px: 400 }}
                        h={{ px: 20 }}
                        border_radius={'normal'}
                      />
                    </Div>
                    <Skeleton w={{ px: 20 }} h={{ px: 20 }} border_radius={'normal'} m={{ r: 2 }} />
                  </StyledCardSection>
                </StyledCard>
              </Div>
            </Div>
          </>
        );
      })}
    </Div>
  );
};

const extractResourceIdFromIssueTriggers = (
  issue_triggers: IssueTrigger[],
  property: 'connections' | 'transformations' | 'destinations',
): string[] => {
  return Array.from(
    new Set(
      issue_triggers.reduce(
        (arr, issue) =>
          Array.isArray(issue.configs[property]) ? [...arr, ...issue.configs[property]] : arr,
        [],
      ),
    ),
  );
};

const TriggersList: React.FC<{
  types?: IssueType[];
  status?: ('enabled' | 'disabled')[];
}> = ({ types, status }) => {
  const { HookdeckAPI } = useContext(GlobalContext);

  const filters = {
    order_by: ['type', 'created_at'],
    dir: ['asc', 'asc'],
    limit: 50,
    type: types || undefined,
    disabled_at:
      status && status.length === 1 ? (status[0] === 'enabled' ? null : { any: true }) : undefined,
  };

  const {
    data: issue_triggers_pages,
    size,
    setSize,
    isValidating,
    mutate,
  } = useSWRInfinite(
    (_, previous_data) =>
      APIMethodKeys.issue_triggers.list({ ...filters, next: previous_data?.pagination.next }),
    (url) => {
      return HookdeckAPI.request<APIList<IssueTrigger>>('GET', url);
    },
  );

  const issue_triggers: IssueTrigger[] = useMemo(
    () => issue_triggers_pages?.reduce((arr, page) => [...arr, ...page.models], []) || [],
    [issue_triggers_pages],
  );

  const webhook_ids = useMemo(
    () =>
      issue_triggers
        ? extractResourceIdFromIssueTriggers(
            issue_triggers.filter(({ type }) => type === 'delivery'),
            'connections',
          )
        : [],
    [issue_triggers],
  );

  const destination_ids = useMemo(
    () =>
      issue_triggers
        ? extractResourceIdFromIssueTriggers(
            issue_triggers.filter(({ type }) => type === 'backpressure'),
            'destinations',
          )
        : [],
    [issue_triggers],
  );

  const transformation_ids = useMemo(
    () =>
      issue_triggers
        ? extractResourceIdFromIssueTriggers(
            issue_triggers.filter(({ type }) => type === 'transformation'),
            'transformations',
          )
        : [],
    [issue_triggers],
  );

  const { data: webhooks } = useSWR(
    webhook_ids.length > 0 && APIMethodKeys.webhooks.list({ id: webhook_ids }),
    () => HookdeckAPI.webhooks.list({ id: webhook_ids }),
  );

  const { data: destinations } = useSWR(
    destination_ids.length > 0 && APIMethodKeys.destinations.list({ id: destination_ids }),
    () => HookdeckAPI.destinations.list({ id: destination_ids }),
  );

  const { data: transformations } = useSWR(
    transformation_ids.length > 0 && APIMethodKeys.transformations.list({ id: transformation_ids }),
    () => HookdeckAPI.transformations.list({ id: transformation_ids }),
  );

  const webhooks_by_id =
    webhooks?.models.reduce((object, webhook) => ({ ...object, [webhook.id]: webhook }), {}) || {};

  const destinations_by_id =
    destinations?.models.reduce(
      (object, destination) => ({ ...object, [destination.id]: destination }),
      {},
    ) || {};

  const transformations_by_id =
    transformations?.models.reduce(
      (object, transformation) => ({ ...object, [transformation.id]: transformation }),
      {},
    ) || {};

  const render_data: IssueTriggersRenderData = {
    webhooks_by_id,
    transformations_by_id,
    destinations_by_id,
  };

  const loading =
    !issue_triggers ||
    (webhook_ids.length > 0 && !webhooks) ||
    (destination_ids.length > 0 && !destinations) ||
    (transformation_ids.length > 0 && !transformations);

  return (
    <Container large h={100}>
      <WithLoader loading={loading} loading_element={<IssueTriggersListSkeleton />}>
        {issue_triggers && (
          <>
            <Div p={{ b: 4 }}>
              {Object.keys(issue_type_configs)
                .filter((key: IssueType) => (types ? types.includes(key) : true))
                .map((key: IssueType) => {
                  const group = issue_triggers.filter((trigger) => trigger.type === key);
                  return (
                    <Div p={{ t: 14, b: 2 }} key={key}>
                      <IssueTriggersGroup
                        onIssueTriggerCreated={(issue_trigger) => {
                          mutate((data) => {
                            const new_data = [...(data || [])];
                            new_data[0].models.push(issue_trigger);
                            new_data[0].count += 1;
                            return new_data;
                          });
                        }}
                        issue_triggers={group}
                        type={key}
                        render_data={render_data}
                      />
                    </Div>
                  );
                })}
            </Div>
            {issue_triggers_pages &&
              issue_triggers_pages[issue_triggers_pages.length - 1].pagination.next && (
                <Div flex={{ justify: 'center' }} p={2} m={{ b: 8 }}>
                  <Button icon="history" onClick={() => setSize(size + 1)} minimal primary>
                    View more triggers
                  </Button>
                </Div>
              )}
          </>
        )}
      </WithLoader>
    </Container>
  );
};

export default TriggersList;
