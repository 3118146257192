import { Formik } from 'formik';
import { useContext } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import Button, { PlaceholderButton } from '../../../../common/base/Button';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import Modal from '../../../../common/Modal';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { DashboardContext } from '../../DashboardContext';
import { TeamMember, TeamRole } from '../../../../../../../../typings/TeamMember.interface';
import { createTeamRoleOptions } from './TeamAccessControl';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import useSWR from 'swr';
import { organization_levels_by_role } from '../../../../../../../../domain/roles';

interface Props {
  members: TeamMember[];
  onMembersAdded: (members: TeamMember[]) => void;
}

const AddTeamMemberModal: React.FC<Props> = ({ members, onMembersAdded }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { team, subscription, organization } = useContext(DashboardContext);
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();

  const { data: org_members } = useSWR(
    APIMethodKeys.organizations.listMembers(organization!.id),
    () => HookdeckAPI.organizations.listMembers(),
  );

  const onClose = () => {
    history.push({
      pathname: '/settings/project/access',
      search: location.search,
      state: { scroll: false },
    });
  };

  const validate = (values) => {
    if (!values[0]) {
      return { 0: 'Required' };
    }
    return {};
  };

  const onSubmit = async (values) => {
    const members: { user_id: string; role: TeamRole }[] = [];

    for (let i = 0; i < Object.keys(values).filter((k) => k.indexOf('role') >= 0).length; i++) {
      const user_id = values[i];
      const role = values[`role${i}`];

      if (user_id && role) {
        members.push({ user_id, role });
      }
    }

    try {
      const new_members = await HookdeckAPI.teams.addMembers(members);
      onMembersAdded && onMembersAdded(new_members);
      onClose();
    } catch (err) {
      addToast('error', err.message);
    }
  };

  return (
    <Formik initialValues={{ role0: 'member' }} onSubmit={onSubmit} validate={validate}>
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Modal
            title="Add members to project"
            submit_label="Add to project"
            cancel_label="Cancel"
            onSubmit={props.handleSubmit}
            onCancel={onClose}
            onClose={onClose}
            is_submitting={props.isSubmitting}>
            <Div>
              {new Array(Object.keys(props.values).filter((k) => k.indexOf('role') >= 0).length)
                .fill(null)
                .map((_, i) => (
                  <Div flex={{ gap: 2 }} key={i}>
                    <SelectInput
                      block
                      name={`${i}`}
                      flex={{ grow: true, direction: 'column' }}
                      search
                      placeholder="Select organization member"
                      dropdown={{
                        title: 'Organization Members',
                        parent_width: true,
                        placement: 'bottom-end',
                      }}
                      options={
                        org_members
                          ?.filter((member) => {
                            if (
                              organization_levels_by_role[member.role] >=
                              organization_levels_by_role.admin
                            ) {
                              return false;
                            }
                            if (members.find((m) => m.user_id === member.user_id)) {
                              return false;
                            }
                            if (props.values[i] === member.user_id) {
                              return true;
                            }
                            if (Object.values(props.values).includes(member.user_id)) {
                              return false;
                            }
                            return true;
                          })
                          ?.map((member) => ({
                            label: member.user_name,
                            description: member.user_email,
                            value: member.user_id,
                          })) || []
                      }
                    />
                    <SelectInput
                      name={`role${i}`}
                      dropdown={{
                        title: 'Roles',
                        w: { px: 200 },
                        parent_width: false,
                        placement: 'bottom-end',
                      }}
                      options={createTeamRoleOptions(
                        !!subscription?.features?.includes('viewer_role') ||
                          !!organization?.feature_flags?.viewer_role,
                      )}
                    />
                    <Div>
                      <Button
                        dropdown
                        outline
                        icon="delete"
                        onClick={() => {
                          const new_values = { ...props.values };

                          delete new_values[`role${i}`];
                          delete new_values[`${i}`];

                          const count = Object.keys(props.values).filter(
                            (k) => k.indexOf('role') >= 0,
                          ).length;

                          for (let j = i + 1; j < count; j++) {
                            new_values[j - 1] = new_values[j];
                            new_values[`role${j - 1}`] = new_values[`role${j}`];
                            delete new_values[j];
                            delete new_values[`role${j}`];
                          }

                          props.setValues(new_values);
                        }}
                      />
                    </Div>
                  </Div>
                ))}
              <Div flex={{ justify: 'flex-end' }}>
                <PlaceholderButton
                  disabled={props.isSubmitting}
                  onClick={() => {
                    const count = Object.keys(props.values).filter(
                      (k) => k.indexOf('role') >= 0,
                    ).length;
                    props.setFieldValue(`role${count}`, 'member');
                  }}>
                  Add another
                </PlaceholderButton>
              </Div>
            </Div>
          </Modal>
        </form>
      )}
    </Formik>
  );
};

export default AddTeamMemberModal;
