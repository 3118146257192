import { format } from 'date-fns';
import { useField } from 'formik';

import { User } from '../../../../../../typings/User.interface';
import { RelativeDate, relative_dates } from '../../../utils/date';
import isEmpty from '../../../utils/isEmpty';
import { StyledCard } from '../base/Card';
import { IconName } from '../base/Icon';
import Text from '../base/Text';
import CliUserFilter from './FilterComponents/CliUserFilter';
import DateFilter from './FilterComponents/DateFilter';
import JSONFilter from './FilterComponents/JSONFilter';
import NumberRangeFilter from './FilterComponents/NumberRangeFilter';
import ResourcesFilter from './FilterComponents/ResourcesFilter';
import ResponseCodeFilter from './FilterComponents/ResponseCodeFilter';
import { Div } from '../helpers/StyledUtils';
import MultiSelectFilter from './FilterComponents/MultiSelectFilter';
import { rejection_cause_labels } from '../../../utils/rejection-causes';
import Search from '../Search';
import SelectFilter from './FilterComponents/SelectFilter';

export type FilterFormValues = {
  date?: {
    relative?: RelativeDate;
    min?: string;
    max?: string;
  };
  webhook_id?: string[];
  request?: {
    headers?: string;
    body?: string;
    parsed_query?: string;
    path?: string;
  };
  source_id?: string[];
  destination_id?: string[];
  response?: {
    response_status?: {
      min?: number;
      max?: number;
    };
    error_code?: string[];
  };
  status?: string[];
  attempts?: {
    min?: number;
    max?: number;
  };
  cli_user_id?: string[];
};

export type FilterComponent = {
  label: string;
  filter_key: string;
  form_name: string;
  Component: React.FC<any>;
  component_props?: any;
  icon: IconName;
  center_label?: boolean;
  hidden?: boolean;
  validate?: (values: { [k: string]: any } | string[] | string) => { [k: string]: any };
  isActive: (values: { [k: string]: any } | string[] | string | string[] | string) => boolean;
  formatForForm: (
    filters: { [k: string]: any } | string[] | string | null,
    all_filters: { [k: string]: any },
  ) => { [k: string]: any } | string[] | string;
  formatForQuery: (
    values: { [k: string]: any } | string[] | string,
    view?: string,
  ) => { [k: string]: any } | string[] | string | undefined;
  formatForDB: (
    values: { [k: string]: any } | string[] | string,
  ) => { [k: string]: any } | undefined;
  formatForMemory?: (
    values: { [k: string]: any } | string[] | string,
  ) => { [k: string]: any } | undefined;
  valuesToString: (
    values: { [k: string]: any } | string[] | string,
    data_by_id: {
      webhook_id: {};
      destination_id: {};
      source_id: {};
      team_members: {};
    },
    user?: User,
  ) => string | undefined;
};

const numberRangeFormatForQuery = (v?: { min?: number; max?: number }) => {
  if (!v) {
    return v;
  }
  return {
    ...(!isEmpty(v.min) ? { min: v.min } : {}),
    ...(!isEmpty(v.max) ? { max: v.max } : {}),
  };
};

const numberRangeFormatForDB = (v: { min?: number; max?: number }) => {
  return {
    ...(!isEmpty(v.min) ? { min: v.min } : {}),
    ...(!isEmpty(v.max) ? { max: v.max } : {}),
  };
};

const common_formatters: Record<
  string,
  (
    key: string,
  ) => Pick<
    FilterComponent,
    | 'center_label'
    | 'isActive'
    | 'formatForForm'
    | 'formatForQuery'
    | 'formatForDB'
    | 'valuesToString'
  >
> = {
  multi_select: (key) => ({
    center_label: true,
    isActive: (v: string[]) => {
      if (v?.length > 0) {
        return true;
      }
      return false;
    },
    formatForForm: (filters) => filters || [],
    formatForQuery: (v: string[]) => (v?.length > 0 ? v : undefined),
    formatForDB: (v: string[]) => (v?.length > 0 ? { [key]: v } : undefined),
    valuesToString: (v: string[]) =>
      v.map((cause) => cause.charAt(0).toUpperCase() + cause.slice(1)).join(', '),
  }),
  select: (key) => ({
    center_label: true,
    isActive: (v: string[]) => {
      if (v?.length > 0) {
        return true;
      }
      return false;
    },
    formatForForm: (filters) => filters || '',
    formatForQuery: (v: string) => (v?.length > 0 ? v : undefined),
    formatForDB: (v: string) => (v?.length > 0 ? { [key]: v } : undefined),
    valuesToString: (v: string) => v.charAt(0).toUpperCase() + v.slice(1),
  }),
  single_value: (key) => ({
    center_label: true,
    isActive: (v: string[]) => {
      if (v?.length > 0) {
        return true;
      }
      return false;
    },
    formatForForm: (v) => v || '',
    formatForQuery: (v) => v || undefined,
    formatForDB: (v) => ({ [key]: v }),
    valuesToString: (v: string) => v,
  }),
};

const filters_components: Record<string, FilterComponent> = {
  date: {
    label: 'Date',
    filter_key: 'date',
    form_name: 'date',
    Component: DateFilter,
    icon: 'date',
    center_label: true,
    isActive: (v: { relative: string; min: string; max: string }) => {
      if (v?.relative) {
        return true;
      }
      if (v?.min || v?.max) {
        return true;
      }
      return false;
    },
    formatForForm: (filters) => filters || {},
    formatForMemory: (v: { relative?: string; min?: string; max?: string }) => {
      if (v.relative) {
        delete v.min;
        delete v.max;
      }
      return v;
    },
    formatForQuery: (v: { relative?: string; min?: string; max?: string }) => {
      if (v?.relative) {
        return v.relative;
      }
      delete v?.relative;
      return v;
    },
    formatForDB: (v: { relative: string; min: string; max: string }) => {
      if (v?.relative) {
        return { date: { relative: v.relative } };
      }
      if (v?.min || v?.max) {
        return { date: { min: v.min, max: v.max } };
      }
      return undefined;
    },
    valuesToString: (v: { relative: string; min: string; max: string }) => {
      if (v?.relative) {
        return relative_dates[v.relative].label;
      }
      if (v?.min || v?.max) {
        return `${format(new Date(v.min), 'MMM d yy')} -> ${
          v.max ? format(new Date(v.max), 'MMM d yy') : 'Now'
        }`;
      }
      return '';
    },
  },
  request: {
    label: 'Request',
    filter_key: 'request',
    form_name: 'request',
    Component: JSONFilter,
    icon: 'requests',
    validate: (request: { body?: string; headers?: string; parsed_query?: string }) => {
      const errors: { body?: string; headers?: string; parsed_query?: string } = {};
      if (request) {
        try {
          if (request.headers) {
            const parsed_headers = request.headers && JSON.parse(request.headers);
            if (typeof parsed_headers !== 'object') {
              errors.headers = 'Headers must be an object';
            }
          }
        } catch (e) {
          errors.headers = 'Invalid JSON';
        }
        try {
          request.body && JSON.parse(request.body);
        } catch (e) {
          errors.body = 'Invalid JSON';
        }
        try {
          request.body && JSON.parse(request.body);
        } catch (e) {
          errors.body = 'Invalid JSON';
        }
        try {
          request.parsed_query && JSON.parse(request.parsed_query);
        } catch (e) {
          errors.parsed_query = 'Invalid JSON';
        }
      }
      return errors;
    },
    isActive: (v: { body: string; headers: string; parsed_query: string; path: string }) => {
      if (
        (v?.body && v?.body !== '{}') ||
        (v?.headers && v?.headers !== '{}') ||
        v?.path ||
        (v?.parsed_query && v?.parsed_query !== '{}')
      ) {
        return true;
      }
      return false;
    },
    formatForForm: (filters: any) => {
      const values = {
        body: '{}',
        headers: '{}',
        parsed_query: '{}',
        path: '',
      };
      if (filters?.body) {
        values.body = filters.body;
      }
      if (filters?.headers) {
        values.headers = filters.headers;
      }
      if (filters?.path) {
        values.path = filters.path;
      }
      if (filters?.parsed_query) {
        values.parsed_query = filters.parsed_query;
      }
      return values;
    },
    formatForMemory: (v: any) => {
      const values: any = {};
      if (v?.body && v?.body !== '{}') {
        values.body = v.body;
      }
      if (v?.headers && v?.headers !== '{}') {
        values.headers = v.headers;
      }
      if (v?.path) {
        values.path = v.path;
      }
      if (v?.parsed_query && v?.parsed_query !== '{}') {
        values.parsed_query = v.parsed_query;
      }
      return values;
    },
    formatForQuery: (v: { body: string; headers: string; parsed_query: string; path: string }) => {
      const query: any = {};
      if (v?.body && v?.body !== '{}') {
        query.body = v.body;
      }
      if (v?.headers && v?.headers !== '{}') {
        query.headers = v.headers;
      }
      if (v?.path) {
        query.path = v.path;
      }
      if (v?.parsed_query && v?.parsed_query !== '{}') {
        query.parsed_query = v.parsed_query;
      }
      return query;
    },
    formatForDB: (v: { body: string; headers: string; parsed_query: string; path: string }) => {
      const body: any = {};
      if (v?.body && v?.body !== '{}') {
        body.body = v.body;
      }
      if (v?.headers && v?.headers !== '{}') {
        body.headers = v.headers;
      }
      if (v?.path) {
        body.path = v.path;
      }
      if (v?.parsed_query && v?.parsed_query !== '{}') {
        body.parsed_query = v.parsed_query;
      }
      return { request: body };
    },
    valuesToString: (v: { body: string; headers: string; parsed_query: string; path: string }) =>
      Object.entries(v)
        .filter(([, json]) => json)
        .map(([key, json]) => `${key.charAt(0).toUpperCase() + key.slice(1)}: ${json}`)
        .join(', '),
  },
  webhook_id: {
    label: 'Connections',
    filter_key: 'webhook_id',
    form_name: 'webhook_id',
    center_label: true,
    Component: ResourcesFilter,
    component_props: {
      option_title: 'Webhooks',
      api_root: 'webhooks',
      name: 'webhook_id',
    },
    icon: 'connections',
    ...common_formatters.multi_select('webhook_id'),
    valuesToString: (v: string[], data_by_id) =>
      v.map((id) => `${data_by_id?.webhook_id[id]?.full_name}`).join(', '),
  },
  source_id: {
    label: 'Sources',
    filter_key: 'source_id',
    form_name: 'source_id',
    Component: ResourcesFilter,
    component_props: {
      option_title: 'Sources',
      api_root: 'sources',
    },
    icon: 'source',
    ...common_formatters.multi_select('source_id'),
    valuesToString: (v: string[], data_by_id) =>
      v.map((id) => data_by_id?.source_id[id]?.name).join(', '),
  },
  destination_id: {
    label: 'Destinations',
    filter_key: 'destination_id',
    form_name: 'destination_id',
    Component: ResourcesFilter,
    component_props: {
      option_title: 'Destinations',
      api_root: 'destinations',
    },
    icon: 'destinations',
    ...common_formatters.multi_select('destination_id'),
    valuesToString: (v: string[], data_by_id) =>
      v.map((id) => data_by_id?.destination_id[id]?.name).join(', '),
  },
  event_status: {
    label: 'Status',
    filter_key: 'status',
    form_name: 'status',
    Component: MultiSelectFilter,
    component_props: {
      options: [
        { value: 'successful', label: 'Successful' },
        { value: 'failed', label: 'Failed' },
        { value: 'pending', label: 'Pending' },
        { value: 'paused', label: 'Paused' },
        { value: 'scheduled', label: 'Scheduled' },
      ],
    },
    icon: 'status',
    ...common_formatters.multi_select('status'),
  },
  connection_status: {
    label: 'Status',
    filter_key: 'status',
    form_name: 'status',
    Component: SelectFilter,
    component_props: {
      options: [
        {
          label: 'Enabled',
          value: 'active',
        },
        {
          label: 'Paused',
          value: 'paused',
        },
        {
          label: 'Disabled',
          value: 'disabled',
        },
      ],
    },
    icon: 'status',
    ...common_formatters.select('status'),
  },
  log_level: {
    label: 'Log Level',
    filter_key: 'log_level',
    form_name: 'log_level',
    Component: MultiSelectFilter,
    component_props: {
      options: [
        { value: 'debug', label: 'Debug' },
        { value: 'info', label: 'Info' },
        { value: 'warn', label: 'Warn' },
        { value: 'error', label: 'Error' },
        { value: 'fatal', label: 'Fatal' },
      ],
    },
    icon: 'status',
    ...common_formatters.multi_select('log_level'),
  },
  events_count: {
    label: 'Events',
    filter_key: 'events_count',
    form_name: 'events_count',
    Component: NumberRangeFilter,
    icon: 'events',
    isActive: (v: { min: string; max: string }) => {
      if (v?.min || v?.max) {
        return true;
      }
      return false;
    },
    formatForForm: (v) => {
      return v || {};
    },
    formatForQuery: (v: { min?: number; max?: number }) => {
      return numberRangeFormatForQuery(v) as any;
    },
    formatForDB: (v: { min?: number; max?: number }) => {
      v = numberRangeFormatForDB(v);
      return { events_count: v };
    },
    valuesToString: (v: { min: number; max: number }) => {
      if (!v.min && !v.max) return;
      if (!v.min) {
        return `Events count: Maximum ${v.max}`;
      }
      return `Events count: ${v.min ? v.min : ''} ${v.max ? 'to ' + v.max : ''}`;
    },
  },
  ignored_events: {
    label: 'Ignored Events',
    filter_key: 'ignored_count',
    form_name: 'ignored_count',
    Component: NumberRangeFilter,
    icon: 'block',
    isActive: (v: { min: string; max: string }) => {
      if (v?.min || v?.max) {
        return true;
      }
      return false;
    },
    formatForForm: (v: string | string[] | { [k: string]: any }) => {
      return v || {};
    },
    formatForQuery: (v?: { min?: number; max?: number } | any) => {
      return numberRangeFormatForQuery(v);
    },
    formatForDB: (v: { min?: number; max?: number }) => {
      v = numberRangeFormatForDB(v);
      return { ignored_count: v };
    },
    valuesToString: (v: { min: number; max: number }) => {
      if (!v.min && !v.max) return;
      if (!v.min) {
        return `Ignored events count: Maximum ${v.max}`;
      }
      return `Ignored events count: ${v.min ? v.min : ''} ${v.max ? 'to ' + v.max : ''}`;
    },
  },
  request_status: {
    label: 'Status',
    filter_key: 'status',
    form_name: 'status',
    Component: SelectFilter,
    component_props: {
      options: [
        { value: 'accepted', label: 'Accepted' },
        { value: 'rejected', label: 'Rejected' },
      ],
    },
    icon: 'status',
    ...common_formatters.select('status'),
  },
  rejection_cause: {
    label: 'Rejection Cause',
    filter_key: 'rejection_cause',
    form_name: 'rejection_cause',
    Component: MultiSelectFilter,
    component_props: {
      options: Object.entries(rejection_cause_labels).map(([k, v]) => ({
        value: k,
        label: v,
      })),
    },
    icon: 'error',
    ...common_formatters.multi_select('rejection_cause'),
  },
  response: {
    label: 'HTTP Response',
    filter_key: 'response',
    form_name: 'response',
    Component: ResponseCodeFilter,
    icon: 'http',
    validate: (response: { response_status?: { min?: number; max?: number } }) => {
      const errors: { response_status?: { min?: string; max?: string } } = {};
      const has_min = !isEmpty(response?.response_status?.min);
      const has_max = !isEmpty(response?.response_status?.max);
      if (
        has_min &&
        (response.response_status!.min! < 200 || response.response_status!.min! > 599)
      ) {
        errors.response_status = {
          min: 'Minimum response status must be between 200 and 599',
        };

        return errors;
      }
      if (
        has_max &&
        (response.response_status!.max! < 200 || response.response_status!.max! > 599)
      ) {
        errors.response_status = {
          max: 'Maximum response status must be between 200 and 599',
        };

        return errors;
      }
      if (has_min && has_max && response.response_status!.min! > response.response_status!.max!) {
        errors.response_status = {
          min: 'Minimum response status must be lower than maximum',
        };
      }

      return errors;
    },
    isActive: (v: { response_status: { min: string; max: string }; error_code: string[] }) => {
      if (v?.response_status?.min || v?.response_status?.max || v?.error_code.length > 0) {
        return true;
      }
      return false;
    },
    formatForForm: (_, all_filters) => {
      return {
        error_code: all_filters.error_code || [],
        response_status: all_filters.response_status || {},
      };
    },
    formatForQuery: (v: {
      response_status: { min: number; max: number };
      error_code: string[];
    }) => {
      return {
        response_status: v?.response_status,
        error_code: v?.error_code?.length > 0 ? v.error_code : undefined,
      };
    },
    formatForDB: (v: { response_status: { min: number; max: number }; error_code: string[] }) => {
      return {
        response_status: v?.response_status,
        error_code: v?.error_code?.length > 0 ? v.error_code : undefined,
      };
    },
    valuesToString: (v: { response_status: { min: number; max: number }; error_code: string[] }) =>
      Object.entries(v)
        .filter(([, json]) => json)
        .map(([key]) => {
          if (key === 'response_status') {
            if (!v.response_status.min && !v.response_status.max) return;
            return `HTTP codes: ${v.response_status.min ? v.response_status.min : '200'} ${
              v.response_status.max ? 'to ' + v.response_status.max : ''
            }`;
          }
          return v.error_code?.length > 0 ? `Codes: ${v.error_code.join(', ')}` : '';
        })
        .join(' '),
  },
  attempts: {
    label: 'Attempts',
    filter_key: 'attempts',
    form_name: 'attempts',
    Component: NumberRangeFilter,
    icon: 'subdirectory',
    isActive: (v: { min: string; max: string }) => {
      if (v?.min || v?.max) {
        return true;
      }
      return false;
    },
    formatForForm: (v) => {
      return v || {};
    },
    formatForQuery: (v?: { min?: number; max?: number } | any) => {
      return numberRangeFormatForQuery(v);
    },
    formatForDB: (v: { min?: number; max?: number }) => {
      v = numberRangeFormatForDB(v);
      return { attempts: v };
    },
    valuesToString: (v: { min: number; max: number }) => {
      if (!v.min && !v.max) return;
      if (!v.min) {
        return `Attempts: Maximum ${v.max}`;
      }
      return `Attempts: ${v.min ? v.min : ''} ${v.max ? 'to ' + v.max : ''}`;
    },
  },

  cli_user_id: {
    label: 'CLI Users',
    filter_key: 'cli_user_id',
    form_name: 'cli_user_id',
    Component: CliUserFilter,
    icon: 'person',
    center_label: true,
    isActive: (v: string[]) => {
      if (v?.length > 0) {
        return true;
      }
      return false;
    },
    formatForForm: (v) => (v === 'any' ? [] : v || []),
    formatForQuery: (v: string[], view) => {
      if (view !== 'cli') return undefined;
      const values = v;
      if (!values || values.length === 0) {
        return 'any';
      }
      return values;
    },
    formatForDB: (v: string[]) => {
      if (!v || v.length === 0) {
        return { cli_user_id: 'any' };
      }
      return { cli_user_id: v };
    },
    valuesToString: (v: string[], data_by_id, user: User) =>
      v
        .map((id) =>
          data_by_id?.team_members[id]?.user_id === user.id
            ? 'Myself'
            : data_by_id?.team_members[id]?.user_name,
        )
        .join(', '),
  },
  search: {
    label: 'Search',
    filter_key: 'search_term',
    form_name: 'search_term',
    validate: (v) => {
      if (v && v.length < 3) {
        return { search_term: 'Search term must be at least 3 characters' };
      }
      return {};
    },
    Component: ({ name, delete_button, onSubmit, onDelete }) => {
      const [{ value }, { error }, { setValue }] = useField(name);
      return (
        <>
          <Div flex={{ align: 'center' }}>
            <Search
              w={50}
              onChange={(v) => {
                if (!v) {
                  onDelete();
                } else {
                  setValue(v);
                }
              }}
              onBlur={onSubmit}
              value={value}
              placeholder={'Search for a value found in the headers, body, query or path...'}
            />
            {delete_button && delete_button}
          </Div>
          {error?.[name] && (
            <Text danger size="s" m={{ t: 2, b: 2 }}>
              {error[name]}
            </Text>
          )}
        </>
      );
    },
    icon: 'search',
    hidden: true,
    ...common_formatters.single_value('search_term'),
    isActive: (v: string[]) => {
      if (v?.length >= 3) {
        return true;
      }
      return false;
    },
    formatForQuery: (v) => v || undefined,
  },
  issue_id: {
    label: 'Issue',
    filter_key: 'issue_id',
    form_name: 'issue_id',
    Component: ({ delete_button }) => {
      const [{ value }] = useField('issue_id');
      return (
        <Div flex={{ align: 'center' }}>
          <StyledCard p={2}>
            <Text subtitle>{value}</Text>
          </StyledCard>
          {delete_button}
        </Div>
      );
    },
    icon: 'inbox',
    hidden: true,
    ...common_formatters.single_value('issue_id'),
  },
  bulk_retry_id: {
    label: 'Bulk Retry',
    filter_key: 'bulk_retry_id',
    form_name: 'bulk_retry_id',
    Component: ({ delete_button }) => {
      const [{ value }] = useField('bulk_retry_id');
      return (
        <Div flex={{ align: 'center' }}>
          <StyledCard p={2}>
            <Text subtitle>{value}</Text>
          </StyledCard>
          {delete_button}
        </Div>
      );
    },
    icon: 'retry',
    hidden: true,
    ...common_formatters.single_value('bulk_retry_id'),
  },
};

export const search_filter_component: FilterComponent = filters_components.search;

export const event_list_filters: FilterComponent[] = [
  filters_components.date,
  filters_components.request,
  filters_components.webhook_id,
  filters_components.source_id,
  filters_components.destination_id,
  filters_components.event_status,
  filters_components.response,
  filters_components.attempts,
  filters_components.cli_user_id,
  filters_components.bulk_retry_id,
  filters_components.issue_id,
];

export const request_list_filters: FilterComponent[] = [
  filters_components.date,
  filters_components.source_id,
  filters_components.request,
  filters_components.request_status,
  filters_components.rejection_cause,
  filters_components.bulk_retry_id,
  filters_components.ignored_events,
  filters_components.events_count,
];

export const transformation_execution_list_filters: FilterComponent[] = [
  filters_components.date,
  filters_components.webhook_id,
  filters_components.log_level,
];
