import { Formik } from 'formik';
import { useContext } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import {
  OrganizationMember,
  OrganizationMemberInvite,
  OrganizationRole,
} from '../../../../../../../../typings/OrganizationMember.interface';
import Button, { PlaceholderButton } from '../../../../common/base/Button';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import Modal from '../../../../common/Modal';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { DashboardContext } from '../../DashboardContext';
import { createRoleOptions } from './OrganizationMembers';

interface Props {
  onMembersAdded: (members: OrganizationMember[], invites: OrganizationMemberInvite[]) => void;
}

const AddOrganizationMemberModal: React.FC<Props> = ({ onMembersAdded }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { organization, subscription } = useContext(DashboardContext);
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();

  const onClose = () => {
    history.push({
      pathname: '/settings/organization/members',
      search: location.search,
      state: { scroll: false },
    });
  };

  const validate = (values) => {
    if (!values[0]) {
      return { 0: 'Required' };
    }

    if (!values['role0']) {
      return { role0: 'Required' };
    }

    return {};
  };

  const onSubmit = async (values) => {
    const members: { email: string; role: OrganizationRole }[] = [];

    for (let i = 0; i < Object.keys(values).filter((k) => k.indexOf('role') >= 0).length; i++) {
      const email = values[i];
      const role = values[`role${i}`];

      if (email && role) {
        members.push({ email, role });
      }
    }

    try {
      const { members: new_members, invites } = await HookdeckAPI.organizations.addMembers(members);
      onMembersAdded && onMembersAdded(new_members, invites);
      onClose();
    } catch (err) {
      addToast('error', err.message);
    }
  };

  return (
    <Formik initialValues={{ role0: 'member' }} onSubmit={onSubmit} validate={validate}>
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Modal
            title="Add members to your organization"
            submit_label="Add"
            cancel_label="Cancel"
            onSubmit={props.handleSubmit}
            onCancel={onClose}
            onClose={onClose}
            is_submitting={props.isSubmitting}>
            <Div>
              {new Array(Object.keys(props.values).filter((k) => k.indexOf('role') >= 0).length)
                .fill(null)
                .map((_, i) => (
                  <Div flex={{ gap: 2 }} key={i}>
                    <TextInput
                      flex={{ direction: 'column', grow: true }}
                      name={`${i}`}
                      placeholder="jane@example.com"
                      type="email"
                      focus={i === 0}
                    />
                    <Div>
                      <SelectInput
                        name={`role${i}`}
                        dropdown={{
                          title: 'Roles',
                          w: { px: 200 },
                          parent_width: false,
                          placement: 'bottom-end',
                        }}
                        options={createRoleOptions(
                          !!subscription?.features?.includes('viewer_role') ||
                            !!organization?.feature_flags?.viewer_role,
                        )}
                      />
                    </Div>
                    <Div>
                      <Button
                        outline
                        icon="delete"
                        onClick={() => {
                          const new_values = { ...props.values };

                          delete new_values[`role${i}`];
                          delete new_values[`${i}`];

                          const count = Object.keys(props.values).filter(
                            (k) => k.indexOf('role') >= 0,
                          ).length;

                          for (let j = i + 1; j < count; j++) {
                            new_values[j - 1] = new_values[j];
                            new_values[`role${j - 1}`] = new_values[`role${j}`];
                            delete new_values[j];
                            delete new_values[`role${j}`];
                          }

                          props.setValues(new_values);
                        }}
                      />
                    </Div>
                  </Div>
                ))}
              <Div flex={{ justify: 'flex-end' }}>
                <PlaceholderButton
                  disabled={props.isSubmitting}
                  onClick={() => {
                    const count = Object.keys(props.values).filter(
                      (k) => k.indexOf('role') >= 0,
                    ).length;
                    props.setFieldValue(`role${count}`, 'member');
                  }}>
                  Add another
                </PlaceholderButton>
              </Div>
            </Div>
          </Modal>
        </form>
      )}
    </Formik>
  );
};

export default AddOrganizationMemberModal;
