import { useContext, useState } from 'react';
import { Route } from 'react-router-dom';
import useSWR from 'swr';

import { OrganizationRole } from '../../../../../../../../typings/OrganizationMember.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { capitalizeFirstLetter } from '../../../../../utils';
import Alert from '../../../../common/base/Alert';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Divider from '../../../../common/base/Divider';
import Tabs from '../../../../common/base/Tabs';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import { useDialog } from '../../../../common/Dialog';
import DropdownMenu from '../../../../common/DropdownMenu';
import { Div } from '../../../../common/helpers/StyledUtils';
import Search from '../../../../common/Search';
import { useToasts } from '../../../../common/Toast';
import { WithOrganizationAdminRole } from '../../../../common/WithRole';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { UserContext } from '../../../../contexts/UserContext';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import { DashboardContext } from '../../DashboardContext';
import AddOrganizationMemberModal from './AddOrganizationMemberModal';
import { organization_levels_by_role } from '../../../../../../../../domain/roles';
import Icon from '../../../../common/base/Icon';
import { showChat } from '../../../../../utils/liveChat';

const description_by_role = {
  admin: 'Unrestricted access to everything',
  member: 'Restricted access to organization members, billing and private projects',
  viewer: 'Read-only access to public projects',
};

export const createRoleOptions = (viewer_enabled: boolean) =>
  Object.keys(description_by_role).map((role: OrganizationRole) => ({
    value: role,
    label: capitalizeFirstLetter(role),
    description:
      role === 'viewer' && !viewer_enabled
        ? 'Upgrade plan to enable role'
        : description_by_role[role],
    disabled: role === 'viewer' && !viewer_enabled,
  }));

const OrganizationMembers = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const { subscription, organization, organization_role, resetContext } =
    useContext(DashboardContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const { query, updateSearchQuery } = useSearchQuery<{ tab: 'active' | 'invited' }>();
  const [search_value, setSearchValue] = useState('');

  const { data: members, mutate: mutateMembers } = useSWR(
    APIMethodKeys.organizations.listMembers(organization!.id),
    () => HookdeckAPI.organizations.listMembers(),
  );

  const { data: invites, mutate: mutateInvites } = useSWR(
    APIMethodKeys.organizations.listMemberInvites(organization!.id),
    () => HookdeckAPI.organizations.listMemberInvites(),
  );

  const changeRole = async (id: string, role: OrganizationRole) => {
    try {
      await HookdeckAPI.organizations.updateRole(id, role);
      mutateMembers((members) => {
        return members?.map((m) => (m.id === id ? { ...m, role } : m));
      }, false);
      addToast('success', `Role changed to ${capitalizeFirstLetter(role)}`);
    } catch (err) {
      addToast('error', err.message);
    }
  };

  const handleRemove = (id: string, email: string) => {
    showDialog(
      async () => {
        try {
          await HookdeckAPI.organizations.removeMember(id);
          mutateMembers((members = []) => {
            members = members?.filter((m) => m.id !== id) ?? [];
            return members;
          }, false);
          addToast('success', 'Member removed from organization');
        } catch (err) {
          addToast('error', err.message);
        }
      },
      () => null,
      {
        title: 'Remove from organization',
        message: (
          <Text>
            <Text as="span" heading>
              {email}
            </Text>{' '}
            will lose access to this organization and all its projects. Are you sure?
          </Text>
        ),
        submit_label: 'Remove from organization',
        cancel_label: 'Cancel',
        danger: true,
      },
    );
  };

  const handleLeave = () => {
    showDialog(
      async () => {
        try {
          await HookdeckAPI.organizations.leave();
          resetContext();
        } catch (err) {
          addToast('error', err.message);
        }
      },
      () => null,
      {
        title: 'Leave organization',
        message: (
          <Text>
            You will lose access to this organization and all of the projects within it. If you’d
            like to re-join, the organization owner or an admin will need to re-invite you. Are you
            sure?
          </Text>
        ),
        submit_label: 'Leave organization',
        cancel_label: 'Stay in organization',
        danger: true,
      },
    );
  };

  const exceeds_users =
    subscription!.max_users && members && members.length >= subscription!.max_users;

  const filtered_members = search_value
    ? members?.filter(
        (member) =>
          member.user_name.toLowerCase().includes(search_value.toLowerCase()) ||
          member.user_email.toLowerCase().includes(search_value.toLowerCase()),
      )
    : members;

  const filtered_invites = search_value
    ? invites?.filter((invite) => invite.email.toLowerCase().includes(search_value.toLowerCase()))
    : invites;

  return (
    <>
      <Route
        path="/settings/organization/members/add"
        render={() => (
          <AddOrganizationMemberModal
            onMembersAdded={(new_members, new_invites) => {
              mutateMembers((members) => {
                new_members.forEach((member) => {
                  if (!members?.some((m) => m.user_id === member.user_id)) {
                    members = [member, ...(members ?? [])];
                  }
                });
                return members;
              }, false);

              mutateInvites((invites) => {
                new_invites.forEach((invite) => {
                  if (!invites?.some((i) => i.email === invite.email)) {
                    invites?.push(invite);
                  }
                });
                return invites;
              }, false);

              addToast('success', 'Members added');
            }}
          />
        )}
      />
      <Div m={{ b: 14 }}>
        <Div m={{ b: 2 }} flex={{ justify: 'space-between', align: 'center' }}>
          <Div>
            <Text heading as="h2" m={{ y: 0 }}>
              Organization Members
            </Text>
            <Text muted>All organization members can access open projects.</Text>
          </Div>
          {!organization?.workos_directory_id && (
            <WithOrganizationAdminRole>
              <Button
                to={(location) => ({
                  ...location,
                  pathname: `/settings/organization/members/add`,
                  state: { scroll: false },
                })}
                disabled={!!exceeds_users}
                primary
                icon="add_circle">
                Add members
              </Button>
            </WithOrganizationAdminRole>
          )}
        </Div>
        <Divider m={{ b: 4 }} />
        {organization?.workos_connection_id && (
          <Alert inline info m={{ b: 4 }}>
            Your organization is configured to use SSO and access for organisation members is
            managed through your identity provider.
            <WithOrganizationAdminRole>
              {' '}
              You can still invite manually members outside your organization.
            </WithOrganizationAdminRole>
          </Alert>
        )}
        {exceeds_users && !organization?.workos_connection_id && (
          <Alert
            inline
            info
            m={{ b: 4 }}
            action={{ label: 'Upgrade', to: '/settings/organization/plans?highlight=max_users' }}>
            You've reached the maximum number of members for your plan.
          </Alert>
        )}
        <StyledCard>
          <StyledCardSection p={{ x: 3, t: 3 }} flex={{ justify: 'space-between' }}>
            <Tabs
              compact
              onTabSelected={(tab) => updateSearchQuery({ tab })}
              active_tab={query.tab ?? 'active'}
              tabs={[
                {
                  label: 'Active',
                  key: 'active',
                  badge_label: members?.length,
                  badge_theme: 'primary',
                },
                {
                  label: 'Invited',
                  key: 'invited',
                  badge_label: invites && invites.length > 0 ? invites.length : undefined,
                  badge_theme: 'warning',
                },
              ]}
              border={false}
            />
            <Search
              value={search_value}
              onChange={setSearchValue}
              small
              m={{ b: 3 }}
              placeholder="Find a user..."
            />
          </StyledCardSection>
          {query.tab !== 'invited' && (
            <>
              {filtered_members
                ?.sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
                .map((member, i) => (
                  <StyledCardSection
                    p={{ x: 4, y: 3 }}
                    flex={{ align: 'center', gap: 4 }}
                    key={member.id}>
                    <Div w={50}>
                      <Text subtitle ellipsis>
                        {member.user_name}
                        {member.user_id === user!.id && ' (You)'}
                      </Text>
                      <Text muted ellipsis>
                        {member.user_email}
                      </Text>
                    </Div>
                    <Div w={50}>
                      {user!.id !== member.user_id &&
                      member.role !== 'owner' &&
                      organization_levels_by_role[organization_role!] >=
                        organization_levels_by_role.admin ? (
                        <DropdownMenu
                          placement="bottom-start"
                          outline
                          w={{ px: 272 }}
                          label={capitalizeFirstLetter(member.role)}
                          title="Roles"
                          options={createRoleOptions(
                            !!subscription?.features?.includes('viewer_role') ||
                              !!organization?.feature_flags?.viewer_role,
                          ).map((option) => ({
                            ...option,
                            onClick: () => changeRole(member.id, option.value),
                          }))}
                        />
                      ) : (
                        <Button outline disabled>
                          {capitalizeFirstLetter(member.role)}
                        </Button>
                      )}
                    </Div>
                    <Div w={{ px: 32 }}>
                      {user!.id === member.user_id &&
                        member.role !== 'owner' &&
                        !user!.sso_idp_id && (
                          <Div flex={{ justify: 'flex-end' }}>
                            <DropdownMenu
                              placement="bottom-end"
                              minimal
                              icon="horizontal_more"
                              options={[
                                {
                                  icon: 'leave',
                                  label: 'Leave Organization',
                                  onClick: handleLeave,
                                  danger: true,
                                },
                              ]}
                            />
                          </Div>
                        )}
                      {user!.id === member.user_id &&
                        member.role === 'owner' &&
                        !organization?.workos_directory_id && (
                          <Div flex={{ justify: 'flex-end' }}>
                            <DropdownMenu
                              placement="bottom-end"
                              minimal
                              icon="horizontal_more"
                              options={[
                                {
                                  icon: 'swap',
                                  label: 'Transfer Ownership',
                                  onClick: showChat,
                                  description:
                                    'Select this to contact us. The team at Hookdeck will help you execute your ownership transfer.',
                                },
                              ]}
                            />
                          </Div>
                        )}
                      {user!.id !== member.user_id && member.role !== 'owner' && (
                        <WithOrganizationAdminRole>
                          <Button
                            minimal
                            icon="delete"
                            onClick={() => handleRemove(member.id, member.user_email)}
                          />
                        </WithOrganizationAdminRole>
                      )}
                    </Div>
                  </StyledCardSection>
                ))}
              {filtered_members && filtered_members.length === 0 && (
                <StyledCardSection p={4}>
                  <Text muted>
                    <Icon icon="info" muted left />
                    No matching users found.
                  </Text>
                </StyledCardSection>
              )}
            </>
          )}
          {query.tab === 'invited' && (
            <>
              {invites && invites.length > 0 && (
                <Div>
                  {filtered_invites
                    ?.sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
                    .map((invite, i) => (
                      <StyledCardSection
                        p={{ x: 4, y: 3 }}
                        flex={{ justify: 'space-between', align: 'center' }}
                        key={invite.id}>
                        <Div w={60}>
                          <Text dark subtitle>
                            {invite.email}
                          </Text>
                          <Text muted danger={invite.status === 'EXPIRED'}>
                            {capitalizeFirstLetter(invite.status.toLowerCase())}
                          </Text>
                        </Div>
                        <Div w={40}>
                          <Button outline disabled>
                            {capitalizeFirstLetter(invite.role)}
                          </Button>
                        </Div>
                        <Div flex={{ align: 'center', gap: 2 }} w={{ px: 134 }}>
                          <WithOrganizationAdminRole>
                            <Button
                              onClick={() => {
                                HookdeckAPI.organizations.resendInvite(invite.id).then((invite) => {
                                  mutateInvites((invites) => {
                                    if (invites && invites.length > 0) {
                                      const i = invites.findIndex((inv) => inv.id === invite.id);
                                      invites[i] = invite;
                                    }
                                    return invites;
                                  });
                                  addToast('success', `Invite to ${invite.email} resent.`);
                                });
                              }}
                              minimal
                              icon="retry">
                              Resend
                            </Button>
                            <Tooltip tooltip="Revoke invite" align="right">
                              <Button
                                onClick={() => {
                                  HookdeckAPI.organizations
                                    .revokeInvite(invite.id)
                                    .then(({ id }) => {
                                      mutateInvites((invites) => {
                                        invites = invites?.filter((inv) => inv.id !== id) ?? [];
                                        if (invites.length === 0) {
                                          return undefined;
                                        }
                                        return invites;
                                      });
                                      addToast('success', `Invite to ${invite.email} revoked.`);
                                    });
                                }}
                                minimal
                                icon="cancel"
                              />
                            </Tooltip>
                          </WithOrganizationAdminRole>
                        </Div>
                      </StyledCardSection>
                    ))}
                </Div>
              )}
              {filtered_invites &&
                filtered_invites.length === 0 &&
                invites &&
                invites.length > 0 && (
                  <StyledCardSection p={4}>
                    <Text muted>
                      <Icon icon="info" muted left />
                      No matching invites found.
                    </Text>
                  </StyledCardSection>
                )}
              {invites && invites.length === 0 && (
                <StyledCardSection p={4}>
                  <Text muted>
                    <Icon icon="info" muted left />
                    There are no pending invitations
                  </Text>
                </StyledCardSection>
              )}
            </>
          )}
        </StyledCard>
      </Div>
    </>
  );
};

export default OrganizationMembers;
