import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  release: process.env.BUILD_ID,
  environment: process.env.ENV,
  beforeSend: (event) => {
    if (process.env.ENV === 'development') {
      return null;
    }
    return event;
  },
});

export default { captureException: Sentry.captureException, withScope: Sentry.withScope };
