import { IntegrationFeature, IntegrationProvider } from '../../../../typings/Integration.interface';

type Fields = {
  label: string;
  name: string;
  type: 'text' | 'select' | 'password' | 'number' | 'textarea';
  placeholder?: string;
  required?: boolean;
  features?: IntegrationFeature[];
  options?: { value: string; label: string }[];
}[];

const HMAC_SCHEMA: IntegrationSchema = {
  generic: true,
  label: 'HMAC',
  features: ['VERIFICATION'],
  fields: [
    {
      label: 'Algorithm',
      name: 'algorithm',
      type: 'select',
      features: ['VERIFICATION'],
      options: [
        {
          value: 'sha1',
          label: 'SHA-1',
        },
        {
          value: 'sha256',
          label: 'SHA-256',
        },
        {
          value: 'sha512',
          label: 'SHA-512',
        },
        {
          value: 'md5',
          label: 'MD5',
        },
      ],
    },
    {
      label: 'Encoding',
      name: 'encoding',
      type: 'select',
      features: ['VERIFICATION'],
      options: [
        {
          value: 'base64',
          label: 'base64',
        },
        {
          value: 'base64url',
          label: 'base64url',
        },
        {
          value: 'hex',
          label: 'hex',
        },
      ],
    },
    {
      label: 'Signature Header Key',
      name: 'header_key',
      type: 'text',
      placeholder: 'X-Some-Hmac-Signature',
      features: ['VERIFICATION'],
    },
    {
      label: 'Webhook Signing Secret',
      name: 'webhook_secret_key',
      type: 'password',
      placeholder: 'Secret key...',
      features: ['VERIFICATION'],
    },
  ],
};

const generic_fields: {
  [key: string]: Fields;
} = {
  handled_hmac: [
    {
      label: 'Webhook Signing Secret',
      name: 'webhook_secret_key',
      type: 'password',
      placeholder: 'Secret key...',
      features: ['VERIFICATION'],
    },
  ],
  basic_auth: [
    {
      label: 'Username',
      name: 'username',
      type: 'text',
      required: false,
      features: ['VERIFICATION'],
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      required: false,
      features: ['VERIFICATION'],
    },
  ],
  public_key: [
    {
      label: 'Public Key',
      name: 'public_key',
      type: 'password',
      features: ['VERIFICATION'],
    },
  ],
};

export interface IntegrationSchema {
  label: string;
  generic?: true;
  features: IntegrationFeature[];
  fields: Fields;
}

const integration_schemas: {
  [key in IntegrationProvider]: IntegrationSchema;
} = {
  HMAC: HMAC_SCHEMA,
  SANITY: { label: 'Sanity', features: ['VERIFICATION'], fields: generic_fields.handled_hmac },
  BASIC_AUTH: {
    generic: true,
    label: 'Basic Auth',
    features: ['VERIFICATION'],
    fields: generic_fields.basic_auth,
  },
  API_KEY: {
    generic: true,
    label: 'API Key',
    features: ['VERIFICATION'],
    fields: [
      {
        label: 'API Key Header',
        name: 'header_key',
        type: 'text',
        placeholder: 'X-Some-Api-Key',
        features: ['VERIFICATION'],
      },
      {
        label: 'API Key',
        name: 'api_key',
        type: 'password',
        placeholder: 'Secret key...',
        features: ['VERIFICATION'],
      },
    ],
  },
  CLOUDSIGNAL: {
    label: 'Cloud Signal',
    features: ['VERIFICATION'],
    fields: [
      {
        label: 'Webhook API Key',
        name: 'api_key',
        type: 'textarea',
        placeholder: 'Webhook API key...',
        features: ['VERIFICATION'],
      },
    ],
  },
  COURIER: {
    label: 'Courier',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  FRONTAPP: {
    label: 'FrontApp',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  ZOOM: {
    label: 'Zoom',
    features: ['HANDSHAKE', 'VERIFICATION'],
    fields: [
      {
        label: 'Webhook Signing Secret',
        name: 'webhook_secret_key',
        type: 'password',
        features: ['HANDSHAKE', 'VERIFICATION'],
      },
    ],
  },
  TWITTER: {
    label: 'Twitter',
    features: ['VERIFICATION', 'HANDSHAKE'],
    fields: [
      {
        label: 'API Key',
        name: 'api_key',
        type: 'password',
        features: ['HANDSHAKE', 'VERIFICATION'],
      },
    ],
  },
  RECHARGE: {
    label: 'Recharge',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  STRIPE: {
    label: 'Stripe',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  'PROPERTY-FINDER': {
    label: 'Property Finder',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  SHOPIFY: {
    label: 'Shopify',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  TWILIO: {
    label: 'Twilio',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  GITHUB: {
    label: 'GitHub',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  POSTMARK: {
    label: 'Postmark',
    features: ['VERIFICATION'],
    fields: generic_fields.basic_auth,
  },
  TYPEFORM: {
    label: 'Typeform',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  XERO: {
    label: 'Xero',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  SVIX: {
    label: 'Svix',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  ADYEN: {
    label: 'Adyen',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  AKENEO: {
    label: 'Akeneo',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  GITLAB: {
    label: 'GitLab',
    features: ['VERIFICATION'],
    fields: [
      {
        label: 'API Key',
        name: 'api_key',
        type: 'password',
        placeholder: 'Secret key...',
        features: ['VERIFICATION'],
      },
    ],
  },
  WOOCOMMERCE: {
    label: 'WooCommerce',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  OURA: {
    label: 'Oura',
    features: ['HANDSHAKE', 'VERIFICATION'],
    fields: [
      {
        label: 'Webhook Signing Secret',
        name: 'webhook_secret_key',
        type: 'password',
        features: ['HANDSHAKE', 'VERIFICATION'],
      },
    ],
  },
  COMMERCELAYER: {
    label: 'Commerce Layer',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  HUBSPOT: {
    label: 'Hubspot',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  MAILGUN: {
    label: 'Mailgun',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  PERSONA: {
    label: 'Persona',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  PIPEDRIVE: {
    label: 'Pipedrive',
    features: ['VERIFICATION'],
    fields: generic_fields.basic_auth,
  },
  SENDGRID: {
    label: 'Sendgrid',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  WORKOS: {
    label: 'Workos',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  SYNCTERA: {
    label: 'Synctera',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  AWS_SNS: {
    label: 'AWS SNS',
    features: ['VERIFICATION'],
    fields: [] as Fields,
  },
  THREE_D_EYE: {
    label: '3d Eye',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  TWITCH: {
    label: 'Twitch',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  ENODE: {
    label: 'Enode',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  FAVRO: {
    label: 'Favro',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  LINEAR: {
    label: 'Linear',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  SHOPLINE: {
    label: 'Shopline',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  WIX: {
    label: 'Wix',
    features: ['VERIFICATION'],
    fields: [
      {
        label: 'Public Signing Key',
        name: 'webhook_secret_key',
        type: 'textarea',
        placeholder: 'Public Signing key...',
        features: ['VERIFICATION'],
      },
    ],
  },
  NMI: {
    label: 'NMI Payment Gateway',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  ORB: {
    label: 'Orb',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  PYLON: {
    label: 'Pylon',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  REPAY: {
    label: 'Repay',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  SQUARE: {
    label: 'Square',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  SOLIDGATE: {
    label: 'Solidgate',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  TRELLO: {
    label: 'Trello',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  EBAY: {
    label: 'Ebay',
    features: ['HANDSHAKE', 'VERIFICATION'],
    fields: [
      {
        label: 'Environment',
        name: 'environment',
        type: 'select',
        features: ['VERIFICATION'],
        options: [
          {
            value: 'PRODUCTION',
            label: 'Production',
          },
          {
            value: 'SANDBOX',
            label: 'Sandbox',
          },
        ],
      },
      {
        label: 'Developer ID',
        name: 'dev_id',
        type: 'text',
        features: ['VERIFICATION'],
        placeholder: 'Dev ID',
      },
      {
        label: 'Client ID',
        name: 'client_id',
        type: 'text',
        features: ['VERIFICATION'],
        placeholder: 'Client ID',
      },
      {
        label: 'Client Secret (Cert ID)',
        name: 'client_secret',
        type: 'text',
        features: ['VERIFICATION'],
        placeholder: 'Client Secret',
      },
      {
        label: 'Verification Token',
        name: 'verification_token',
        type: 'text',
        features: ['HANDSHAKE'],
        placeholder: 'between 32 and 80 chars',
      },
    ],
  },
  TELNYX: {
    label: 'Telnyx',
    features: ['VERIFICATION'],
    fields: generic_fields.public_key,
  },
  TOKENIO: {
    label: 'TokenIO',
    features: ['VERIFICATION'],
    fields: generic_fields.public_key,
  },
  FISERV: {
    label: 'Fiserv/Clover',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
  BONDSMITH: {
    label: 'Bondsmith',
    features: ['VERIFICATION'],
    fields: generic_fields.handled_hmac,
  },
};

export default integration_schemas;
