import styled, { css } from 'styled-components';

import { OrderByDirection } from '../../../../../../../../typings/API.interface';
import { Event } from '../../../../../../../../typings/Event.interface';
import { EventAttempt } from '../../../../../../../../typings/EventAttempt.interface';
import { Webhook } from '../../../../../../../../typings/Webhook.interface';
import { OrderBy } from '../../../../../typings/EventList.interface';
import { numberWithCommas } from '../../../../../utils';
import { getCurrentTimezoneAbreviation } from '../../../../../utils/date';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import LabelButton from '../../../../common/base/LabelButton';
import Text from '../../../../common/base/Text';
import DisplayDate from '../../../../common/DisplayDate';
import { Div } from '../../../../common/helpers/StyledUtils';
import { Status } from '../../../../common/Status';
import { ATTEMPT_TRIGGER_LABELS } from '../../../../../utils/attempt';

const StyledSelect = styled.select(
  ({ theme }) => css`
    margin: 0;
    width: 100%;
    border: none;
    font-size: ${theme.pxToRem(12)};
    font-weight: ${theme.font_weigths.medium};
    color: ${theme.colors.on.neutral.secondary_neutral};
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0;
    max-width: 100%;
    outline: none;
    background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.80667 10.4733L7.53333 12.2C7.79333 12.46 8.21333 12.46 8.47333 12.2L10.2 10.4733C10.62 10.0533 10.32 9.33334 9.72667 9.33334H6.27333C5.68 9.33334 5.38667 10.0533 5.80667 10.4733Z' fill='%23828282'/%3E%3Cpath d='M5.80667 5.52668L7.53333 3.80001C7.79333 3.54001 8.21333 3.54001 8.47333 3.80001L10.2 5.52668C10.62 5.94668 10.32 6.66668 9.72667 6.66668H6.27333C5.68 6.66668 5.38667 5.94668 5.80667 5.52668Z' fill='%23828282'/%3E%3C/svg%3E%0A")
      right center no-repeat;
    &:focus {
      outline: none;
    }
  `,
);

interface EventListLayout {
  [k: string]: (
    order_by: OrderBy,
    dir: OrderByDirection,
    actions: {
      updateOrder: (order_by: OrderBy, dir: OrderByDirection) => void;
    },
  ) => {
    widths: number[] | { min?: number; max?: number }[];
    headers: (string | JSX.Element)[];
    getEventRowFields: (
      event: Event,
      outdated: boolean,
      actions: {
        retry: (event: Event) => void;
        mute: (event: Event) => void;
        bookmark: (event: Event) => void;
      },
      webhook?: Webhook,
      cli_client?: any,
    ) => (string | JSX.Element | object)[];
    getAttemptRowFields: (
      attempt: EventAttempt,
      outdated: boolean,
      actions: {
        retry: (event: Event) => void;
        mute: (event: Event) => void;
      },
    ) => (string | JSX.Element | object)[];
  };
}

const event_list_layouts: EventListLayout = {
  cli: (order_by, dir, actions) => ({
    widths: [
      { max: 172, min: 172 },
      { max: 140, min: 140 },
      { min: 100 },
      { min: 128, max: 128 },
      { max: 172, min: 172 },
    ],
    headers: [
      <div key="date">
        <StyledSelect
          value={order_by}
          onChange={(e) => actions.updateOrder(e.target.value as OrderBy, dir)}>
          <option value="created_at">Event Date ({getCurrentTimezoneAbreviation()})</option>
          <option value="last_attempt_at">
            Last Attempt At ({getCurrentTimezoneAbreviation()})
          </option>
        </StyledSelect>
      </div>,
      'Status',
      'Connection',
      'Attempts',
      'Device Name',
    ],
    getEventRowFields: (event, outdated, actions, webhook, client) => [
      event[order_by] && <DisplayDate date={event[order_by]} />,
      <Div key={`${event.id}-status`} flex={{ gap: 2, justify: 'space-between', align: 'center' }}>
        <Status small key={event.id} {...event} />
        {event.status !== 'QUEUED' && webhook && (
          <Button.Permission
            role="member"
            small
            minimal
            icon="retry"
            icon_muted
            m={{ y: -1.5 }}
            onClick={(e) => {
              e.stopPropagation();
              actions.retry(event);
            }}
          />
        )}
      </Div>,
      webhook ? (
        <LabelButton
          to={`/connections/${webhook.id}`}
          monospace
          neutral
          label={webhook.full_name}
          small
        />
      ) : (
        <Text muted size="s">
          Connection deleted
        </Text>
      ),
      `${event.attempts || 0}`,
      client?.device_name,
    ],
    getAttemptRowFields: (attempt) => [
      <DisplayDate key={`${attempt.id}-date`} date={attempt.created_at} />,
      <Status small key={`${attempt.id}-status`} {...attempt} />,
      <Badge small key={`${attempt.id}-trigger`} muted>
        {ATTEMPT_TRIGGER_LABELS[attempt.trigger]}
      </Badge>,
      <Text key={`${attempt.id}-delivery`} size="s" monospace>
        {!attempt.delivery_latency ? '-' : `${numberWithCommas(attempt.delivery_latency)}ms`}
      </Text>,
      <Text key={`${attempt.id}-response`} size="s" monospace>
        {!attempt.response_latency ? '-' : `${numberWithCommas(attempt.response_latency)}ms`}
      </Text>,
    ],
  }),
  http: (order_by, dir, actions) => ({
    widths: [
      { max: 172, min: 172 },
      { max: 140, min: 140 },
      { min: 100 },
      { min: 128, max: 128 },
      { max: 174, min: 174 },
    ],
    headers: [
      <div key="date">
        <StyledSelect
          value={order_by}
          onChange={(e) => actions.updateOrder(e.target.value as OrderBy, dir)}>
          <option value="created_at">Event Date ({getCurrentTimezoneAbreviation()})</option>
          <option value="last_attempt_at">
            Last Attempt At ({getCurrentTimezoneAbreviation()})
          </option>
        </StyledSelect>
      </div>,
      'Status',
      'Connection',
      'Attempts',
      'Next Attempt',
    ],
    getEventRowFields: (event, outdated, actions, webhook) => [
      event[order_by] && <DisplayDate date={event[order_by]} />,
      <Div key={`${event.id}-status`} flex={{ gap: 2, justify: 'space-between', align: 'center' }}>
        <Status small key={event.id} {...event} />
        {event.status !== 'QUEUED' && webhook && (
          <Button.Permission
            role="member"
            small
            minimal
            icon="retry"
            icon_muted
            m={{ y: -1.5 }}
            onClick={(e) => {
              e.stopPropagation();
              actions.retry(event);
            }}
          />
        )}
      </Div>,
      webhook ? (
        <LabelButton
          to={`/connections/${webhook.id}`}
          monospace
          neutral
          label={webhook.full_name}
          small
        />
      ) : (
        <Text muted size="s">
          Connection deleted
        </Text>
      ),
      `${event.attempts || 0}`,
      event.next_attempt_at && (
        <Div
          key={`${event.id}-next-attempt`}
          flex={{ gap: 2, justify: 'space-between', align: 'center' }}>
          <DisplayDate date={event.next_attempt_at} />
          <Button.Permission
            role="member"
            small
            minimal
            icon="cancel"
            icon_muted
            m={{ y: -1.5 }}
            onClick={(e) => {
              e.stopPropagation();
              actions.mute(event);
            }}
          />
        </Div>
      ),
    ],
    getAttemptRowFields: (attempt) => [
      <DisplayDate key={`${attempt.id}-date`} date={attempt.created_at} />,
      <Status small key={`${attempt.id}-status`} {...attempt} />,
      <Badge small key={`${attempt.id}-trigger`} muted>
        {ATTEMPT_TRIGGER_LABELS[attempt.trigger]}
      </Badge>,
      <Text key={`${attempt.id}-delivery`} muted size="s" monospace>
        {!attempt.delivery_latency ? '-' : `${numberWithCommas(attempt.delivery_latency)}ms`}
      </Text>,
      <Text key={`${attempt.id}-response`} muted size="s" monospace>
        {!attempt.response_latency ? '-' : `${numberWithCommas(attempt.response_latency)}ms`}
      </Text>,
    ],
  }),
};

export default event_list_layouts;
