import { trigger } from '@dopplerhq/universal-import-core';
import { useContext, useState } from 'react';
import useSWR from 'swr';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import LINKS from '../../../../../configs/links';
import isEmpty from '../../../../../utils/isEmpty';
import Notification from '../../../../common/base/Alert';
import Button from '../../../../common/base/Button';
import { IconName } from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Skeleton from '../../../../common/base/Skeleton';
import Text from '../../../../common/base/Text';
import CopyableField from '../../../../common/CopyableField';
import { useDialog } from '../../../../common/Dialog';
import DropdownMenu from '../../../../common/DropdownMenu';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import Divider from '../../../../common/base/Divider';
import Tooltip from '../../../../common/base/Tooltip';
import { HookdeckAPIError } from '../../../../../client/Hookdeck';

const options = [
  { label: 'now', value: '0' },
  { label: 'in 1 hour', value: '3600' },
  { label: 'in 24 hours', value: '86400' },
];

const form_props = {
  validate: (values: any) => {
    if (!values.delay_sec) {
      return { delay_sec: 'Required' };
    }
    return {};
  },
  Fields: () => (
    <Div flex={{ justify: 'space-between', align: 'center' }} m={{ t: 6, b: 4 }}>
      <Text heading>Key expiration</Text>
      <SelectInput name="delay_sec" placeholder={'Select...'} required m={0} options={options} />
    </Div>
  ),
};

const getDelayLabel = (delay_sec: number): string | undefined => {
  return options.find((option) => option.value === delay_sec.toString())?.label.replace('in ', '');
};

const isUnauthorizedError = (error: unknown) => {
  if (error instanceof HookdeckAPIError && (error.status === 401 || error.status === 403))
    return true;
  return false;
};

const TeamApiKeys: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();

  const [alerts, setAlerts] = useState<{ [key: string]: string | undefined }>({});

  const {
    data: api_key,
    error: api_key_error,
    mutate: mutateApiKey,
  } = useSWR(
    APIMethodKeys.session.getCurrentApiKey(),
    () => HookdeckAPI.session.getCurrentApiKey(),
    {
      shouldRetryOnError: (error) => !isUnauthorizedError(error),
    },
  );
  const {
    data: signing_secret,
    error: signing_secret_error,
    mutate: mutateSigningSecret,
  } = useSWR(
    APIMethodKeys.session.getCurrentSigningSecret(),
    () => HookdeckAPI.session.getCurrentSigningSecret(),
    {
      shouldRetryOnError: (error) => !isUnauthorizedError(error),
    },
  );

  const rollApiKey = (api_key, delay_sec: number) => {
    HookdeckAPI.session
      .rollApiKey(api_key.id, { delay_sec })
      .then((res) => {
        setAlerts((prev) => ({
          ...prev,
          [res.key]:
            delay_sec > 0
              ? `Your API key was rolled, your old key will remain active for ${getDelayLabel(
                  delay_sec,
                )}.`
              : 'Your API key was rolled, your old key is now inactive.',
        }));
        mutateApiKey(res);
      })
      .catch(() => {
        addToast('error', 'Failed to roll API key');
      });
  };
  const rollSigningSecret = (signing_secret, delay_sec: number) => {
    HookdeckAPI.session
      .rollSigningSecret(signing_secret.id, { delay_sec })
      .then((res) => {
        setAlerts((prev) => ({
          ...prev,
          [res.key]:
            delay_sec > 0
              ? `Your signing secret was rolled, your old secret will remain active for ${getDelayLabel(
                  delay_sec,
                )}.`
              : 'Your signing secret was rolled, your old secret is now inactive.',
        }));
        mutateSigningSecret(res);
      })
      .catch(() => {
        addToast('error', 'Failed to roll signing secret');
      });
  };

  const handleDopplerTrigger = (name: string, value: string) => {
    trigger({ secretName: name, secretValue: value }).catch(() => {
      addToast('error', 'Failed to open Doppler');
    });
  };

  const secrets = [
    {
      title: 'API Key',
      description: 'Use your API Key to query the Hookdeck Admin API.',
      key: api_key?.key ?? 'api_key',
      link: {
        href: LINKS.api_ref,
        text: 'Learn more about the API ->',
      },
      options: [
        {
          label: 'Import to Doppler',
          icon: 'doppler_colored' as IconName,
          onClick: () => handleDopplerTrigger('HOOKDECK_API_KEY', api_key!.key),
        },
        {
          label: 'Roll Key',
          icon: 'roll' as IconName,
          danger: true,
          onClick: () =>
            showDialog(
              (values: any) => rollApiKey(api_key, values.delay_sec),
              () => null,
              {
                title: 'Roll API Key',
                message:
                  'By rolling your API Key, your current key will no longer be authorized to make requests to our Admin API. We will generate a new key for you to use. If you select a rollover period below, both your old and new API key will be authorized for the remainder of the window.',
                submit_label: 'Roll API Key',
                danger: true,
                cancel_label: 'Cancel',
                form_props: form_props,
              },
            ),
        },
      ],
      unauthorized: isUnauthorizedError(api_key_error) ? (
        <Div w={100}>
          <Tooltip tooltip="You currently don't have permission to perform this action. Contact your organization admin for further access.">
            <Div aria-disabled style={{ pointerEvents: 'none', opacity: 0.5 }}>
              <CopyableField
                monospace
                flex={{ grow: true }}
                value={
                  '************************************************************************************************************************'
                }
              />
            </Div>
          </Tooltip>
        </Div>
      ) : undefined,
    },
    {
      title: 'Signing Secret',
      description: 'Use your signing secret to verify Hookdeck’s signature.',
      key: signing_secret?.key ?? 'signing_secret',
      link: {
        href: LINKS.product_docs.signature_verification,
        text: 'Learn more about signature verification ->',
      },
      options: [
        {
          label: 'Import to Doppler',
          icon: 'doppler_colored' as IconName,
          onClick: () => handleDopplerTrigger('HOOKDECK_SIGNING_SECRET', signing_secret!.key),
        },
        {
          label: 'Roll Key',
          icon: 'roll' as IconName,
          danger: true,
          onClick: () =>
            showDialog(
              (values: any) => rollSigningSecret(signing_secret, values.delay_sec),
              () => null,
              {
                title: 'Roll Signing Secret',
                message: (
                  <Text>
                    By rotating your signing secret your current secret will no longer be able to
                    verify the signature. We will generate a new secret for you to use. If you
                    select a rollover period both your old and new secret will be active for the
                    remainder of the window. Make sure your server is configured to verify two
                    signatures, if not, the verification will fail but you will always be able to
                    retry those events at a later time. See{' '}
                    <Link href={LINKS.product_docs.signature_verification} target="_blank" primary>
                      signature verification
                    </Link>{' '}
                    and{' '}
                    <Link href={LINKS.product_docs.request_retries} target="_blank" primary>
                      retries
                    </Link>
                    .
                  </Text>
                ),
                submit_label: 'Roll Signing Secret',
                danger: true,
                cancel_label: 'Cancel',
                form_props: form_props,
              },
            ),
        },
      ],
      unauthorized: isUnauthorizedError(signing_secret_error) ? (
        <Div w={100}>
          <Tooltip tooltip="You currently don't have permission to perform this action. Contact your organization admin for further access.">
            <Div aria-disabled style={{ pointerEvents: 'none', opacity: 0.5 }}>
              <CopyableField
                monospace
                flex={{ grow: true }}
                value={
                  '************************************************************************************************************************'
                }
              />
            </Div>
          </Tooltip>
        </Div>
      ) : undefined,
    },
  ];

  const loading = isEmpty(api_key) && isEmpty(signing_secret);

  return (
    <>
      {secrets.map((secret) => (
        <Div m={{ b: 14 }} key={secret.title}>
          <Text heading as="h2" m={0}>
            {secret.title}
          </Text>
          <Text muted m={{ b: 2 }}>
            {secret.description}
          </Text>
          <Divider m={{ b: 4 }} />
          <Div flex={{ align: 'center', gap: 4 }} m={{ b: 4 }}>
            {secret.unauthorized ? (
              secret.unauthorized
            ) : loading ? (
              <>
                <Skeleton large flex={{ grow: true }} />
                <Skeleton variant={'square'} large />
              </>
            ) : (
              <>
                <CopyableField monospace flex={{ grow: true }} value={secret.key!} secret />
                <DropdownMenu
                  placement="bottom-end"
                  renderToggle={(opened, toggle) => (
                    <Button icon="vertical_more" outline onClick={() => toggle(!opened)} />
                  )}
                  options={secret.options}
                />
              </>
            )}
          </Div>
          {alerts[secret.key!] && (
            <Notification inline info m={{ b: 4 }}>
              {alerts[secret.key!]}
            </Notification>
          )}
          <Link href={secret.link.href}>{secret.link.text}</Link>
        </Div>
      ))}
    </>
  );
};

export default TeamApiKeys;
