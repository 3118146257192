import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { showChat } from '../../../utils/liveChat';
import Button from '../../common/base/Button';
import { StyledCard } from '../../common/base/Card';
import Container from '../../common/base/Container';
import Text from '../../common/base/Text';
import { Div } from '../../common/helpers/StyledUtils';
import { DashboardContext } from './DashboardContext';

export const StyledPaywallWrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: absolute;

  > div {
    z-index: ${({ theme }) => theme.zindex.modal};
    position: relative;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.surface.base.backdrop};
    z-index: ${({ theme }) => theme.zindex.sticky + 1};
    backdrop-filter: blur(1px);
  }
`;

const TeamMemberOverLimitModal = () => {
  const { subscription, organization_role } = useContext(DashboardContext);
  const location = useLocation();

  const eligible =
    subscription?.max_users === 1 &&
    organization_role !== 'owner' &&
    !location.pathname.includes('/settings');

  useEffect(() => {
    if (eligible) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'visible';
      };
    }
  }, [eligible]);

  if (!eligible) {
    return null;
  }

  return (
    <StyledPaywallWrapper>
      <Container small flex={{ align: 'center', justify: 'center' }} h={{ vh: 100 }}>
        <StyledCard p={8} raised={3}>
          <Text heading size="xl" center m={{ b: 4 }}>
            Users over plan limit
          </Text>
          <Text center>
            Your free plan only allows a single user on the organization. Only the organization
            owner can have access.
            <br />
            <br />
            To re-enable your access, ask your organization owner to upgrade your plan.{' '}
            <strong>Upgrade your plan</strong> to continue using Hookdeck or ask the owner to
            upgrade your plan.
          </Text>
          <Div flex={{ justify: 'center', gap: 3 }} m={{ t: 6 }}>
            <Button onClick={showChat} icon="chat" outline>
              Contact Us
            </Button>
            <Button to="/settings/organization/plans?highlight=max_users" icon="upgrade">
              Upgrade Plan
            </Button>
          </Div>
        </StyledCard>
      </Container>
    </StyledPaywallWrapper>
  );
};

export default TeamMemberOverLimitModal;
