import { useContext, useMemo } from 'react';

import { OrderByDirection } from '../../../../../typings/API.interface';
import { Event } from '../../../../../typings/Event.interface';
import APIMethodKeys from '../../client/APIMethodKeys';
import { EventListFiltersProps, OrderBy } from '../../typings/EventList.interface';
import { GlobalContext } from '../contexts/GlobalContext';
import useRefreshingList, { RefreshingListResult } from './useRefreshingList';

const status_filters = {
  successful: 'SUCCESSFUL',
  failed: 'FAILED',
  pending: 'QUEUED',
  paused: 'HOLD',
  scheduled: 'SCHEDULED',
};

export const formatEventFiltersForAPI = (filters: EventListFiltersProps, order_by: OrderBy) => {
  const formatted_filters: { [k: string]: any } = {};
  if (typeof filters.date === 'object' && (filters.date.min || filters.date.max)) {
    formatted_filters[order_by] = {
      gte: filters.date.min,
      lte: filters.date.max,
    };
  }

  if (Array.isArray(filters.response_status)) {
    formatted_filters.response_status = filters.response_status;
  } else if (filters.response_status?.min || filters.response_status?.max) {
    formatted_filters.response_status = {
      ...(filters.response_status.min ? { gte: parseInt(filters.response_status.min) } : {}),
      ...(filters.response_status.max ? { lte: parseInt(filters.response_status.max) } : {}),
    };
  }

  if (filters.attempts?.min || filters.attempts?.max) {
    formatted_filters.attempts = {
      ...(filters.attempts.min ? { gte: parseInt(filters.attempts.min) } : {}),
      ...(filters.attempts.max ? { lte: parseInt(filters.attempts.max) } : {}),
    };
  }

  formatted_filters.next = filters.next;
  formatted_filters.prev = filters.prev;
  if (filters.cli_user_id !== 'any') {
    formatted_filters.cli_user_id = filters.cli_user_id;
  } else {
    formatted_filters.cli_id = { any: true };
  }

  formatted_filters.error_code =
    !filters.error_code || filters.error_code.length === 0 ? undefined : filters.error_code;

  formatted_filters.search_term = filters.search_term ?? undefined;
  formatted_filters.headers = filters.request?.headers ?? undefined;
  formatted_filters.body = filters.request?.body ?? undefined;
  formatted_filters.path = filters.request?.path ?? undefined;
  formatted_filters.parsed_query = filters.request?.parsed_query ?? undefined;
  formatted_filters.successful_at = filters.successful_at ?? undefined;
  formatted_filters.webhook_id =
    !filters.webhook_id || filters.webhook_id.length === 0 ? undefined : filters.webhook_id;
  formatted_filters.source_id =
    !filters.source_id || filters.source_id.length === 0 ? undefined : filters.source_id;
  formatted_filters.destination_id =
    !filters.destination_id || filters.destination_id.length === 0
      ? undefined
      : filters.destination_id;

  formatted_filters.issue_id =
    !filters.issue_id || filters.issue_id.length === 0 ? undefined : filters.issue_id;
  formatted_filters.bulk_retry_id =
    !filters.bulk_retry_id || filters.bulk_retry_id.length === 0
      ? undefined
      : filters.bulk_retry_id;
  if (filters.status && filters.status?.length > 0) {
    formatted_filters.status = filters.status.map((status) => status_filters[status].toUpperCase());
  }

  return formatted_filters;
};

export interface EventListResults extends Omit<RefreshingListResult<Event>, 'models'> {
  events: Event[];
}

const useEventList = (
  filters: EventListFiltersProps,
  order_by: OrderBy,
  dir: OrderByDirection,
): EventListResults => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const formatted_filters = useMemo(
    () => formatEventFiltersForAPI({ ...filters }, order_by as any),
    [filters, order_by],
  );
  const { models: events, ...props } = useRefreshingList<Event>(
    APIMethodKeys.events.list,
    HookdeckAPI.events.list,
    formatted_filters,
    order_by,
    dir,
  );
  return {
    events,
    ...props,
  };
};

export default useEventList;
