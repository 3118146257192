import { addDays, differenceInHours, differenceInMilliseconds, format } from 'date-fns';
import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import { capitalizeFirstLetter, extractFromArray } from '../../../../utils';
import {
  cause_filter_breakdown,
  getRejectionCauseBreakdown,
} from '../../../../utils/rejection-causes';
import Alert from '../../../common/base/Alert';
import Badge from '../../../common/base/Badge';
import Button from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Container from '../../../common/base/Container';
import Icon from '../../../common/base/Icon';
import Link from '../../../common/base/Link';
import Loading from '../../../common/base/Loading';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import DisplayDate from '../../../common/DisplayDate';
import { Div } from '../../../common/helpers/StyledUtils';
import InfoTable from '../../../common/InfoTable';
import { useToasts } from '../../../common/Toast';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useCopyASCurl from '../../../hooks/useCopyAsCurl';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { DashboardContext } from '../DashboardContext';
import EntrySidebar from '../Requests/EntrySidebar';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../StyledView';
import useRequestEntriesList from './useRequestEntriesList';
import LabelButton from '../../../common/base/LabelButton';
import { getCurrentTimezoneAbreviation } from '../../../../utils/date';
import Table from '../../../common/Table';
import NotFound from '../NotFound';
import FullRequestData from '../../../common/Request/FullRequestData';

interface Query {
  selected_id?: string | string[];
}

const RequestView: React.FC = () => {
  const { id: request_id } = useParams<{ id: string }>();
  const [retrying, setRetrying] = useState(false);
  const { query, updateSearchQuery } = useSearchQuery<Query>();
  const selected_id = extractFromArray(query.selected_id) as string;
  const onSelected = (selected_id: string | null) =>
    updateSearchQuery({ selected_id: selected_id || undefined });
  const { addToast } = useToasts();

  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription, view, organization } = useContext(DashboardContext);

  const {
    data: request,
    error,
    isValidating,
    mutate,
  } = useSWR(APIMethodKeys.requests.get(request_id), () => HookdeckAPI.requests.get(request_id));

  const { entries, fetched, count, has_next, has_prev, next, prev, revalidate } =
    useRequestEntriesList(request, 50);

  const handleRetry = (webhook_id?: string) => {
    if (retrying) {
      return;
    }
    setRetrying(true);
    HookdeckAPI.requests
      .retry(request_id, webhook_id ? [webhook_id] : undefined)
      .then(({ request, events }) => {
        addToast('success', `Request has been retried and ${events.length} events were created.`);
        mutate(request, false);
        revalidate(events);
        if (events) {
          const event = events.find((e) => e.webhook_id === webhook_id);
          if (selected_id && event && selected_id !== event.id) {
            if (event) {
              onSelected(event.id);
            }
          }
        }
      })
      .catch(() => {
        addToast('error', 'Request could not be retried');
      });
    setRetrying(false);
  };

  const unique_webhook_ids = useMemo(
    () => (entries ? Array.from(new Set(entries.map((entry) => entry.webhook_id))).sort() : []),
    [entries],
  );

  const { data: webhooks } = useSWR(
    unique_webhook_ids.length > 0 && APIMethodKeys.webhooks.list({ id: unique_webhook_ids }),
    () => HookdeckAPI.webhooks.list({ id: unique_webhook_ids }),
  );

  const webhooks_by_id =
    webhooks &&
    webhooks.models.reduce((object, webhook) => ({ ...object, [webhook.id]: webhook }), {});

  const { data: source, error: source_error } = useSWR(
    request && APIMethodKeys.sources.get(request.source_id),
    () => HookdeckAPI.sources.get(request!.source_id),
  );

  const copyAsCurl = useCopyASCurl({
    type: 'request',
    id: request_id,
    event_data: request?.data ?? undefined,
  });

  if (error && error.response?.status === 404) {
    return (
      <NotFound
        title="Request not available or archived"
        description={`Requests can take a moment to become available in the dashboard. Alternatively if the request is older then ${
          subscription!.retention_days
        } days it's been archived.`}
        id={request_id}
        link={{ to: `/requets`, text: 'All Requests' }}
        extra_action={
          <Button
            icon={isValidating ? 'loading' : 'refresh'}
            disabled={isValidating}
            minimal
            onClick={() => mutate()}>
            Check again
          </Button>
        }
      />
    );
  }

  const loading =
    request === undefined ||
    (source === undefined && !source_error) ||
    (unique_webhook_ids.length > 0 && webhooks === undefined);

  if (loading) {
    return (
      <Div flex={{ justify: 'center' }} p={8}>
        <Loading />
      </Div>
    );
  }

  const rejection_cause_breakdown =
    request.rejection_cause &&
    source &&
    getRejectionCauseBreakdown(request.rejection_cause)({
      request,
      source,
    });

  const rows = entries.map((entry) => ({
    id: entry.id,
    selected: selected_id === entry.id,
    highlighted: selected_id === request.id,
    fields: [
      <DisplayDate key={entry.id} date={entry.created_at || request.created_at} />,
      'cause' in entry ? (
        <Div key={entry.id} flex={{ justify: 'space-between', gap: 2, align: 'center' }}>
          <Badge icon="block" muted monospace small>
            {cause_filter_breakdown[entry.cause](entry).label}
          </Badge>
          <Button.Permission
            role="member"
            small
            minimal
            icon="retry"
            icon_muted
            m={{ y: -1.5 }}
            onClick={(e) => {
              e.stopPropagation();
              handleRetry(entry.webhook_id);
            }}
          />
        </Div>
      ) : (
        <Badge icon="success" muted monospace key={entry.id} small>
          Created Event
        </Badge>
      ),
      !webhooks_by_id?.[entry.webhook_id] ? (
        <Text size="s" muted>
          Connection Deleted
        </Text>
      ) : (
        <LabelButton
          label={webhooks_by_id?.[entry.webhook_id]?.full_name}
          neutral
          monospace
          to={`/connections/${entry.webhook_id}`}
        />
      ),
    ],
  }));

  const archived_at = addDays(new Date(request.ingested_at), subscription!.retention_days);
  const hours_before_archive = differenceInHours(archived_at, new Date());

  return (
    <StyledViewWrapper>
      <StyledViewContent light>
        <PageNav
          breadcrumb={[
            {
              icon: 'requests',
              title: `${view === 'cli' ? 'CLI ' : ''}Requests`,
              path: `${view === 'cli' ? '/cli' : ''}/requests`,
            },
            { icon: 'requests', title: request_id, monospace: true },
          ]}>
          <Tooltip tooltip="Copy request as cURL" placement="bottom-end">
            <Button outline icon="copy" onClick={copyAsCurl}>
              cURL
            </Button>
          </Tooltip>
          <Tooltip
            disabled={!!request.rejection_cause}
            placement="bottom-end"
            tooltip="Accepted requests cannot be retried">
            <Button.Permission
              role="member"
              disabled={retrying || !request.rejection_cause}
              onClick={() => handleRetry()}
              outline
              icon="retry">
              {retrying ? <Loading /> : 'Retry'}
            </Button.Permission>
          </Tooltip>
        </PageNav>
        <Container large>
          <Text heading size="l" m={{ t: 16, b: 4 }}>
            Request Details
          </Text>
          <InfoTable
            entries={[
              {
                label: 'Status',
                element: request.rejection_cause ? (
                  <Badge danger subtle icon="block">
                    {request.rejection_cause}
                  </Badge>
                ) : (
                  <Badge success subtle icon="check">
                    Accepted
                  </Badge>
                ),
              },
              {
                label: 'Verified',
                element: (
                  <Badge muted success={request.verified}>
                    {capitalizeFirstLetter(String(request.verified))}
                  </Badge>
                ),
              },
              {
                label: 'Source',
                element: (
                  <>
                    {source_error &&
                      (source_error.response?.status === 410 ? (
                        <Text as="p" muted>
                          Deleted
                        </Text>
                      ) : (
                        <Text as="p" muted>
                          Error retrieving name
                        </Text>
                      ))}
                    {!source_error && (
                      <LabelButton
                        monospace
                        label={source!.name}
                        neutral
                        to={`/sources/${source!.id}`}
                      />
                    )}
                  </>
                ),
              },
              {
                label: 'Received At',
                element: (
                  <Text monospace>
                    {format(new Date(request.ingested_at), `yyyy-MM-dd HH:mm:ss`)}{' '}
                    {getCurrentTimezoneAbreviation()}
                  </Text>
                ),
              },
              {
                label: 'Created Events',
                element: (
                  <Badge muted>
                    {view === 'cli' ? request.cli_events_count : request.events_count}
                  </Badge>
                ),
              },
              {
                label: 'Ingestion Latency',
                element: (
                  <Text>
                    {differenceInMilliseconds(
                      new Date(request.created_at),
                      new Date(request.ingested_at),
                    )}{' '}
                    ms
                  </Text>
                ),
              },
              {
                label: 'Ignored Events',
                element:
                  request.ignored_count && request.ignored_count > 0 ? (
                    <Badge warning subtle>
                      {request.ignored_count}
                    </Badge>
                  ) : (
                    <Badge muted>{request.ignored_count}</Badge>
                  ),
              },
            ]}
          />
          {rejection_cause_breakdown?.text && (
            <StyledCard m={{ t: 4 }} p={{ x: 4, y: 3 }}>
              <Icon icon="info" left />
              {rejection_cause_breakdown?.text}
            </StyledCard>
          )}
          {hours_before_archive <= 24 && hours_before_archive >= 0 && (
            <Alert info inline m={{ t: 4 }}>
              This requests will be archived in{' '}
              {hours_before_archive === 0 ? 'less then an hour' : `${hours_before_archive} hours`}.
              {!organization && (
                <Link to="/settings/organization/plans?highlight=retention_days" m={{ l: 1 }}>
                  Upgrade <Icon small icon="upgrade" />
                </Link>
              )}
            </Alert>
          )}
          {view === 'cli' && request.events_count > 0 && (
            <Alert info inline m={{ t: 4 }}>
              This request also created {request.events_count} HTTP events.{' '}
              <Link to={`/requests/${request.id}`}>Switch to HTTP view</Link>
            </Alert>
          )}
          {view === 'http' && request.cli_events_count > 0 && (
            <Alert info inline m={{ t: 4 }}>
              This request also created {request.cli_events_count} CLI events.{' '}
              <Link to={`/cli/requests/${request.id}`}>Switch to CLI view</Link>
            </Alert>
          )}
          <Text heading size="l" m={{ t: 14, b: 4 }}>
            Request Data
          </Text>
          {request.data ? (
            <>
              <FullRequestData data={request.data} compact={false} type="request" id={request.id} />
            </>
          ) : (
            <>
              <Text as="p">
                This request is past your archival period of {subscription!.retention_days} days,
                the request data is no longer available.
              </Text>
              {!organization && (
                <Link to="/settings/organization/plans?highlight=retention_days" icon="upgrade">
                  Upgrade Plan
                </Link>
              )}
            </>
          )}
          <Text heading size="l" m={{ t: 16, b: 4 }}>
            Request Entries
          </Text>
          <StyledCard overflow_hidden m={{ b: 16 }}>
            <StyledCardSection p={{ x: 4, y: 3 }}>
              <Text subtitle>Events & Ignored Events</Text>
            </StyledCardSection>
            <Table
              headers={['Timestamp', 'Status', 'Connection']}
              widths={[{ max: 148, min: 148 }, { max: 222, min: 222 }, { min: 100 }]}
              current_count={count}
              loading={!fetched}
              onNextPage={has_next ? next : undefined}
              onPreviousPage={has_prev ? prev : undefined}
              onRowSelected={onSelected}
              rows={rows}
            />
          </StyledCard>
        </Container>
      </StyledViewContent>
      {selected_id && (
        <EntrySidebar
          request_id={request_id}
          entry_id={selected_id}
          onClose={() => onSelected(null)}
          retry={(request, webhook_ids) => handleRetry(webhook_ids[0])}
        />
      )}
    </StyledViewWrapper>
  );
};

export default RequestView;
