import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Prompt, Route, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import IssueTrigger from '../../../../../../../../typings/IssueTrigger.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import field_formats from '../../../../../utils/field-formatters';
import Button from '../../../../common/base/Button';
import Container from '../../../../common/base/Container';
import Divider from '../../../../common/base/Divider';
import Loading from '../../../../common/base/Loading';
import Tabs from '../../../../common/base/Tabs';
import Text from '../../../../common/base/Text';
import TextSwitch from '../../../../common/base/TextSwitch';
import { useDialog } from '../../../../common/Dialog';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { TeamPermission } from '../../../../contexts/TeamPermissionContext';
import IssuesList from '../../Issues/IssueList';
import { PageNav, SecondaryPageNav, StyledViewContent, StyledViewWrapper } from '../../StyledView';
import issue_trigger_forms from '../IssueTriggersList/Forms/issue_triggers_forms';
import NotFound from '../../NotFound';

const IssueTriggerView: React.FC = () => {
  const { id: issue_trigger_id } = useParams<{ id: string }>();
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const history = useHistory();

  const {
    data: issue_trigger,
    error,
    mutate,
  } = useSWR(APIMethodKeys.issue_triggers.get(issue_trigger_id), () =>
    HookdeckAPI.issue_triggers.get(issue_trigger_id),
  );

  const { data: issue_count } = useSWR(
    APIMethodKeys.issues.count({ status: 'OPENED', issue_trigger_id }),
    () => HookdeckAPI.issues.count({ status: 'OPENED', issue_trigger_id }),
  );

  const { data: integrations } = useSWR(APIMethodKeys.team_integrations.list(), () =>
    HookdeckAPI.team_integrations.list(),
  );

  if (error && error.response?.status === 404) {
    return (
      <NotFound
        title="Issue trigger not found"
        description={`This issue trigger has been deleted or does not exist within this project.`}
        id={issue_trigger_id}
        link={{ to: `/issue-triggers`, text: 'All Triggers' }}
      />
    );
  }

  if (!issue_trigger || integrations === undefined) {
    return (
      <Div flex={{ justify: 'center', align: 'center' }} h={{ vh: 100 }}>
        <Loading />
      </Div>
    );
  }

  const onDelete = () => {
    showDialog(
      () => {
        HookdeckAPI.issue_triggers
          .delete(issue_trigger.id)
          .then(() => {
            addToast('success', 'Issue trigger has been deleted.');
            history.push('/issue-triggers');
          })
          .catch(() => {
            addToast('error', 'Issue trigger could not be deleted.');
          });
      },
      () => null,
      {
        title: 'Are you sure you want to delete this trigger?',
        message: 'All associated issues with this trigger will also be deleted.',
        danger: true,
      },
    );
  };

  const { Fields, getInitialValues, formatValuesToAPIInput, validate } =
    issue_trigger_forms[issue_trigger.type];

  const intial_values = getInitialValues(integrations.models, issue_trigger);

  return (
    <StyledViewWrapper>
      <StyledViewContent light style={{ minHeight: '100vh' }}>
        <PageNav
          breadcrumb={[
            { icon: 'issues', title: 'Issues', path: '/issues' },
            { icon: 'issues', title: 'Issue Triggers', path: '/issue-triggers' },
            { icon: 'issues', title: issue_trigger_id, monospace: true },
          ]}
        />
        <SecondaryPageNav background_color="surface">
          <Div p={{ t: 3 }}>
            <Tabs
              active_tab={location.pathname.split('/').length === 3 ? 'settings' : 'issues'}
              border={false}
              tabs={[
                {
                  key: 'settings',
                  label: 'Settings',
                  to: `/issue-triggers/${issue_trigger_id}`,
                  icon: 'settings',
                },
                {
                  key: 'issues',
                  label: 'Issues',
                  to: `/issue-triggers/${issue_trigger_id}/issues`,
                  icon: 'inbox',
                  badge_label: issue_count?.count || undefined,
                  badge_theme: 'danger',
                },
              ]}
            />
          </Div>
        </SecondaryPageNav>
        <Container medium h={100}>
          <TeamPermission role="member">
            <Route exact path="/issue-triggers/:id">
              <Div m={{ b: 14, t: 14 }}>
                <Text heading m={0}>
                  Trigger Behavior
                </Text>
                <Text muted>The conditions that your trigger will execute on.</Text>
                <Divider m={{ t: 2, b: 4 }} />
                <Formik
                  initialValues={intial_values}
                  validate={async (values) => {
                    let errors: { name?: string } = {};
                    if (validate) {
                      errors = validate(values);
                    }
                    return errors;
                  }}
                  onSubmit={(values, { resetForm }) => {
                    return HookdeckAPI.issue_triggers
                      .update(issue_trigger_id, formatValuesToAPIInput(values as any))
                      .then((issue_trigger) => {
                        addToast('success', 'Issue trigger updated');
                        mutate(issue_trigger);
                        resetForm({
                          values: getInitialValues(integrations.models, issue_trigger),
                          touched: {},
                        });
                        return issue_trigger;
                      })
                      .catch(() => {
                        addToast('error', 'Error updating issue trigger');
                        return null;
                      });
                  }}>
                  {({ isSubmitting, isValid, dirty }) => (
                    <Form>
                      <Prompt
                        when={!isSubmitting && dirty}
                        message="Are you sure you want to quit without saving your work?"
                      />
                      <Fields integrations={integrations.models} muted={false} />
                      <Button.Permission
                        m={{ t: 4 }}
                        disabled={isSubmitting || !isValid}
                        submit
                        primary
                        outline
                        icon="save">
                        Save
                      </Button.Permission>
                    </Form>
                  )}
                </Formik>
              </Div>
              <Div m={{ b: 14 }}>
                <Text heading m={0}>
                  Trigger Name
                </Text>
                <Text muted>
                  Set a custom name to programmatically query for this issue trigger within the API.
                </Text>
                <Divider m={{ t: 2, b: 4 }} />
                <Formik
                  initialValues={{ name: issue_trigger.name }}
                  validate={async (values) => {
                    const errors: { name?: string } = {};
                    if (values.name && issue_trigger.name !== values.name) {
                      const used = await HookdeckAPI.issue_triggers.nameIsUsed(values.name);
                      if (used) {
                        errors.name = 'Name is already used.';
                      }
                    }
                  }}
                  onSubmit={(values, { resetForm }) => {
                    return HookdeckAPI.issue_triggers
                      .update(issue_trigger_id, { name: values.name || null })
                      .then((issue_trigger) => {
                        addToast('success', 'Issue trigger name updated');
                        mutate(issue_trigger);
                        resetForm({ values: { name: issue_trigger.name }, touched: {} });
                        return issue_trigger;
                      })
                      .catch(() => {
                        addToast('error', 'Error updating issue trigger name');
                        return null;
                      });
                  }}>
                  {({ isSubmitting, isValid, dirty }) => (
                    <Form>
                      <Prompt
                        when={!isSubmitting && dirty}
                        message="Are you sure you want to quit without saving your work?"
                      />
                      <TextInput
                        name="name"
                        format={field_formats.slugify}
                        placeholder="some-name"
                        monospace
                      />
                      <Button.Permission
                        disabled={isSubmitting || !isValid}
                        submit
                        primary
                        outline
                        icon="save">
                        Save
                      </Button.Permission>
                    </Form>
                  )}
                </Formik>
              </Div>
              <Div m={{ b: 14 }}>
                <Text heading m={0}>
                  Trigger Status
                </Text>
                <Text muted>Disable your trigger to mute all associated issues and alerts.</Text>
                <Divider m={{ t: 2, b: 4 }} />
                <TextSwitch
                  onSelect={(key: 'true' | 'false') => {
                    let statusPromise: Promise<any> = Promise.resolve();
                    if (key === 'true' && issue_trigger.disabled_at) {
                      statusPromise = HookdeckAPI.issue_triggers.enable(issue_trigger_id);
                    } else if (key === 'false' && !issue_trigger.disabled_at) {
                      statusPromise = HookdeckAPI.issue_triggers.disable(issue_trigger_id);
                    }
                    return statusPromise
                      .then((issue_trigger: IssueTrigger) => {
                        addToast(
                          'success',
                          `Issue trigger ${!issue_trigger.disabled_at ? 'enabled' : 'disabled'}`,
                        );
                        mutate(issue_trigger);
                        return issue_trigger;
                      })
                      .catch(() => {
                        addToast('error', 'Error updating issue trigger');
                        return null;
                      });
                  }}
                  active={issue_trigger.disabled_at ? 'false' : 'true'}
                  options={[
                    { key: 'true', label: 'Enabled' },
                    { key: 'false', label: 'Disabled' },
                  ]}
                />
              </Div>
              <Div m={{ b: 14 }}>
                <Text heading m={0}>
                  Delete Trigger
                </Text>
                <Text muted>Deletes this trigger and removes all associated issues.</Text>
                <Divider m={{ t: 2, b: 4 }} />
                <Button.Permission danger onClick={onDelete}>
                  Delete
                </Button.Permission>
              </Div>
            </Route>
            <Route exact path="/issue-triggers/:id/issues">
              <IssuesList issue_trigger_id={issue_trigger_id} sort_by="first_seen_at" />
            </Route>
          </TeamPermission>
        </Container>
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default IssueTriggerView;
