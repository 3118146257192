import { add, differenceInDays, formatDistanceToNow, startOfDay, startOfHour, sub } from 'date-fns';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import {
  BackpressureIssueWithData,
  DeliveryIssueWithData,
  TransformationIssue as TransformationIssueType,
} from '../../../../../../../typings/Issue.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { relative_dates, RelativeDate } from '../../../../utils/date';
import {
  getIssueTitleParts,
  issue_actions,
  ISSUE_STATUS_CONFIGS,
  issue_type_configs,
} from '../../../../utils/issues';
import Badge from '../../../common/base/Badge';
import Button, { ButtonGroup } from '../../../common/base/Button';
import { StyledCard } from '../../../common/base/Card';
import Container from '../../../common/base/Container';
import Icon from '../../../common/base/Icon';
import Loading from '../../../common/base/Loading';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import BulkRetryDropdown from '../../../common/BulkRetryDropdown';
import { Div } from '../../../common/helpers/StyledUtils';
import InfoTable from '../../../common/InfoTable';
import { useToasts } from '../../../common/Toast';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { DashboardContext } from '../DashboardContext';
import IssueTitle from '../Issues/IssueTitle';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../StyledView';
import BackpressureIssue from './BackpressureIssue';
import DeliveryIssue from './DeliveryIssue';
import TransformationIssue from './TransformationIssue';
import LabelButton from '../../../common/base/LabelButton';
import NotFound from '../NotFound';

const IssueView: React.FC = () => {
  const { id: issue_id } = useParams<{ id: string }>();

  const { HookdeckAPI } = useContext(GlobalContext);
  const { team, subscription } = useContext(DashboardContext);
  const {
    data: issue,
    mutate,
    error,
  } = useSWR(APIMethodKeys.issues.get(issue_id), () => HookdeckAPI.issues.get(issue_id));
  const { current: now } = useRef(new Date());
  const [refresh_key, setRefreshKey] = useState(new Date().getTime());
  const [resolve_action_applied, setResolveActionApplied] = useState(false);
  const { query, updateSearchQuery } = useSearchQuery<{
    date?: { relative: RelativeDate; min: string; max: string };
  }>();
  const setDate = (date) => updateSearchQuery({ date });

  const { addToast } = useToasts();

  const { data: webhooks } = useSWR(
    issue &&
      issue.type === 'delivery' &&
      APIMethodKeys.webhooks.list({ id: issue.aggregation_keys.webhook_id }),
    () =>
      HookdeckAPI.webhooks.list({
        id: (issue as DeliveryIssueWithData).aggregation_keys.webhook_id,
      }),
  );

  const { data: destinations } = useSWR(
    issue &&
      issue.type === 'backpressure' &&
      APIMethodKeys.destinations.list({ id: issue.aggregation_keys.destination_id }),
    () =>
      HookdeckAPI.destinations.list({
        id: (issue as BackpressureIssueWithData).aggregation_keys.destination_id,
      }),
  );

  const { data: transformations } = useSWR(
    issue &&
      issue.type === 'transformation' &&
      APIMethodKeys.transformations.list({ id: issue.aggregation_keys.transformation_id }),
    () =>
      HookdeckAPI.transformations.list({
        id: (issue as TransformationIssueType).aggregation_keys.transformation_id,
      }),
  );

  const webhooks_by_id = webhooks?.models.reduce(
    (object, webhook) => ({ ...object, [webhook.id]: webhook }),
    {},
  );

  const destinations_by_id = destinations?.models.reduce(
    (object, destination) => ({ ...object, [destination.id]: destination }),
    {},
  );

  const transformations_by_id = transformations?.models.reduce(
    (object, transformation) => ({ ...object, [transformation.id]: transformation }),
    {},
  );

  useEffect(() => {
    let interval;
    if (resolve_action_applied) {
      setRefreshKey(new Date().getTime());
      interval = setInterval(() => {
        setRefreshKey(new Date().getTime());
      }, 10000);
    }
    return () => interval && clearInterval(interval);
  }, [resolve_action_applied]);

  const applyAction = async (action: keyof typeof issue_actions) => {
    let promise;
    if (action === 'acknowledge') {
      promise = HookdeckAPI.issues.update(issue_id, { status: 'ACKNOWLEDGED' });
    } else if (action === 'resolve') {
      promise = HookdeckAPI.issues.update(issue_id, { status: 'RESOLVED' });
    } else if (action === 'reopen') {
      promise = HookdeckAPI.issues.update(issue_id, { status: 'OPENED' });
    } else if (action === 'ignore') {
      promise = HookdeckAPI.issues.update(issue_id, { status: 'IGNORED' });
    } else if (action === 'dismiss') {
      promise = HookdeckAPI.issues.dismiss(issue_id);
    }
    promise
      .then(() => {
        mutate();
        addToast('success', 'Issue status updated');
      })
      .catch(() => addToast('error', 'Failed to update issue'));
  };

  if (error && error.response?.status === 404) {
    return (
      <NotFound
        title="Issue not found"
        description={`This issue does not exist within this project.`}
        id={issue_id}
        link={{ to: `/issues`, text: 'All Issues' }}
      />
    );
  }

  if (
    !issue ||
    (issue.type === 'delivery' && !webhooks_by_id) ||
    (issue.type === 'transformation' && !transformations_by_id) ||
    (issue.type === 'backpressure' && !destinations_by_id)
  ) {
    return (
      <Div flex={{ justify: 'center' }} p={8}>
        <Loading />
      </Div>
    );
  }

  let start_date = query.date?.min
    ? new Date(query.date.min)
    : differenceInDays(new Date(), new Date(issue.first_seen_at)) > subscription!.retention_days
      ? startOfDay(sub(new Date(), { days: subscription!.retention_days }))
      : (startOfDay(new Date(issue.first_seen_at)) as Date);
  let end_date = query.date?.max ? new Date(query.date.max) : add(startOfHour(now), { hours: 1 });

  if (query.date?.relative) {
    const date = relative_dates[query.date?.relative].convert(new Date());
    start_date = date.min;
    end_date = date.max;
  }

  const render_data = {
    webhooks_by_id,
    transformations_by_id,
    destinations_by_id,
  };

  const { default_action, current_action } = ISSUE_STATUS_CONFIGS[issue.status];
  const title_parts = getIssueTitleParts(issue, render_data);

  return (
    <StyledViewWrapper>
      <StyledViewContent light>
        <PageNav
          breadcrumb={[
            {
              icon: 'issues',
              title: `Issues`,
              path: `/issues`,
            },
            { icon: 'issues', title: issue_id, monospace: true },
          ]}>
          <ButtonGroup
            more_options={Object.keys(issue_actions)
              .filter((key) => {
                if (key === default_action || key === current_action) {
                  return false;
                }
                if (key === 'dismiss' && issue.dismissed_at) {
                  return null;
                }
                return issue.status === 'OPENED' ? key !== 'reopen' : true;
              })
              .map((key: keyof typeof issue_actions) => ({
                label: issue_actions[key].label,
                danger: issue_actions[key].danger,
                icon: issue_actions[key].icon,
                onClick: () => applyAction(key),
              }))}>
            <Button.Permission outline onClick={() => applyAction(default_action)}>
              <Icon icon={issue_actions[default_action].icon} muted left />
              {issue_actions[default_action].label}
            </Button.Permission>
          </ButtonGroup>
          {issue &&
            issue.type === 'transformation' &&
            issue.aggregation_keys.log_level.includes('fatal') && (
              <BulkRetryDropdown
                model="ignored_events"
                outline
                filters={{
                  transformation_id: issue.aggregation_keys.transformation_id[0],
                  cause: ['TRANSFORMATION_FAILED'],
                  // The API doesn't support date filtering for ignored events but it should probably be added
                  // date: { min: start_date.toISOString(), max: end_date.toISOString() },
                }}
                order_by="created_at"
                dir="asc"
                onSubmit={() => setResolveActionApplied(true)}
              />
            )}
          {issue &&
            issue.type === 'transformation' &&
            issue.aggregation_keys.log_level.includes('fatal') && (
              <Button
                icon="edit"
                outline
                to={`/transformations/${issue.aggregation_keys.transformation_id[0]}/edit`}>
                Edit Transformation
              </Button>
            )}
          {issue && issue.type === 'delivery' && (
            <BulkRetryDropdown
              model="events"
              outline
              filters={{
                ...issue.aggregation_keys,
                response_status: issue.aggregation_keys.response_status.map(
                  (status) => `${status}`,
                ),
                status: ['failed'],
                date: { min: start_date.toISOString(), max: end_date.toISOString() },
                issue_id: issue.id,
              }}
              order_by="created_at"
              dir="asc"
              onSubmit={() => setResolveActionApplied(true)}
            />
          )}
        </PageNav>
        <Container large p={{ y: 14 }}>
          <Text size="l" heading m={{ b: 4 }}>
            Issue Details
          </Text>
          <StyledCard p={{ x: 4, y: 3 }} m={{ b: 4 }} flex={{ align: 'center' }}>
            <Icon icon={ISSUE_STATUS_CONFIGS[issue.status].icon} muted left />
            <IssueTitle issue={issue} parts={title_parts} />
          </StyledCard>
          <InfoTable
            m={{ b: 4 }}
            entries={[
              {
                label: 'Issue Status',
                element: (
                  <Badge
                    subtle={ISSUE_STATUS_CONFIGS[issue.status].badge_theme !== 'muted'}
                    {...{ [ISSUE_STATUS_CONFIGS[issue.status].badge_theme]: true }}
                    icon={ISSUE_STATUS_CONFIGS[issue.status].icon}>
                    {ISSUE_STATUS_CONFIGS[issue.status].label}
                  </Badge>
                ),
              },
              {
                label: 'Issue Age',
                element: (
                  <Tooltip tooltip={issue.first_seen_at} copyable placement="bottom-end">
                    <Text>{formatDistanceToNow(new Date(issue.first_seen_at))} old</Text>
                  </Tooltip>
                ),
              },
              {
                label: 'Issue Type',
                element: (
                  <Badge muted icon={issue_type_configs[issue.type].icon}>
                    {issue_type_configs[issue.type].label}
                  </Badge>
                ),
              },
              {
                label: 'Last Seen',
                element: (
                  <Tooltip tooltip={issue.last_seen_at} copyable placement="bottom-end">
                    <Text>
                      {formatDistanceToNow(new Date(issue.last_seen_at), {
                        addSuffix: true,
                      })}
                    </Text>
                  </Tooltip>
                ),
              },
              {
                label: 'Triggered By',
                element: (
                  <LabelButton
                    monospace
                    label_icon="issue_trigger"
                    label={issue.issue_trigger_id}
                    to={`/issue-triggers/${issue.issue_trigger_id}`}
                    neutral
                  />
                ),
              },
            ]}
          />
          {issue && issue.type === 'delivery' && (
            <DeliveryIssue
              issue={issue}
              start_date={start_date}
              end_date={end_date}
              relative_date={query.date?.relative}
              setDate={setDate}
              refresh_key={String(refresh_key)}
            />
          )}
          {issue && issue.type === 'transformation' && (
            <TransformationIssue
              issue={issue}
              start_date={start_date}
              end_date={end_date}
              relative_date={query.date?.relative}
              setDate={setDate}
              refresh_key={String(refresh_key)}
            />
          )}
          {issue && issue.type === 'backpressure' && <BackpressureIssue issue={issue} />}
          <Div p={6} />
        </Container>
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default IssueView;
