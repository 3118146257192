import { useField } from 'formik';
import { Fragment, useContext, useState } from 'react';
import useSWR from 'swr';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import RadioGroupInput from '../../../../common/Form/Fields/RadioInput';
import Search from '../../../../common/Search';
import Button, { ClickableArea } from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import Loading from '../../../../common/base/Loading';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import resource_details_form_props from '../Forms/resource_details';
import source_configuration_form_props from '../Forms/source_configuration';
import Divider from '../../../../common/base/Divider';

const SourceSection = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const [{ value: use }] = useField('source_use');
  const [{ value: source_id }, , { setValue: setSourceId }] = useField('source_id');
  const [search_term, setSearchTerm] = useState<string>('');

  const filters: Record<string, any> = {
    limit: 100,
    name: search_term ? { contains: search_term } : undefined,
  };
  const { data: sources } = useSWR(use === 'select' && APIMethodKeys.sources.list(filters), () =>
    HookdeckAPI.sources.list(filters),
  );
  const { data: select_source } = useSWR(
    use === 'select' && source_id && APIMethodKeys.sources.get(source_id),
    () => HookdeckAPI.sources.get(source_id),
  );

  const active_source =
    (source_id && sources?.models.find((source) => source.id === source_id)) || select_source;

  return (
    <StyledCard>
      <StyledCardSection muted>
        <RadioGroupInput
          name={'source_use'}
          type="tabs"
          inline
          options={[
            { value: 'new', label: 'Create new source' },
            {
              value: 'select',
              label: 'Use existing source',
            },
          ]}
        />
      </StyledCardSection>
      {use === 'select' &&
        (source_id ? (
          <StyledCardSection>
            <Div flex={{ align: 'center', justify: 'space-between' }} p={{ l: 3, r: 1, y: 1 }}>
              <Div flex={{ align: 'center' }}>
                <Icon icon="source" left />
                <Text monospace text_wrap={false} ellipsis>
                  {active_source ? active_source.name : <Loading />}
                </Text>
                <Icon right icon="check" primary />
              </Div>
              <Button icon="close" minimal small onClick={() => setSourceId(null)} />
            </Div>
          </StyledCardSection>
        ) : (
          <>
            <StyledCardSection>
              <Search
                focus
                borderless
                onChange={setSearchTerm}
                value={search_term}
                placeholder="Search your sources by name..."
              />
            </StyledCardSection>
            <StyledCardSection scroll={true} h={{ px: 152 }} flex={{ direction: 'column' }}>
              {!sources && (
                <Div p={{ x: 3, y: 2 }}>
                  <Loading />
                </Div>
              )}
              {sources?.models.map((source, i) => (
                <Fragment key={source.id}>
                  {i !== 0 && <Divider />}
                  <ClickableArea
                    flex={{ align: 'center', justify: 'space-between' }}
                    p={{ x: 3, y: 2 }}
                    onClick={() => setSourceId(source.id)}>
                    <Div flex={{ align: 'center' }}>
                      <Icon icon="source" left />
                      <Text monospace text_wrap={false} ellipsis>
                        {source.name}
                      </Text>
                    </Div>
                  </ClickableArea>
                </Fragment>
              ))}
              {sources && (
                <>
                  <Divider />
                  <Text
                    muted
                    center
                    p={{ y: 6, x: 4 }}
                    flex={{
                      grow: sources?.models && sources?.models.length <= 4 ? true : undefined,
                      align: 'center',
                      justify: 'center',
                    }}>
                    {sources.pagination.next
                      ? 'Try searching to find more sources...'
                      : 'That’s it! You don’t have any other sources.'}
                  </Text>
                </>
              )}
            </StyledCardSection>
          </>
        ))}
      {use === 'new' && (
        <StyledCardSection>
          <Div p={4}>
            <resource_details_form_props.Fields
              prefix="source"
              placeholder="shopify-prod"
              help="Provide a name that represents the sender of your events."
              name_required
            />
          </Div>
          <source_configuration_form_props.Fields prefix="source" />
        </StyledCardSection>
      )}
    </StyledCard>
  );
};

export default SourceSection;
