export const DEFAULT_PROJECT_SKU = '0005-001';

const THROUGHPUT_SKUS = {
  available: {
    '0005-001': {
      label: 'Small-5',
      allocation: 5,
      price: 0,
    },
    '0010-001': {
      label: 'Small-10',
      allocation: 10,
      price: 15 * 100,
    },
    '0025-001': {
      label: 'Small-25',
      allocation: 25,
      price: 50 * 100,
    },
    '0050-001': {
      label: 'Medium-50',
      allocation: 50,
      price: 110 * 100,
    },
    '0100-001': {
      label: 'Medium-100',
      allocation: 100,
      price: 210 * 100,
    },
    '0200-001': {
      label: 'Large-200',
      allocation: 200,
      price: 400 * 100,
    },
  },
  legacy: {
    '0020-001': {
      label: 'Small-20',
      allocation: 20,
      price: 40 * 100,
    },
    '0040-001': {
      label: 'Medium-40',
      allocation: 40,
      price: 90 * 100,
    },
    '0080-001': {
      label: 'Medium-80',
      allocation: 80,
      price: 180 * 100,
    },
    '0160-001': {
      label: 'Large-160',
      allocation: 160,
      price: 320 * 100,
    },
    '0250-001': {
      label: 'Large-250',
      allocation: 250,
      price: 500 * 100,
    },
    '0300-001': {
      label: 'Large-300',
      allocation: 300,
      price: 600 * 100,
    },
  },
} as const;

export default THROUGHPUT_SKUS;
