import { useField, useFormikContext } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Text from '../../base/Text';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import Link from '../../base/Link';
import { usePermissionAwareDisabled } from '../../../contexts/TeamPermissionContext';
import Icon from '../../base/Icon';

interface Props extends StyledUtilsProps {
  label?: string;
  name: string;
  help?: string;
  disabled?: boolean;
  default_value?: string;
  clearable?: boolean;
  placeholder?: string;
  maxlength?: number;
  minlength?: number;
  required?: boolean;
  focus?: boolean;
  auto_resize?: boolean;
  show_error?: boolean;
  monospace?: boolean;
  format?: (v: string) => string;
  validate?: (string) => Promise<string> | string;
  onBlur?: () => void;
}

const StyledField = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0 !important;
  }
`;

const StyledInputWrapper = styled.div`
  textarea {
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
  }
`;

const StyledInput = styled(Div)<{ error: boolean; auto_resize: boolean; monospace: boolean }>(
  ({ theme, error, auto_resize, monospace }) => css`
    position: relative;
    display: flex;
    font-size: ${theme.pxToRem(13)};
    line-height: ${theme.pxToRem(20)};

    ${error &&
    css`
      textarea:not(:focus) {
        ::placeholder {
          color: ${theme.colors.on.neutral.danger};
        }
        border-color: ${theme.colors.on.hue_container.danger};
        background-color: ${theme.colors.surface.container.danger};
      }
    `}

    ${auto_resize &&
    css`
      & grammarly-extension {
        display: none;
      }
    `}

    textarea {
      padding: ${theme.spacing(1.25)} ${theme.spacing(3)};
      line-height: ${theme.pxToRem(20)};
      ${auto_resize &&
      css`
        word-break: break-all;
        resize: none;
        ::-webkit-scrollbar {
          display: none;
        }
      `}
      ${monospace &&
      css`
        font-family:
          JetBrains Mono,
          monospace;
      `}
      &:disabled {
        background-color: ${theme.colors.surface.base.disabled};
      }
    }
  `,
);

const TextAreaInput: React.FC<Props> = ({
  label,
  name,
  default_value,
  placeholder,
  clearable,
  required,
  maxlength,
  minlength,
  help,
  disabled: _disabled,
  focus,
  show_error = true,
  auto_resize = false,
  monospace = false,
  format,
  validate,
  onBlur,
  ...other_props
}) => {
  const disabled = usePermissionAwareDisabled(_disabled);

  const formatValue = (v: string): string => {
    if (format) {
      v = format(v);
    }
    if (auto_resize) {
      v = v.replace(/\n/g, '');
    }
    return v;
  };

  const validateRequired = (value: string) => {
    if (value?.length < 1 && required) return 'Required';
  };

  const { submitCount } = useFormikContext();
  const [field, { value, error, touched }, { setValue, setTouched }] = useField({
    name,
    validate: validate || validateRequired,
  });
  const [ignore_default, setIgnoreDefault] = useState(field.value && !touched);
  const last_default_value = useRef(formatValue(default_value || ''));

  const [ref, setRef] = useState<HTMLTextAreaElement | null>(null);
  const [ref_width, setRefWidth] = useState<number | null>(null);

  useEffect(() => {
    if (focus) {
      ref?.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (default_value !== undefined && !ignore_default) {
      setValue(formatValue(default_value));
    }
  }, [default_value, ignore_default]);

  useEffect(() => {
    if (field.value === '' && !ignore_default) {
      setIgnoreDefault(false);
    }
  }, [field.value, default_value]);

  useEffect(() => {
    if (
      field.value === '' &&
      ignore_default &&
      last_default_value.current !== formatValue(default_value || '')
    ) {
      setIgnoreDefault(false);
      setValue(formatValue(default_value || ''));
    }
  }, [field.value, default_value, ignore_default, last_default_value]);

  // Prevent the component from being considered uncontrolled because no value is set yet
  if (field.value === undefined) {
    field.value = '';
  }

  useEffect(() => {
    if (!ref || !auto_resize) return;
    const resizeObserver = new ResizeObserver((observed) => {
      setRefWidth(observed[0].contentRect.width);
    });
    resizeObserver.observe(ref);
    return () => resizeObserver.disconnect(); // clean up
  }, [ref]);

  const line = useMemo(() => {
    if (!ref || !auto_resize) return;
    const v = value ? value.length / ((ref.offsetWidth - 24) / 8) : 1;
    return ref && Math.ceil(v);
  }, [ref, value, ref_width]);

  const has_error = show_error && (touched || submitCount > 0) && !!error;

  return (
    <Div m={{ b: 4 }} {...other_props}>
      <StyledField>
        <Div flex={{ justify: 'space-between', align: 'center' }}>
          {label ? (
            <label htmlFor={name}>
              <Text subtitle size="s">
                {label}
                {required && (
                  <Text as="span" danger>
                    *
                  </Text>
                )}
              </Text>
            </label>
          ) : (
            <Div />
          )}
          {clearable && !disabled && (
            <Link icon="delete" m={{ r: 1, b: 0.5 }} onClick={() => setValue(null)} />
          )}
        </Div>
        <StyledInputWrapper>
          <StyledInput error={has_error} auto_resize={auto_resize} monospace={monospace}>
            <textarea
              {...field}
              ref={(newRef) => setRef(newRef || null)}
              rows={line || undefined}
              onChange={(e) => {
                setIgnoreDefault(true);
                e.target.value = formatValue(e.target.value);
                field.onChange(e);
                last_default_value.current = formatValue(default_value || '');
              }}
              disabled={disabled}
              placeholder={default_value || placeholder || ''}
              required={required}
              onBlur={() => {
                setTouched(true);
                onBlur && onBlur();
              }}
              maxLength={maxlength}
              minLength={minlength}
            />
          </StyledInput>
        </StyledInputWrapper>
        {has_error && (
          <Text m={{ t: 1, b: 0 }} size="s" as="p" danger>
            <Icon icon="info" left={1} />
            {error}
          </Text>
        )}
        {help && !has_error && (
          <Text m={{ t: 1, b: 0 }} size="s" as="p" muted>
            <Icon icon="info" left={1} />
            {help}
          </Text>
        )}
      </StyledField>
    </Div>
  );
};

export default TextAreaInput;
