import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import useSWR from 'swr';

import { IssueType } from '../../../../../../../../typings/Issue.interface';
import IssueTrigger from '../../../../../../../../typings/IssueTrigger.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { issue_type_configs } from '../../../../../utils/issues';
import Button from '../../../../common/base/Button';
import Divider from '../../../../common/base/Divider';
import Icon from '../../../../common/base/Icon';
import Loading from '../../../../common/base/Loading';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import Modal from '../../../../common/Modal';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { TeamPermission } from '../../../../contexts/TeamPermissionContext';
import { IssueTriggersRenderData } from './';
import issue_trigger_forms from './Forms/issue_triggers_forms';
import IssueTriggerCard from './IssueTriggerCard';

const IssueTriggersGroup: React.FC<{
  type: IssueType;
  issue_triggers: IssueTrigger[];
  render_data: IssueTriggersRenderData;
  onIssueTriggerCreated: (issue_trigger: IssueTrigger) => void;
}> = ({ type, issue_triggers, render_data, onIssueTriggerCreated }) => {
  const { label, icon, description } = issue_type_configs[type];
  const history = useHistory();
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const Fields = issue_trigger_forms[type].Fields;

  const { data: integrations } = useSWR(APIMethodKeys.team_integrations.list(), () =>
    HookdeckAPI.team_integrations.list(),
  );

  if (integrations === undefined) {
    return (
      <Div flex={{ justify: 'center' }} p={8}>
        <Loading />
      </Div>
    );
  }

  const onClose = () => {
    history.push({
      pathname: '/issue-triggers',
      search: location.search,
      state: { scroll: false },
    });
  };

  return (
    <>
      <Div flex={{ justify: 'space-between' }}>
        <Div>
          <Div flex={{ align: 'center' }}>
            <Icon icon={icon} left />
            <Text heading as="h2" m={0}>
              {label} Issue Triggers
            </Text>
          </Div>
          <Text as="p" muted m={0}>
            {description}
          </Text>
        </Div>
        <Button.Permission
          role="member"
          outline
          primary
          style={{
            display: location.pathname === `/issue-triggers/create/${type}` ? 'none' : 'block',
          }}
          to={(location) => ({
            pathname: `/issue-triggers/create/${type}`,
            search: location.search,
            state: { scroll: false },
          })}
          icon="add_circle">
          Create
        </Button.Permission>
      </Div>
      <Divider m={{ t: 2, b: 4 }} />
      {issue_triggers.map((issue_trigger) => (
        <Div m={{ b: 4 }} key={issue_trigger.id}>
          <IssueTriggerCard issue_trigger={issue_trigger} render_data={render_data} />
        </Div>
      ))}
      {issue_triggers.length === 0 && (
        <Div m={{ b: 4 }}>
          <Text muted>No {type} issue triggers...</Text>
        </Div>
      )}
      <Route
        path={`/issue-triggers/create/${type}`}
        render={() => (
          <TeamPermission role="member">
            <Formik
              initialValues={issue_trigger_forms[type].getInitialValues(integrations.models)}
              validate={issue_trigger_forms[type].validate}
              onSubmit={(values) => {
                return HookdeckAPI.issue_triggers
                  .create(issue_trigger_forms[type].formatValuesToAPIInput(values as any))
                  .then((issue_trigger) => {
                    addToast('success', 'Created issue trigger');
                    onIssueTriggerCreated(issue_trigger);
                    history.push({
                      pathname: '/issue-triggers',
                      search: location.search,
                      state: { scroll: false },
                    });
                    return issue_trigger;
                  })
                  .catch(() => {
                    addToast('error', 'Error creating issue trigger');
                    return null;
                  });
              }}>
              {({ isSubmitting, isValid }) => (
                <Form>
                  <Modal
                    size="large"
                    onCancel={onClose}
                    onClose={onClose}
                    cancel_label="Cancel"
                    submit_disabled={isSubmitting || !isValid}
                    is_submitting={isSubmitting}
                    title={`New ${issue_type_configs[type].label} Issue Trigger`}
                    submit_label="Create Trigger">
                    <Fields integrations={integrations.models} muted={true} />
                  </Modal>
                </Form>
              )}
            </Formik>
          </TeamPermission>
        )}
      />
    </>
  );
};

export default IssueTriggersGroup;
