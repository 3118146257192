import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import Icon from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';
import { StyledNavLinks } from './';
import useSWR from 'swr';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { DashboardContext } from '../DashboardContext';

const SettingsNav = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { organization } = useContext(DashboardContext);

  const { data: quota_excess_count } = useSWR(
    APIMethodKeys.quotas.countExceeded('max_requests_per_second'),
    () => HookdeckAPI.quotas.countExceeded('max_requests_per_second'),
  );

  return (
    <Div p={{ x: 3, y: 4 }}>
      <Text muted heading size="s" m={{ x: 2 }}>
        Project
      </Text>
      <StyledNavLinks>
        <NavLink to="/settings/project/general">
          <Icon left icon="settings" />
          <Text subtitle size="m">
            General
          </Text>
        </NavLink>
        <NavLink to="/settings/project/secrets">
          <Icon left icon="key" />
          <Text subtitle size="m">
            Secrets
          </Text>
        </NavLink>
        <NavLink to="/settings/project/access">
          <Icon left icon="group" />
          <Text subtitle size="m">
            Access Control
          </Text>
        </NavLink>
        <NavLink to="/settings/project/integrations">
          <Icon left icon="connect" />
          <Text subtitle size="m">
            Integrations
          </Text>
        </NavLink>
        <NavLink to="/settings/project/quotas">
          <Icon left icon="rate_limit" />
          <Text subtitle size="m">
            Quotas
          </Text>
          {quota_excess_count && quota_excess_count.count > 0 && (
            <Div m={{ l: 'auto' }}>
              <Icon danger icon="error" m={{ r: 1 }} />
            </Div>
          )}
        </NavLink>
      </StyledNavLinks>
      <Text muted heading size="s" m={{ x: 2, t: 4 }}>
        Organization
      </Text>
      <StyledNavLinks>
        <NavLink to="/settings/organization/general">
          <Icon left icon="settings" />
          <Text subtitle size="m">
            General
          </Text>
        </NavLink>
        <NavLink to="/settings/organization/members">
          <Icon left icon="group" />
          <Text subtitle size="m">
            Members
          </Text>
        </NavLink>
        <NavLink to="/settings/organization/billing">
          <Icon left icon="wallet" />
          <Text subtitle size="m">
            Billing
          </Text>
        </NavLink>
        {!organization?.workos_directory_id && !organization?.workos_connection_id && (
          <NavLink to="/settings/organization/plans">
            <Icon left icon="checklist" />
            <Text subtitle size="m">
              Plans
            </Text>
          </NavLink>
        )}
      </StyledNavLinks>
      <Text muted heading size="s" m={{ x: 2, t: 4 }}>
        Account
      </Text>
      <StyledNavLinks>
        <NavLink to="/settings/account/profile">
          <Icon left icon="account" />
          <Text subtitle size="m">
            Profile
          </Text>
        </NavLink>
      </StyledNavLinks>
    </Div>
  );
};

export default SettingsNav;
