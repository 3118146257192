import { Formik, useField } from 'formik';
import { useContext } from 'react';
import styled, { css } from 'styled-components';
import useSWR from 'swr';
import { Grid, GridUnit } from '@hookdeck/theme';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import Badge from '../../../../common/base/Badge';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Text from '../../../../common/base/Text';
import { StyledRadio } from '../../../../common/Form/Fields/RadioInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import Modal from '../../../../common/Modal';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import { DashboardContext } from '../../DashboardContext';
import { showChat } from '../../../../../utils/liveChat';
import Icon from '../../../../common/base/Icon';
import { TeamPermission } from '../../../../contexts/TeamPermissionContext';

const StyleRadioRow = styled(StyledRadio)(
  ({ theme, checked }) => css`
    ${checked &&
    css`
      background-color: ${theme.colors.surface.base.background};
    `}
  `,
);

const RadioTableInput: React.FC<{
  current_value: string;
  skus: { [key: string]: any };
  name: string;
}> = ({ skus, name, current_value }) => {
  const [field] = useField(name);

  const { team_role } = useContext(DashboardContext);

  const skus_array = Object.entries(skus);

  const disabled = team_role !== 'admin';

  return (
    <>
      <div role="group">
        {skus_array.map(([sku, config], i) => {
          return (
            <StyledCardSection key={sku}>
              <StyleRadioRow
                key={sku}
                as="label"
                inline={true}
                type={'plain'}
                highlight={true}
                disabled={disabled}
                m={0}
                p={3}
                checked={field.value === sku}>
                <Grid>
                  <GridUnit size={1.25 / 3}>
                    <Div flex={{ align: 'center' }}>
                      <input
                        {...field}
                        onChange={() => field.onChange({ target: { value: sku, name } })}
                        checked={field.value === sku}
                        disabled={disabled}
                        type="radio"
                      />
                      <Badge muted>{config.label}</Badge>
                      {current_value === sku && (
                        <Badge m={{ l: 2 }} primary subtle>
                          Current {config.legacy && '(Legacy)'}
                        </Badge>
                      )}
                    </Div>
                  </GridUnit>
                  <GridUnit size={1 / 3}>
                    <Text subtitle>{config.allocation} events per second</Text>
                  </GridUnit>
                  <GridUnit size={0.75 / 3}>
                    <Div flex={{ justify: 'flex-end', gap: 2 }}>
                      {config.price !== config.total_price && (
                        <Text muted subtitle strike>
                          ${config.price / 100}
                        </Text>
                      )}
                      <Text subtitle>${config.total_price / 100}</Text>
                    </Div>
                  </GridUnit>
                </Grid>
              </StyleRadioRow>
            </StyledCardSection>
          );
        })}
        <StyledCardSection>
          <StyleRadioRow
            as="label"
            inline={true}
            type={'plain'}
            highlight={true}
            disabled={disabled}
            m={0}
            p={3}
            checked={field.value === 'custom'}>
            <Grid>
              <GridUnit size={1.25 / 3}>
                <Div flex={{ align: 'center' }}>
                  <input
                    {...field}
                    onChange={() => field.onChange({ target: { value: 'custom', name } })}
                    checked={field.value === 'custom'}
                    disabled={disabled}
                    type="radio"
                  />
                  <Badge muted>Custom</Badge>
                </Div>
              </GridUnit>
              <GridUnit size={1 / 3}>
                <Text subtitle>
                  {'>'} {skus_array[skus_array.length - 1][1].allocation} events per second
                </Text>
              </GridUnit>
              <GridUnit size={0.75 / 3}>
                <Div flex={{ justify: 'flex-end', gap: 2 }}>
                  <Text subtitle>Custom pricing</Text>
                </Div>
              </GridUnit>
            </Grid>
          </StyleRadioRow>
        </StyledCardSection>
      </div>
    </>
  );
};

const ChangeThrouhgputModal: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { team, team_role, subscription, mutateTeam } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const { updateSearchQuery } = useSearchQuery<{ view: 'throughput' }>();

  const { data: skus } = useSWR(APIMethodKeys.billing.getThrouhgputSkus(), () =>
    HookdeckAPI.billing.getThroughputSkus(),
  );

  const onClose = () => {
    updateSearchQuery({ view: undefined });
  };

  const onSubmit = async (values) => {
    if (values.sku === 'custom') {
      showChat();
      onClose();
      return;
    }
    if (values.sku === team!.billing_meta.sku) {
      return;
    }
    return HookdeckAPI.billing
      .updateThroughput(values.sku)
      .then((team) => {
        mutateTeam(team);
        addToast('success', 'Project throughput successfully updated.');
        onClose();
      })
      .catch(() => {
        addToast('error', 'Failed to update project throughput.');
      });
  };

  return (
    <Formik initialValues={{ sku: team!.billing_meta.sku }} onSubmit={onSubmit}>
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <TeamPermission role="admin">
            <Modal
              size="large"
              title="Change project throughput"
              submit_label={props.values.sku === 'custom' ? 'Contact us' : 'Update throughput'}
              cancel_label="Cancel"
              submit_disabled={team_role === 'admin' && props.values.sku === team!.billing_meta.sku}
              onSubmit={props.handleSubmit}
              onCancel={onClose}
              onClose={onClose}
              is_submitting={props.isSubmitting}>
              <Text as="p">
                The throughput rate determines the events per second that Hookdeck will process and
                deliver for this project. Upon changing throughput, you will be charged the
                corresponding fee. You can change your throughput at any time. Reducing your
                throughput will grant a pro-rated credit.
              </Text>
              {team!.billing_meta.included !== null &&
                team!.billing_meta.included > 0 &&
                subscription!.max_attempts_per_second !== null &&
                subscription!.max_attempts_per_second > 0 && (
                  <Div flex m={{ b: 4 }}>
                    <Icon icon="info" left muted m={{ t: 0.5 }} />
                    <Text muted>
                      This project throughput is discounted for{' '}
                      {subscription!.max_attempts_per_second} events/s because of a grandfathered
                      plan.
                    </Text>
                  </Div>
                )}
              <StyledCard overflow_hidden>
                <StyledCardSection muted p={{ y: 1, x: 3 }}>
                  <Grid>
                    <GridUnit size={1.25 / 3}>
                      <Text subtitle muted size="s" m={{ l: 7 }}>
                        Size
                      </Text>
                    </GridUnit>
                    <GridUnit size={1 / 3}>
                      <Text subtitle muted size="s">
                        Throughput rate
                      </Text>
                    </GridUnit>
                    <GridUnit size={0.75 / 3}>
                      <Div flex={{ justify: 'flex-end' }}>
                        <Text subtitle muted size="s">
                          Price per month
                        </Text>
                      </Div>
                    </GridUnit>
                  </Grid>
                </StyledCardSection>
                {skus && (
                  <RadioTableInput current_value={team!.billing_meta.sku} skus={skus} name="sku" />
                )}
              </StyledCard>
            </Modal>
          </TeamPermission>
        </form>
      )}
    </Formik>
  );
};

export default ChangeThrouhgputModal;
