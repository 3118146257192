import React, { useContext, useState } from 'react';
import useSWR from 'swr';

import { TransformationExecution } from '../../../../../../../typings/TransformationExecution.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import {
  TransformationExecutionsFiltersProps,
  TransformationExecutionsQueryProps,
} from '../../../../typings/TransformationExecution.interface';
import { extractFromArray, toArray } from '../../../../utils';
import Button from '../../../common/base/Button';
import { StyledCardSection } from '../../../common/base/Card';
import Text from '../../../common/base/Text';
import { transformation_execution_list_filters } from '../../../common/Filters/FilterComponents';
import Filters from '../../../common/Filters/Filters';
import { Div } from '../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { PageNav, StyledViewContainerCard, StyledViewContent, StyledViewNav } from '../StyledView';
import TransformationLogDetails from './TransformationExecutionDetails';
import TransformationExecutionsList from './TransformationExecutionsList';
import { relative_dates } from '../../../../utils/date';
import Icon from '../../../common/base/Icon';
import Divider from '../../../common/base/Divider';

const extractFiltersFromQuery = (
  parsed_query: TransformationExecutionsQueryProps,
): TransformationExecutionsFiltersProps => {
  let date: {};
  if (typeof parsed_query.date === 'string') {
    const { min, max } = relative_dates[parsed_query.date as string].convert(new Date());
    date = {
      relative: parsed_query.date,
      min: min?.toISOString(),
      max: max?.toISOString(),
    };
  } else {
    date = {
      max: extractFromArray(parsed_query.date?.max) as string,
      min: extractFromArray(parsed_query.date?.min) as string,
    };
  }
  return {
    date,
    webhook_id: toArray(parsed_query.webhook_id || []) as string[],
    log_level: toArray(parsed_query.log_level || []) as string[],
    next: extractFromArray(parsed_query.next) as string,
    prev: extractFromArray(parsed_query.prev) as string,
    issue_id: extractFromArray(parsed_query.issue_id) as string,
  };
};

const TransformationExecutionsContent: React.FC<{
  transformation_id: string;
  onEdit: (execution?: TransformationExecution) => void;
}> = ({ transformation_id, onEdit }) => {
  const { query, updateSearchQuery } = useSearchQuery<TransformationExecutionsQueryProps>();
  const selected_execution_id = extractFromArray(query.selected_execution_id) as string;
  const [render_key, setRenderKey] = useState(Date.now());
  const filters = extractFiltersFromQuery(query);

  const onExecutionSelected = (selected_execution_id: string) =>
    updateSearchQuery({ selected_execution_id });
  const onFilterChanged = (new_filters: object) => {
    const should_update = Object.entries(new_filters).some(
      ([key, value]) => JSON.stringify(filters[key]) !== JSON.stringify(value),
    );
    if (should_update) {
      updateSearchQuery(new_filters, {
        remove_keys: ['selected_execution_id', 'next', 'prev'],
      });
    }
  };
  const onPaginationChanged = (pagination: { prev?: string; next?: string }) => {
    updateSearchQuery(
      { prev: undefined, next: undefined, ...pagination },
      {
        remove_keys: ['selected_execution_id'],
      },
    );
  };
  return (
    <>
      <StyledViewContent light>
        <Div>
          <Filters
            route="transformation-executions"
            filters={filters}
            components={transformation_execution_list_filters}
            onFilterChanged={onFilterChanged}
          />
        </Div>
        <StyledViewContainerCard border_top m={{ t: 4 }} flex={{ grow: true, direction: 'column' }}>
          <StyledCardSection
            p={{ x: 4, y: 2 }}
            flex={{ justify: 'space-between', align: 'center' }}>
            <Text subtitle>Results</Text>
            <Button
              icon="refresh"
              minimal
              onClick={() => {
                updateSearchQuery(
                  {},
                  { remove_keys: ['prev', 'next', 'selected_event_id', 'selected_attempt_id'] },
                );
                setRenderKey(Date.now());
              }}>
              Refresh
            </Button>
          </StyledCardSection>
          <TransformationExecutionsList
            fill
            key={render_key}
            transformation_id={transformation_id}
            selected_execution_id={undefined}
            filters={filters}
            onPaginationChanged={onPaginationChanged}
            onExecutionSelected={onExecutionSelected}
            onEdit={onEdit}
          />
        </StyledViewContainerCard>
      </StyledViewContent>
      {selected_execution_id && (
        <TransformationLogDetails
          transformation_id={transformation_id}
          execution_id={selected_execution_id}
          onClose={() => updateSearchQuery({}, { remove_keys: ['selected_execution_id'] })}
        />
      )}
    </>
  );
};

const TransformationExecutionsView: React.FC<{
  transformation_id: string;
  onEdit: (execution?: TransformationExecution) => void;
  onClose: () => void;
  in_modal?: boolean;
}> = ({ transformation_id, onClose, onEdit, in_modal }) => {
  const { HookdeckAPI } = useContext(GlobalContext);

  const { data: transformation } = useSWR(
    APIMethodKeys.transformations.get(transformation_id),
    () => HookdeckAPI.transformations.get(transformation_id),
  );

  if (in_modal) {
    return (
      <Div style={{ maxHeight: '100vh', overflow: 'scroll', position: 'relative' }}>
        <StyledViewNav background_color="background">
          <Div flex={{ align: 'center', gap: 4 }}>
            <Button onClick={onClose} outline icon="close" />
            <Div flex={{ align: 'center' }}>
              <Icon icon="transformation" left />
              <Text heading>Executions of "{transformation?.name}"</Text>
            </Div>
          </Div>
        </StyledViewNav>
        <TransformationExecutionsContent transformation_id={transformation_id} onEdit={onEdit} />
      </Div>
    );
  }

  return (
    <>
      <PageNav
        breadcrumb={[
          {
            icon: 'connections',
            title: 'Connections',
            path: `/connections`,
          },
          {
            icon: 'transformation',
            title: transformation?.name || null,
            monospace: true,
            path: `/transformations/${transformation_id}`,
          },
          {
            icon: 'transformation',
            title: 'Executions',
          },
        ]}
      />
      <Divider />
      <TransformationExecutionsContent transformation_id={transformation_id} onEdit={onEdit} />
    </>
  );
};

export default TransformationExecutionsView;
