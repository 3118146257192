import { Grid, GridUnit } from '@hookdeck/theme';

import Badge from '../../../../../common/base/Badge';
import { StyledCard, StyledCardSection } from '../../../../../common/base/Card';
import Divider from '../../../../../common/base/Divider';
import Icon from '../../../../../common/base/Icon';
import Text from '../../../../../common/base/Text';
import { Div } from '../../../../../common/helpers/StyledUtils';
import React, { useContext } from 'react';
import { DashboardContext } from '../../../DashboardContext';
import APIMethodKeys from '../../../../../../client/APIMethodKeys';
import useSWR from 'swr';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { OrbSubscription } from '../../../../../../typings/Orb.interface';
import THROUGHPUT_SKUS from '../../../../../../../../../domain/throughput_skus';

const ProjectThroughputs: React.FC<{ subscription_details: OrbSubscription }> = ({
  subscription_details,
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { organization } = useContext(DashboardContext);

  const { data: teams } = useSWR(APIMethodKeys.teams.listAll(), () => HookdeckAPI.teams.listAll());

  const teams_for_current_organization = teams?.filter(
    (team) => team.organization_id === organization!.id,
  );

  const price_per_team = teams_for_current_organization?.reduce(
    (obj, team) => {
      const interval = subscription_details.price_intervals?.find(
        (pi) => team.billing_meta.price_interval_id === pi.id,
      );
      obj[team.id] = interval?.price.unit_config?.unit_amount || '0';
      return obj;
    },
    {} as Record<string, string>,
  );

  return (
    <>
      <Text heading as="h2" m={0}>
        Projects Throughput
      </Text>
      <Text muted m={{ b: 2 }}>
        Throughput is managed on a per-project basis, and can be modified under a project’s Quotas
        tab.
      </Text>
      <Divider m={{ b: 4 }} />
      <StyledCard>
        <StyledCardSection muted p={{ x: 2, y: 1 }}>
          <Grid>
            <GridUnit size={1.5 / 4}>
              <Text muted subtitle size="s">
                Project
              </Text>
            </GridUnit>
            <GridUnit size={1 / 4}>
              <Text muted subtitle size="s">
                Size
              </Text>
            </GridUnit>
            <GridUnit size={1 / 4}>
              <Text muted subtitle size="s">
                Rate
              </Text>
            </GridUnit>
            <GridUnit size={0.5 / 4}>
              <Div flex={{ justify: 'flex-end' }}>
                <Text muted subtitle size="s">
                  Price
                </Text>
              </Div>
            </GridUnit>
          </Grid>
        </StyledCardSection>
        {price_per_team &&
          teams_for_current_organization?.map((team) => (
            <StyledCardSection key={team.id} p={{ x: 2, y: 3 }}>
              <Grid>
                <GridUnit size={1.5 / 4}>
                  <Div flex={{ align: 'center' }}>
                    <Icon muted icon="folder" left />
                    <Text subtitle ellipsis m={{ r: 2 }}>
                      {team.name}
                    </Text>
                  </Div>
                </GridUnit>
                <GridUnit size={1 / 4}>
                  <Badge muted>
                    {THROUGHPUT_SKUS.available[team.billing_meta.sku]?.label ||
                      THROUGHPUT_SKUS.legacy[team.billing_meta.sku]?.label}
                  </Badge>
                </GridUnit>
                <GridUnit size={1 / 4}>
                  <Text>{team.max_events_per_second} events per second</Text>
                </GridUnit>
                <GridUnit size={0.5 / 4}>
                  <Div flex={{ justify: 'flex-end' }}>
                    <Text>${price_per_team[team.id]}</Text>
                  </Div>
                </GridUnit>
              </Grid>
            </StyledCardSection>
          ))}
        {price_per_team && (
          <StyledCardSection flex={{ justify: 'space-between' }} p={{ x: 2, y: 3 }}>
            <Text heading>Total</Text>
            <Text heading>
              $
              {Object.values(price_per_team)
                .reduce((total: number, v: string) => (total + Number(v)) as any, 0)
                .toFixed(2)}
            </Text>
          </StyledCardSection>
        )}
      </StyledCard>
    </>
  );
};

export default ProjectThroughputs;
