import { useContext, useState } from 'react';

import { Event } from '../../../../../../../typings/Event.interface';
import { getErrorCodeLabels } from '../../../../utils/error-codes';
import Badge from '../../../common/base/Badge';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import LabelButton from '../../../common/base/LabelButton';
import Link from '../../../common/base/Link';
import Skeleton from '../../../common/base/Skeleton';
import Text from '../../../common/base/Text';
import DisplayDate from '../../../common/DisplayDate';
import { Div } from '../../../common/helpers/StyledUtils';
import { Status } from '../../../common/Status';
import { DashboardContext } from '../DashboardContext';
import useSWR from 'swr';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { GlobalContext } from '../../../contexts/GlobalContext';

type Props = {
  event?: Event;
};

const EventMetadata: React.FC<Props> = ({ event }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription } = useContext(DashboardContext);
  const [expanded, setExpanded] = useState(false);

  const { data: connection } = useSWR(
    event && expanded && APIMethodKeys.webhooks.get(event?.webhook_id),
    () => HookdeckAPI.webhooks.get(event!.webhook_id),
  );

  if (!event) {
    return (
      <>
        <StyledCard>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Skeleton h={{ px: 20 }} w={{ px: 40 }} loading m={{ b: 1 }} />
            <Skeleton h={{ px: 20 }} w={{ px: 72 }} loading />
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Skeleton h={{ px: 20 }} w={{ px: 128 }} loading />
          </StyledCardSection>
        </StyledCard>
      </>
    );
  }

  const expanded_content = (
    <>
      {expanded && (
        <>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Text size="s" subtitle m={{ b: 1 }}>
              Connection
            </Text>
            {connection ? (
              <LabelButton
                label={connection?.full_name}
                monospace
                small
                to={`/connections/${event.webhook_id}`}
                neutral
              />
            ) : (
              <Skeleton h={{ px: 20 }} w={{ px: 100 }} />
            )}
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Text size="s" subtitle m={{ b: 1 }}>
              Request
            </Text>
            <LabelButton
              label={event.request_id}
              monospace
              small
              to={`/requests/${event.request_id}`}
              neutral
            />
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Text size="s" subtitle m={{ b: 1 }}>
              Attempts Count
            </Text>
            <Div flex={{ justify: 'space-between' }}>
              <Badge small muted>
                {event.attempts}
              </Badge>
            </Div>
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Text size="s" subtitle m={{ b: 1 }}>
              Created At
            </Text>
            <DisplayDate date={event.created_at} />
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Text size="s" subtitle m={{ b: 1 }}>
              Last Attempt At
            </Text>
            <DisplayDate date={event.last_attempt_at} />
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Text size="s" subtitle m={{ b: 1 }}>
              Successful At
            </Text>
            <DisplayDate date={event.successful_at} />
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Text size="s" subtitle m={{ b: 1 }}>
              Next Attempt At
            </Text>
            <DisplayDate date={event.next_attempt_at} />
          </StyledCardSection>
        </>
      )}
      <StyledCardSection p={{ x: 4, y: 3 }}>
        <Link
          as="button"
          small
          icon={expanded ? 'collapse_all' : 'expand_all'}
          flex={{ align: 'center' }}
          onClick={() => setExpanded(!expanded)}>
          {!expanded ? `View Metadata` : 'Hide Metadata'}
        </Link>
      </StyledCardSection>
    </>
  );

  if (event.status === 'HOLD' || event.status === 'QUEUED') {
    return (
      <StyledCard>
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Text size="s" subtitle m={{ b: 1 }}>
            Status
          </Text>
          <Status {...event} />
        </StyledCardSection>
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Text size="s" subtitle m={{ b: 1 }}>
            Description
          </Text>
          <Text>
            {event.status
              ? 'This event is queued for delivery or is currently being delivered'
              : 'The event is on hold while the connection is paused.'}
          </Text>
        </StyledCardSection>
        {expanded_content}
      </StyledCard>
    );
  }

  if (event.error_code) {
    const label = getErrorCodeLabels(subscription)[event.error_code];
    return (
      <StyledCard>
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Text size="s" subtitle m={{ b: 1 }}>
            Status
          </Text>
          <Status {...event} />
        </StyledCardSection>
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Text size="s" subtitle m={{ b: 1 }}>
            Error
          </Text>
          <Badge danger subtle m={{ b: 2 }}>
            {event.error_code}
          </Badge>
          <Text>{label}</Text>
        </StyledCardSection>
        {expanded_content}
      </StyledCard>
    );
  }

  return (
    <>
      <StyledCard>
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Text size="s" subtitle m={{ b: 1 }}>
            Status
          </Text>
          <Status {...event} />
        </StyledCardSection>
        {expanded_content}
      </StyledCard>
    </>
  );
};

export default EventMetadata;
