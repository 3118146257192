import { Form, Formik } from 'formik';
import { useContext } from 'react';

import Button from '../../../../common/base/Button';
import Divider from '../../../../common/base/Divider';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { UserContext } from '../../../../contexts/UserContext';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import AccountAuthentication from './AccountAuthentication';
import Link from '../../../../common/base/Link';
import { showChat } from '../../../../../utils/liveChat';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import { StyledCard } from '../../../../common/base/Card';

const AccountProfile: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user, mutateUser } = useContext(UserContext);
  const { addToast } = useToasts();

  const handleSubmit = (promise) => {
    return promise
      .then((user) => {
        mutateUser && mutateUser(user);
        addToast('success', 'Profile updated');
      })
      .catch(() => {
        addToast('error', 'Failed to update profile');
      });
  };

  return (
    <>
      <Div m={{ b: 14 }}>
        <Formik
          initialValues={{
            name: user!.name,
            email: user!.email,
          }}
          validate={(values: { name?: string; email?: string }) => {
            const errors: { name?: string; email?: string } = {};
            if (!values.name || values.name.length === 0) {
              errors.name = 'Required';
            }
            return errors;
          }}
          onSubmit={(values) =>
            handleSubmit(
              HookdeckAPI.session.updateMe({
                name: values.name,
              }),
            )
          }>
          {(props) => (
            <Form>
              <Text heading as="h2" m={{ b: 2 }}>
                Personal Details
              </Text>
              <Divider m={{ b: 4 }} />
              <TextInput label="Name" name="name" required />
              <TextInput
                label="Email"
                disabled
                name="email"
                type="email"
                help="Contact us to change your email"
              />
              <Button
                outline
                disabled={!props.isValid || !props.touched || props.isSubmitting}
                primary
                submit
                icon={props.isSubmitting ? 'loading' : 'save'}>
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </Div>
      <Div m={{ b: 14 }}>
        <Formik
          initialValues={{
            enable_alerts: !user!.disable_alerts,
          }}
          onSubmit={(values) => {
            return handleSubmit(
              HookdeckAPI.session.updateMe({
                disable_alerts: !values.enable_alerts,
              }),
            );
          }}>
          {(props) => (
            <Form>
              <Text heading as="h2" m={{ b: 2 }}>
                Notification Settings
              </Text>
              <Divider m={{ b: 4 }} />
              <StyledCard p={4} flex={{ justify: 'space-between' }} m={{ b: 4 }}>
                <div>
                  <Text>Email Notifications</Text>
                  <Text muted>Enable to receive platform updates via email.</Text>
                </div>
                <SwitchInput show_label name="enable_alerts" />
              </StyledCard>
              <Button
                minimal
                outline
                disabled={!props.isValid || !props.touched || props.isSubmitting}
                primary
                submit
                icon={props.isSubmitting ? 'loading' : 'save'}>
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </Div>
      <AccountAuthentication />
      <Div m={{ b: 14 }}>
        <Text heading as="h2" m={{ b: 2 }}>
          Delete Account
        </Text>
        <Divider m={{ b: 4 }} />
        <Div flex={{ justify: 'space-between' }}>
          <Text>Delete your account and all associated account data.</Text>
          <Link onClick={showChat} as={'button'}>
            Contact us {'->'}
          </Link>
        </Div>
      </Div>
    </>
  );
};

export default AccountProfile;
