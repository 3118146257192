import { Destination } from '../../../../../../../../typings/Destination.interface';
import { Source } from '../../../../../../../../typings/Source.interface';
import { Webhook } from '../../../../../../../../typings/Webhook.interface';
import { fieldName } from '../../../../../utils';
import field_formats from '../../../../../utils/field-formatters';
import TextAreaInput from '../../../../common/Form/Fields/TextAreaInput';

export interface FormValues {
  name?: string;
  description?: string | null;
}

const resource_details_form_props = {
  postprocessValues: (values: FormValues) => {
    return {
      name: values.name || null,
      description: values.description || null,
    };
  },
  getInitialValues: (model?: Source | Destination | Webhook) =>
    model
      ? {
          name: model.name || '',
          description: model.description || '',
        }
      : {},
  validate: async (
    values: FormValues,
    isNamedUsed: (name: string) => Promise<boolean>,
    name_required = true,
  ) => {
    const errors: FormValues = {};
    if (name_required && (!values.name || values.name.length === 0)) {
      errors.name = 'Required';
    } else if (values.name) {
      const used = await isNamedUsed(values.name);
      if (used) {
        errors.name = 'Name is already used.';
      }
    }
    if (values.description && values.description.length > 500) {
      errors.description = 'Description must be less than 500 characters';
    }
    return errors;
  },
  Fields: ({
    prefix,
    help,
    placeholder,
    name_required = true,
    show_description = false,
    hide_label = false,
  }: {
    prefix: string;
    placeholder: string;
    help?: string;
    name_required?: boolean;
    show_description?: boolean;
    hide_label?: boolean;
  }) => {
    return (
      <>
        <TextAreaInput
          m={0}
          maxlength={155}
          name={fieldName('name', prefix)}
          label={hide_label ? undefined : 'Name'}
          monospace
          required={name_required}
          auto_resize
          format={field_formats.slugify}
          placeholder={placeholder}
          help={help}
        />
        {show_description && (
          <TextAreaInput
            m={{ t: 4 }}
            name={fieldName('description', prefix)}
            maxlength={500}
            label="Description"
          />
        )}
      </>
    );
  },
};

export default resource_details_form_props;
