import { DestinationAuthMethodType } from '../../../../typings/Destination.interface';

type Fields = {
  label: string;
  name: string;
  type: 'text' | 'select' | 'password' | 'number';
  placeholder?: string;
  required?: boolean;
  options?: { value: string; label: string }[];
}[];

interface Schema {
  label: string;
  fields: Fields;
}

const destination_auth_method_schemas: {
  [key in DestinationAuthMethodType]: Schema;
} = {
  HOOKDECK_SIGNATURE: {
    label: 'Hookdeck Signature (Default)',
    fields: [],
  },
  CUSTOM_SIGNATURE: {
    label: 'Custom SHA-256 HMAC Signature',
    fields: [
      {
        label: 'Header Key',
        name: 'key',
        type: 'text',
        placeholder: 'X-Your-Signature',
        required: true,
      },
      {
        label: 'Signing Secret',
        name: 'signing_secret',
        type: 'password',
        placeholder: 'Secret key...',
      },
    ],
  },
  BASIC_AUTH: {
    label: 'Basic Auth',
    fields: [
      {
        label: 'Username',
        name: 'username',
        type: 'text',
        required: false,
      },
      {
        label: 'Password',
        name: 'password',
        type: 'password',
        required: false,
      },
    ],
  },
  API_KEY: {
    label: 'API Key',
    fields: [
      {
        label: 'Parameter Name',
        name: 'key',
        type: 'text',
        placeholder: 'X-Some-Api-Key',
        required: true,
      },
      {
        label: 'API Key',
        name: 'api_key',
        type: 'password',
        placeholder: 'Secret key...',
        required: true,
      },
      {
        label: 'Parameter Type',
        name: 'to',
        type: 'select',
        options: [
          { value: 'header', label: 'Header' },
          { value: 'query', label: 'Query' },
        ],
        required: true,
      },
    ],
  },
  BEARER_TOKEN: {
    label: 'Bearer Token',
    fields: [
      {
        label: 'Bearer Token',
        name: 'token',
        type: 'password',
        placeholder: 'Secret key...',
        required: true,
      },
    ],
  },
  OAUTH2_CLIENT_CREDENTIALS: {
    label: 'OAuth2 (Client Credentials)',
    fields: [
      {
        label: 'Authorization Server',
        name: 'auth_server',
        type: 'text',
        placeholder: 'Server URL',
        required: true,
      },
      {
        label: 'Client ID',
        name: 'client_id',
        type: 'text',
        placeholder: 'Client ID...',
        required: true,
      },
      {
        label: 'Client Secret',
        name: 'client_secret',
        type: 'password',
        placeholder: 'Client Secret...',
        required: true,
      },
      {
        label: 'Scope',
        name: 'scope',
        type: 'text',
        placeholder: 'Comma separated scopes...',
      },
    ],
  },
  OAUTH2_AUTHORIZATION_CODE: {
    label: 'OAuth2 (Authorization Code)',
    fields: [
      {
        label: 'Authorization Server',
        name: 'auth_server',
        type: 'text',
        placeholder: 'Server URL',
        required: true,
      },
      {
        label: 'Client ID',
        name: 'client_id',
        type: 'text',
        placeholder: 'Client ID...',
        required: true,
      },
      {
        label: 'Client Secret',
        name: 'client_secret',
        type: 'password',
        placeholder: 'Client Secret...',
        required: true,
      },
      {
        label: 'Refresh Token',
        name: 'refresh_token',
        type: 'password',
        placeholder: 'Refresh Token...',
        required: true,
      },
      {
        label: 'Scope',
        name: 'scope',
        type: 'text',
        placeholder: 'Comma separated scopes...',
      },
    ],
  },
  AWS_SIGNATURE: {
    label: 'AWS Signature',
    fields: [
      {
        label: 'Access Key Id',
        name: 'access_key_id',
        type: 'password',
        required: true,
        placeholder: 'AWS access key id...',
      },
      {
        label: 'Secret Access Key',
        name: 'secret_access_key',
        type: 'password',
        required: true,
        placeholder: 'AWS secret access key...',
      },
    ],
  },
};

export default destination_auth_method_schemas;
