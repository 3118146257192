import {
  BackpressureIssue,
  DeliveryIssue,
  Issue,
  IssueStatus,
  IssueType,
  TransformationIssue,
} from '../../../../typings/Issue.interface';
import { IconName } from '../components/common/base/Icon';
import { IssuesRenderData } from '../components/scenes/DashboardScene/Issues/IssueList';
import { getMsIntervalByUnit } from './rules';
import { TeamRole } from '../../../../typings/TeamMember.interface';
import { capitalizeFirstLetter, pluralize } from '.';

export type IssueTitlteParts = (string | { label: string; to?: string })[];

export const issue_type_configs: Record<
  IssueType,
  {
    icon: IconName;
    label: string;
    description?: string;
    getIssueTitleParts: (issue: Issue, render_data: IssuesRenderData) => IssueTitlteParts;
    getIssueDescrition: (issue: Issue, render_data: IssuesRenderData) => string;
  }
> = {
  delivery: {
    icon: 'send',
    label: 'Delivery',
    description:
      'Delivery issues represent errors returned by your destination when events are delivered',
    getIssueTitleParts: (
      issue: DeliveryIssue,
      render_data: Pick<IssuesRenderData, 'webhooks_by_id'>,
    ) => {
      return [
        ...issue.aggregation_keys.response_status.map((status) => ({
          label: `HTTP ${status}`,
        })),
        ...issue.aggregation_keys.error_code.map((code) => ({
          label: `CODE ${code}`,
        })),
        'on connection',
        ...issue.aggregation_keys.webhook_id.map((id) => ({
          label: render_data.webhooks_by_id?.[id]?.full_name || '',
          to: `/connections/${id}`,
        })),
      ];
    },
    getIssueDescrition: (issue: DeliveryIssue) => {
      const response_status_string = issue.aggregation_keys.response_status.join(', ');
      const error_code_string = issue.aggregation_keys.error_code.join(', ');
      let description = 'You are receiving an ';
      if (response_status_string) {
        description += `HTTP ${response_status_string} response`;
      }
      if (response_status_string && error_code_string) {
        description += ' and ';
      }
      if (error_code_string) {
        description += `CODE ${error_code_string}`;
      }
      description += ` on your connection${
        issue.aggregation_keys.webhook_id.length > 1 ? 's' : ''
      }.`;
      return description;
    },
  },
  transformation: {
    icon: 'transformation',
    label: 'Transformation',
    description: 'Transformation issues represent errors returned by a transformation execution',
    getIssueTitleParts: (
      issue: TransformationIssue,
      render_data: Pick<IssuesRenderData, 'transformations_by_id'>,
    ) => {
      return [
        ...issue.aggregation_keys.log_level.map((level) => ({
          label: capitalizeFirstLetter(level),
        })),
        'on transformation',
        ...issue.aggregation_keys.transformation_id.map((id) => ({
          label: render_data.transformations_by_id?.[id]?.name || '',
          to: `/transformations/${id}`,
        })),
      ];
    },
    getIssueDescrition: (
      issue: TransformationIssue,
      render_data: Pick<IssuesRenderData, 'transformations_by_id'>,
    ) => {
      const transformation =
        render_data.transformations_by_id?.[issue.aggregation_keys.transformation_id[0]];
      const fatal = issue.aggregation_keys.log_level.includes('fatal');
      const other_levels = issue.aggregation_keys.log_level.filter((l) => l !== 'fatal');
      let description = `Your transformation "${transformation?.name}" `;

      if (fatal) {
        if (other_levels.length > 0) {
          description =
            description +
            `failed to execute and logged ${other_levels.join(' and ')} while executing.`;
        } else {
          description = description + `failed to execute.`;
        }
      } else {
        description = description + `logged ${other_levels.join(' and ')} while executing.`;
      }
      description +=
        ' Update your transformation code and retry the impacted ignored events to fix this issue.';
      return description;
    },
  },
  backpressure: {
    icon: 'rate_limit',
    label: 'Backpressure',
    description: 'Backpressure issues represent accumulated delay on a destination queue.',
    getIssueTitleParts: (
      issue: BackpressureIssue,
      render_data: Pick<IssuesRenderData, 'destinations_by_id'>,
    ) => {
      return [
        ...issue.aggregation_keys.delay.map((delay) => {
          const [value, unit] = getMsIntervalByUnit(delay);
          return {
            label: `${value} ${pluralize(value, unit)} delay`,
          };
        }),
        'on destination',
        ...issue.aggregation_keys.destination_id.map((id) => ({
          label: render_data.destinations_by_id?.[id]?.name || '',
          to: `/destinations/${id}`,
        })),
      ];
    },
    getIssueDescrition: (
      issue: BackpressureIssue,
      render_data: Pick<IssuesRenderData, 'destinations_by_id'>,
    ) => {
      const delay = issue.aggregation_keys.delay[0];
      const destination =
        render_data.destinations_by_id?.[issue.aggregation_keys.destination_id[0]];
      const [value, unit] = getMsIntervalByUnit(delay);
      const description = `Your destination "${destination?.name}" has a backlog of attempts that will take more than ${value} ${unit} to clear.`;
      return description;
    },
  },
};

export const getIssueDescrition = (issue: Issue, render_data: IssuesRenderData) => {
  return issue_type_configs[issue.type].getIssueDescrition(issue, render_data);
};

export const getIssueTitleParts = (issue: Issue, render_data: IssuesRenderData) => {
  return issue_type_configs[issue.type].getIssueTitleParts(issue, render_data);
};

export const ISSUE_STATUS_CONFIGS: Record<
  IssueStatus,
  {
    label: string;
    description: string;
    icon: IconName;
    badge_theme: 'primary' | 'muted' | 'danger' | 'warning' | 'success';
    default_action: keyof typeof issue_actions;
    current_action: keyof typeof issue_actions;
  }
> = {
  OPENED: {
    label: 'Open',
    description: 'Issues that are newly created and not yet acknowledged.',
    icon: 'error',
    badge_theme: 'danger',
    default_action: 'acknowledge',
    current_action: 'reopen',
  },
  ACKNOWLEDGED: {
    label: 'Acknowledged',
    description: 'Issues that are ongoing and acknowledged by a team member.',
    icon: 'awknowledged',
    badge_theme: 'warning',
    default_action: 'resolve',
    current_action: 'acknowledge',
  },
  RESOLVED: {
    label: 'Resolved',
    description:
      'Issues that have been marked as resolved. Will be re-open if the issue re-occurs.',
    icon: 'done_all',
    badge_theme: 'success',
    default_action: 'dismiss',
    current_action: 'resolve',
  },
  IGNORED: {
    label: 'Ignored',
    description: 'Issues that have been ignored. Will not be re-open if the issue re-occurs.',
    icon: 'notification_off',
    badge_theme: 'muted',
    default_action: 'reopen',
    current_action: 'ignore',
  },
} as const;

export const issue_actions = {
  acknowledge: {
    label: 'Acknowledge',
    danger: false,
    role: 'member' as TeamRole,
    icon: ISSUE_STATUS_CONFIGS['ACKNOWLEDGED'].icon,
  },
  resolve: {
    label: 'Resolve',
    danger: false,
    role: 'member' as TeamRole,
    icon: ISSUE_STATUS_CONFIGS['RESOLVED'].icon,
  },
  ignore: {
    label: 'Ignore',
    danger: false,
    role: 'member' as TeamRole,
    icon: ISSUE_STATUS_CONFIGS['IGNORED'].icon,
  },
  reopen: {
    label: 'Re-open',
    danger: false,
    role: 'member' as TeamRole,
    icon: ISSUE_STATUS_CONFIGS['OPENED'].icon,
  },
  dismiss: {
    label: 'Dismiss',
    danger: true,
    role: 'member' as TeamRole,
    icon: 'block' as IconName,
  },
};
