import { isBefore, subDays } from 'date-fns';
import { useContext, useMemo } from 'react';
import useSWR from 'swr';

import { APIList, OrderByDirection } from '../../../../../../../../typings/API.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { EventListFiltersProps, OrderBy } from '../../../../../typings/EventList.interface';
import Alert from '../../../../common/base/Alert';
import Icon from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Table from '../../../../common/Table';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { EventListResults } from '../../../../hooks/useEventList';
import { DashboardContext } from '../../DashboardContext';
import EventListEmptyView from '../EventListEmptyView';
import event_list_layouts from './EventListLayouts';
import { Div } from '../../../../common/helpers/StyledUtils';
import { getCurrentTimezoneAbreviation } from '../../../../../utils/date';
import { EventAttempt } from '../../../../../../../../typings/EventAttempt.interface';
import { Event } from '../../../../../../../../typings/Event.interface';

interface EventsListProps extends EventListResults {
  layout: 'http' | 'cli';
  active_filters?: object;
  selected_event_id?: string;
  filters: EventListFiltersProps;
  order_by: OrderBy;
  dir: OrderByDirection;
  selected_attempt_id: string;
  total_count?: number;
  onEventSelected(id: string | null): void;
  onAttemptSelected(id: string): void;
  onPaginationChanged(filters: object): void;
  onOrderUpdated(order_by: string, dir: string): void;
  event_actions: { [k in 'retry' | 'mute' | 'bookmark']: (event: Event) => void };
  event_attempts?: APIList<EventAttempt>;
}

const EventsList: React.FC<EventsListProps> = ({
  selected_event_id,
  onEventSelected,
  selected_attempt_id,
  onAttemptSelected,
  order_by,
  dir,
  layout,
  onPaginationChanged,
  onOrderUpdated,
  filters,
  events,
  outdated_ids,
  count,
  fetched,
  has_next_results,
  refresh,
  prev,
  next,
  total_count,
  event_actions,
  event_attempts,
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { active_cli_sessions, view, team, subscription, organization } =
    useContext(DashboardContext);

  const unique_webhook_ids = useMemo(
    () => (events ? Array.from(new Set(events.map((event) => event.webhook_id))).sort() : []),
    [events],
  );

  const unique_client_ids = useMemo(
    () => (events ? Array.from(new Set(events.map((event) => event.cli_id))).sort() : []),
    [events],
  );

  const { data: webhooks } = useSWR(
    APIMethodKeys.webhooks.list(
      unique_webhook_ids.length > 0 ? { id: unique_webhook_ids } : { limit: 1 },
    ),
    () =>
      HookdeckAPI.webhooks.list(
        unique_webhook_ids.length > 0 ? { id: unique_webhook_ids } : { limit: 1 },
      ),
  );

  const { data: cli_clients } = useSWR(
    unique_client_ids.length > 0 && APIMethodKeys.cli_clients.list(),
    () => HookdeckAPI.cli_clients.list(),
  );

  const webhooks_by_id =
    webhooks &&
    webhooks.models.reduce((object, webhook) => ({ ...object, [webhook.id]: webhook }), {});

  const cli_clients_by_id =
    cli_clients &&
    cli_clients.models.reduce((object, webhook) => ({ ...object, [webhook.id]: webhook }), {});

  const loading =
    events === undefined ||
    !fetched ||
    (unique_webhook_ids.length > 0 && webhooks === undefined) ||
    (unique_client_ids.length > 0 && cli_clients === undefined);

  const has_request_filter = filters.request && Object.values(filters.request).some((v) => !!v);

  let alert_top;
  if (view === 'cli' && active_cli_sessions && active_cli_sessions.length === 0) {
    alert_top = 'Connect your CLI to receive new events';
  }

  if (!loading && events && count === 0) {
    return (
      <>
        {alert_top && <Alert warning>{alert_top}</Alert>}
        <EventListEmptyView webhooks={webhooks!} />
      </>
    );
  }

  const onRowSelected = (id) => {
    return id
      ? id.indexOf('atm_') === 0
        ? onAttemptSelected(id)
        : onEventSelected(id)
      : onEventSelected(null);
  };

  const { headers, widths, getEventRowFields, getAttemptRowFields } = event_list_layouts[layout](
    order_by,
    dir,
    { updateOrder: onOrderUpdated },
  );

  const rows = loading
    ? []
    : events.reduce((rows, event) => {
        const outdated = outdated_ids.includes(event.id);
        return [
          ...rows,
          {
            id: event.id,
            selected: selected_event_id === event.id && !selected_attempt_id,
            highlighted: selected_event_id === event.id,
            outdated,
            expendable: true,
            fields: getEventRowFields(
              event,
              outdated,
              event_actions,
              webhooks_by_id?.[event.webhook_id],
              cli_clients_by_id?.[event.cli_id],
            ),
          },
          ...(selected_event_id !== event.id || !event_attempts
            ? []
            : [
                {
                  headers: [
                    `Attempt Date (${getCurrentTimezoneAbreviation()})`,
                    'Status',
                    'Attempt Trigger',
                    'Delivery Latency',
                    'Response Latency',
                  ],
                  rows: event_attempts?.models?.map((attempt) => ({
                    id: attempt.id,
                    selected: selected_attempt_id === attempt.id,
                    highlighted: selected_event_id === event.id,
                    fields: getAttemptRowFields(attempt, outdated, event_actions),
                  })),
                },
              ]),
        ];
      }, []);

  return (
    <Table
      fill
      headers={headers}
      has_new_results={has_next_results}
      onLoadNewResults={refresh}
      widths={widths}
      rows={rows as any}
      loading={loading}
      loading_title={'Events Loading'}
      loading_message={'It may take a few moments to populate your events.'}
      onRowSelected={onRowSelected}
      onNextPage={
        next ? () => onPaginationChanged({ ...filters, next, prev: undefined }) : undefined
      }
      onPreviousPage={
        prev ? () => onPaginationChanged({ ...filters, prev, next: undefined }) : undefined
      }
      bottom_element={
        !loading &&
        !next &&
        !filters.date?.relative &&
        !filters.date?.min &&
        isBefore(new Date(team!.created_at), subDays(new Date(), subscription!.retention_days)) && (
          <Div p={3}>
            <Alert info inline w={100}>
              You've reached the end of your {subscription?.retention_days} days archival window.
              {!organization && (
                <Link to="/settings/organization/plans?highlight=retention_days" m={{ l: 2 }}>
                  Upgrade <Icon small icon="upgrade" />
                </Link>
              )}
            </Alert>
          </Div>
        )
      }
      alert_top={alert_top}
      current_count={count}
      total_count={has_request_filter ? undefined : total_count}
    />
  );
};

export default EventsList;
