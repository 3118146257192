import { useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import styled from 'styled-components';

import {
  EventView as IEventView,
  RequestView as IRequestView,
} from '../../../../../../typings/View.interface';
import { formatPath } from '../../../utils/path';
import ErrorBoundary from '../../common/helpers/ErrorBoundary';
import ViewRoute from '../../common/ViewRoute';
import { TeamPermission } from '../../contexts/TeamPermissionContext';
import useScrollToTop from '../../hooks/useScrollToTop';
import BookmarksView from './Bookmarks';
import CliAuthView from './CliAuth';
import ConnectionView from './Connections/Connection/ConnectionView';
import ConnectionsView from './Connections';
import CreateConnectionView from './Connections/Create/CreateView';
import {
  DashboardContext,
  DashboardContextProvider,
  WithTeamOnly,
  WithUserOnly,
} from './DashboardContext';
import DestinationView from './Connections/Destination/DestinationView';
import EventView from './Event';
import EventsView from './Events';
import ForceUpgradeModal from './ForceUpgradeModal';
import IssueView from './Issue';
import IssuesView from './Issues';
import IssueTriggersListView from './IssueTriggers';
import IssueTriggerView from './IssueTriggers/IssueTrigger/IssueTriggerView';
import MetricsView from './Metrics';
import { metric_categories } from './Metrics/metric-categories';
import MetricCategoryView from './Metrics/MetricCategory';
import DashboardNav from './Nav';
import OnboardingView from './Onboarding';
import { redirects } from './redirects';
import RequestView from './Request';
import RequestsView from './Requests';
import Settings from './Settings';
import SourceView from './Connections/Source/SourceView';
import TeamSelect from './TeamSelect';
import TransformationView from './Transformation';
import { ViewsContextProvider } from './ViewsContext';
import TeamMemberOverLimitModal from './TeamMemberOverLimitModal';
import { DialogProvider } from '../../common/Dialog';

const DashboardLayout = styled.div<{ nav_collapsed: boolean }>`
  background-color: ${({ theme }) => theme.colors.surface.base.background};
  min-height: 100vh;
  display: grid;
  grid-template-columns: ${({ theme, nav_collapsed }) =>
    nav_collapsed ? '1fr' : `${theme.pxToRem(224)} 1fr`};
`;

const StyledDashboardContent = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.base.background};
  min-height: 100vh;
  flex-grow: 1;
  min-width: 0;
  position: relative;
`;

const DashboardScene: React.FC = () => {
  const location = useLocation();
  const { view, nav_collapsed, subscription } = useContext(DashboardContext);

  useScrollToTop();

  return (
    <>
      <Switch>
        {/* Routes without team */}
        <ViewRoute
          usePathAsTitle
          capitalizeSubtitle
          path="/onboarding"
          component={OnboardingView}
        />
        <ViewRoute
          title={'Select Project'}
          usePathAsTitle
          path={'/projects'}
          component={TeamSelect}
        />
        {/* Routes with team */}
        <Route
          render={() => (
            <WithTeamOnly>
              <TeamPermission role="viewer">
                <Switch>
                  <Route path="/cli-auth/:key" component={CliAuthView} />
                  <Route>
                    <DashboardLayout nav_collapsed={nav_collapsed}>
                      {!nav_collapsed && <DashboardNav />}
                      <StyledDashboardContent id="main">
                        <ErrorBoundary>
                          <ForceUpgradeModal />
                          <TeamMemberOverLimitModal />
                          <Switch>
                            <Redirect
                              path="/webhooks"
                              to={{
                                ...location,
                                pathname: location.pathname.replace('/webhooks', '/connections'),
                              }}
                            />
                            <Route path="/settings" component={Settings} />
                            <ViewRoute
                              usePathAsTitle
                              path="/connections/new"
                              component={CreateConnectionView}
                            />
                            <ViewRoute usePathAsTitle path="/sources/:id" component={SourceView} />
                            <ViewRoute
                              usePathAsTitle
                              path="/destinations/:id"
                              component={DestinationView}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path="/connections/:id"
                              component={ConnectionView}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path="/connections"
                              component={ConnectionsView}
                            />
                            <Route
                              path={['/cli/events', '/events']}
                              render={() => (
                                <ViewsContextProvider
                                  type="events"
                                  redirect_root={`${view === 'cli' ? '/cli' : ''}/events`}>
                                  {({ views }) =>
                                    views && (
                                      <Switch>
                                        {views.models.map((view) => (
                                          <ViewRoute
                                            title={'Events'}
                                            subtitle={view.name}
                                            key={view.id}
                                            path={[
                                              `/events/${formatPath(view.name)}`,
                                              `/cli/events/${formatPath(view.name)}`,
                                            ]}>
                                            <EventsView current_view={view as IEventView} />
                                          </ViewRoute>
                                        ))}
                                        <ViewRoute
                                          usePathAsTitle
                                          path={['/events/:id', '/cli/events/:id']}
                                          component={EventView}
                                        />
                                        <ViewRoute usePathAsTitle path={['/events', '/cli/events']}>
                                          <EventsView />
                                        </ViewRoute>
                                      </Switch>
                                    )
                                  }
                                </ViewsContextProvider>
                              )}
                            />
                            <Route
                              path={['/requests', '/cli/requests']}
                              render={() => (
                                <ViewsContextProvider
                                  type="requests"
                                  redirect_root={`${view === 'cli' ? '/cli' : ''}/requests`}>
                                  {({ views }) =>
                                    views && (
                                      <Switch>
                                        {views.models.map((view) => (
                                          <ViewRoute
                                            title={'Requests'}
                                            subtitle={view.name}
                                            key={view.id}
                                            path={[
                                              `/requests/${formatPath(view.name)}`,
                                              `/cli/requests/${formatPath(view.name)}`,
                                            ]}>
                                            <RequestsView current_view={view as IRequestView} />
                                          </ViewRoute>
                                        ))}
                                        <ViewRoute
                                          usePathAsTitle
                                          path={['/requests/:id', '/cli/requests/:id']}
                                          component={RequestView}
                                        />
                                        <ViewRoute
                                          usePathAsTitle
                                          path={['/requests', '/cli/requests']}>
                                          <RequestsView />
                                        </ViewRoute>
                                      </Switch>
                                    )
                                  }
                                </ViewsContextProvider>
                              )}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path={['/bookmarks', '/cli/bookmarks']}
                              component={BookmarksView}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path="/transformations/:id*"
                              component={TransformationView}
                            />
                            <ViewRoute usePathAsTitle path="/issues/:id" component={IssueView} />
                            <ViewRoute usePathAsTitle exact path="/issues" component={IssuesView} />
                            <ViewRoute
                              usePathAsTitle
                              exact
                              path={['/issue-triggers', '/issue-triggers/create/:type']}
                              component={IssueTriggersListView}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path="/issue-triggers/:id"
                              component={IssueTriggerView}
                            />
                            {Object.keys(metric_categories).map((key) =>
                              subscription?.features?.includes('metrics') ? (
                                <ViewRoute
                                  key={key}
                                  usePathAsTitle
                                  capitalizeSubtitle
                                  path={`/metrics/${key}`}
                                  component={MetricCategoryView}
                                />
                              ) : (
                                <Redirect key={key} path={`/metrics/${key}`} to="/metrics" />
                              ),
                            )}
                            <ViewRoute usePathAsTitle path={`/metrics`} component={MetricsView} />
                            <Redirect to="/events" />
                          </Switch>
                        </ErrorBoundary>
                      </StyledDashboardContent>
                    </DashboardLayout>
                  </Route>
                </Switch>
              </TeamPermission>
            </WithTeamOnly>
          )}
        />
      </Switch>
    </>
  );
};

export default () => {
  const location = useLocation();

  return (
    <WithUserOnly>
      <Switch>
        {redirects.map((redirect) => (
          <Redirect
            key={redirect.from}
            from={redirect.from}
            to={{
              ...location,
              pathname: redirect.to,
            }}
            exact={redirect.exact}
          />
        ))}
      </Switch>
      <DashboardContextProvider>
        <DialogProvider>
          <DashboardScene />
        </DialogProvider>
      </DashboardContextProvider>
    </WithUserOnly>
  );
};
