import {
  TeamIntegrationFeature,
  TeamIntegrationProvider,
} from '../../../../typings/TeamIntegration.interface';
import { IconName } from '../components/common/base/Icon';

type Fields = {
  label: string;
  name: string;
  type: 'text' | 'select' | 'password' | 'number';
  placeholder?: string;
  required?: boolean;
  options?: { value: string; label: string }[];
}[];

export interface TeamIntegrationSchema {
  icon: IconName;
  label: string;
  features: TeamIntegrationFeature[];
  fields?: Fields;
  how_steps?: string[];
  onClick?: () => void;
  callback?: (data: any) => void;
}

const team_integration_schemas: {
  [key in Exclude<TeamIntegrationProvider, 'email'>]: TeamIntegrationSchema;
} = {
  slack: {
    icon: 'slack_colored',
    label: 'Slack',
    features: ['notification'],
    onClick: () => {
      window.location.replace(
        `${process.env.SLACK_SHARABLE_URL}&redirect_uri=${process.env.SLACK_REDIRECT_URI}`,
      );
    },
    callback: (data: any) => {
      return {
        access_token: data.code,
      };
    },
  },
  opsgenie: {
    icon: 'opsgenie_colored',
    label: 'Opsgenie',
    features: ['notification'],
    fields: [{ label: 'API key', name: 'api_key', type: 'password' }],
    how_steps: [
      'Head to your Team’s Dashboard,',
      'Click <b>Teams</b> in the top nav bar,',
      'Select a <b>Team</b> or <b>Add Team</b>,',
      'In the Team’s page, navigate to <b>Integrations</b> from the left sidebar,',
      'Click <b>Add Integration</b> and search for <b>API</b>,',
      'Enter your <b>API Key</b> in the next section,',
      'Enable <b>Read Access</b>, <b>Create and Update Access</b>, and <b>Enabled</b>,',
      'Select <b>Save Integration</b>.',
    ],
  },
  datadog: {
    icon: 'datadog',
    label: 'Datadog',
    features: ['metrics'],
    fields: [{ label: 'API key', name: 'api_key', type: 'password' }],
    how_steps: [
      'Head to your Datadog Dashboard,',
      'Click <b>Organization Settings</b> from the left sidebar,',
      'Select <b>API Keys</b> in the settings list,',
      'In the API Keys page, click <b>New Key</b> to generate a new API key,',
      'Enter the <b>API Key</b> in the below API Key field,',
      'Click <b>Connect</b> to save the integration setup to Datadog, your metrics will start sending to Datadog within a few minutes.',
    ],
  },
};

export default team_integration_schemas;
