import { useField } from 'formik';
import { Fragment, useContext, useState } from 'react';
import useSWR from 'swr';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import RadioGroupInput from '../../../../common/Form/Fields/RadioInput';
import Search from '../../../../common/Search';
import Button, { ClickableArea } from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Loading from '../../../../common/base/Loading';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import destination_configuration_form_props from '../Forms/destination_configuration';
import resource_details_form_props from '../Forms/resource_details';
import Icon from '../../../../common/base/Icon';
import Divider from '../../../../common/base/Divider';

const DestinationSection = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const [{ value: use }] = useField('destination_use');
  const [{ value: destination_id }, , { setValue: setDestinationId }] = useField('destination_id');
  const [search_term, setSearchTerm] = useState<string>('');

  const filters: Record<string, any> = {
    limit: 100,
    name: search_term ? { contains: search_term } : undefined,
  };
  const { data: destinations } = useSWR(
    use === 'select' && APIMethodKeys.destinations.list(filters),
    () => HookdeckAPI.destinations.list(filters),
  );
  const { data: select_destination } = useSWR(
    use === 'select' && destination_id && APIMethodKeys.destinations.get(destination_id),
    () => HookdeckAPI.destinations.get(destination_id),
  );

  const active_destination =
    (destination_id &&
      destinations?.models?.find((destination) => destination.id === destination_id)) ||
    select_destination;

  return (
    <StyledCard>
      <StyledCardSection muted>
        <RadioGroupInput
          name={'destination_use'}
          type="tabs"
          inline
          options={[
            { value: 'new', label: 'Create new destination' },
            { value: 'select', label: 'Use existing destination' },
          ]}
        />
      </StyledCardSection>
      {use === 'select' &&
        (destination_id ? (
          <StyledCardSection>
            <Div flex={{ align: 'center', justify: 'space-between' }} p={{ l: 3, r: 1, y: 1 }}>
              <Div flex={{ align: 'center' }}>
                <Icon icon="destination" left />
                <Text monospace text_wrap={false} ellipsis>
                  {active_destination ? active_destination.name : <Loading />}
                </Text>
                <Icon right icon="check" primary />
              </Div>
              <Button icon="close" minimal small onClick={() => setDestinationId(null)} />
            </Div>
          </StyledCardSection>
        ) : (
          <>
            <StyledCardSection>
              <Search
                focus
                borderless
                onChange={setSearchTerm}
                value={search_term}
                placeholder="Search your destinations by name..."
              />
            </StyledCardSection>
            <StyledCardSection scroll={true} h={{ px: 152 }} flex={{ direction: 'column' }}>
              {!destinations && (
                <Div p={{ x: 3, y: 2 }}>
                  <Loading />
                </Div>
              )}
              {destinations?.models.map((destination, i) => (
                <Fragment key={destination.id}>
                  {i !== 0 && <Divider />}
                  <ClickableArea
                    flex={{ align: 'center', justify: 'space-between' }}
                    p={{ x: 3, y: 2 }}
                    onClick={() => setDestinationId(destination.id)}>
                    <Div flex={{ align: 'center' }}>
                      <Icon icon="destination" left />
                      <Text monospace text_wrap={false} ellipsis>
                        {destination.name}
                      </Text>
                    </Div>
                  </ClickableArea>
                </Fragment>
              ))}
              {destinations && (
                <>
                  <Divider />
                  <Text
                    muted
                    center
                    p={{ y: 6, x: 4 }}
                    flex={{
                      grow:
                        destinations?.models && destinations?.models.length <= 4 ? true : undefined,
                      align: 'center',
                      justify: 'center',
                    }}>
                    {destinations.pagination.next
                      ? 'Try searching to find more destinations...'
                      : 'That’s it! You don’t have any other destinations.'}
                  </Text>
                </>
              )}
            </StyledCardSection>
          </>
        ))}
      {use === 'new' && (
        <StyledCardSection>
          <Div p={4}>
            <resource_details_form_props.Fields
              prefix="destination"
              placeholder="my-api"
              help="Provide a name that represents the location you'd like your events to be routed to."
            />
          </Div>
          <destination_configuration_form_props.Fields prefix="destination" />
        </StyledCardSection>
      )}
    </StyledCard>
  );
};

export default DestinationSection;
