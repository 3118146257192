import { useContext, useState } from 'react';
import {
  TransformationTemplate,
  TransformationTemplateCreateInput,
  TransformationTemplateVisibility,
  TransformationTemplateUpdateInput,
} from '../../../../../../../../../typings/TransformationTemplate.interface';
import Dropdown from '../../../../../common/Dropdown';
import DropdownMenu from '../../../../../common/DropdownMenu';
import Button, { ClickableArea } from '../../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../../common/base/Card';
import Tabs from '../../../../../common/base/Tabs';
import Text from '../../../../../common/base/Text';
import { useDialog } from '../../../../../common/Dialog';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { useToasts } from '../../../../../common/Toast';
import { fieldName } from '../../../../../../utils';
import TextInput from '../../../../../common/Form/Fields/TextInput';
import Editor from '../../../../../common/Editor';
import SelectInput from '../../../../../common/Form/Fields/SelectInput';
import { Div } from '../../../../../common/helpers/StyledUtils';
import useSWR from 'swr';
import APIMethodKeys from '../../../../../../client/APIMethodKeys';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import Loading from '../../../../../common/base/Loading';
import { UserContext } from '../../../../../contexts/UserContext';
import Search from '../../../../../common/Search';

interface Props {
  code: string;
  env_keys: string[];
  onTemplateSelected: (template: TransformationTemplate) => void;
}

const TransformationTemplateDropdown = ({ code, env_keys, onTemplateSelected }: Props) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const showDialog = useDialog();
  const { addToast } = useToasts();
  const [search_term, setSearchTerm] = useState('');

  const [active_tab, setActiveTab] = useLocalStorage<TransformationTemplateVisibility>(
    'pref:transformation-template:visibility',
    'private',
  );

  const filters = {
    limit: 100,
    visibility: active_tab,
    ...(search_term ? { label: { contains: search_term } } : {}),
  };
  const { data: templates, mutate } = useSWR(
    APIMethodKeys.transformation_templates.list(filters),
    () => HookdeckAPI.transformation_templates.list(filters),
  );

  const showDelete = async (template: TransformationTemplate) => {
    showDialog(
      () => {
        HookdeckAPI.transformation_templates
          .delete(template.id)
          .then(({ id }) => {
            mutate(
              templates && {
                ...templates,
                count: templates.count - 1,
                models: templates.models.filter((model) => (model.id === id ? false : true)),
              },
            );
            addToast('success', 'Template deleted');
          })
          .catch(() => {
            addToast('error', 'Failed to delete template');
          });
      },
      undefined,
      {
        title: 'Are you sure you want to delete this template?',
        message: `You and your team members won't be able to retrieve this template if deleted. Existing transformations won't be impacted.`,
        danger: true,
      },
    );
  };

  const showUpdateDialog = (template: TransformationTemplate) => {
    showDialog(
      (values: TransformationTemplateUpdateInput) => {
        HookdeckAPI.transformation_templates
          .update(template.id, values)
          .then((transformation_template) => {
            mutate(
              templates && {
                ...templates,
                models: templates.models.map((model) =>
                  model.id === transformation_template.id ? transformation_template : model,
                ),
              },
            );
            addToast('success', 'Template created');
            values.visibility && setActiveTab(values.visibility);
          })
          .catch((error) => addToast('error', 'Failed to create template'));
      },
      () => null,
      {
        title: 'Update Template',
        submit_label: 'Save',
        cancel_label: 'Cancel',
        form_props: {
          initial_values: template,
          validate: ({ label }: { label: string }): Partial<{ label: string }> | {} => {
            if (!label || label.length < 0) return { label: 'Required' };
            return {};
          },
          Fields: () => (
            <>
              <TextInput
                name={fieldName('label')}
                label="Template Label"
                m={{ b: 4 }}
                maxlength={255}
                required
                focus
              />
              <SelectInput
                w={100}
                options={[
                  { value: 'private', label: 'Private' },
                  { value: 'project', label: 'Project' },
                  { value: 'organization', label: 'Organization' },
                ]}
                name={fieldName('visibility')}
                label="Visibility"
                m={{ b: 4 }}
              />
              <Text subtitle size="s" m={{ b: 1 }}>
                Transformation Code
              </Text>
              <StyledCard overflow_hidden>
                <Editor
                  prevent_theme_change
                  value={template.code}
                  language="javascript"
                  display="minimal"
                  height={'150px'}
                />
              </StyledCard>
              <Text subtitle size="s" m={{ b: 1, t: 4 }}>
                Environment Keys
              </Text>
              <Div flex>
                {template.env_keys.length > 0 ? (
                  template.env_keys.map((key) => (
                    <Text as="code" key={key} m={{ r: 2 }}>
                      {key}
                    </Text>
                  ))
                ) : (
                  <Text>No environment keys</Text>
                )}
              </Div>
            </>
          ),
        },
      },
    );
  };

  const showPreview = (template: TransformationTemplate) => {
    showDialog(
      () => {
        onTemplateSelected(template);
      },
      () => null,
      {
        title: 'Template Preview',
        submit_label: 'Use',
        cancel_label: 'Close',
        form_props: {
          initial_values: {},
          validate: (): object => ({}),
          Fields: () => (
            <>
              <Text subtitle size="s" m={{ b: 1 }}>
                Label
              </Text>
              <Text as={'p'}>{template.label}</Text>
              <Text subtitle size="s" m={{ b: 1 }}>
                Transformation Code
              </Text>
              <StyledCard overflow_hidden>
                <Editor
                  value={template.code}
                  prevent_theme_change
                  language="javascript"
                  display="minimal"
                  height={'150px'}
                />
              </StyledCard>
              <Text subtitle size="s" m={{ b: 1, t: 4 }}>
                Environment Keys
              </Text>
              <Div flex>
                {template.env_keys.length > 0 ? (
                  template.env_keys.map((key) => (
                    <Text as="code" key={key} m={{ r: 2 }}>
                      {key}
                    </Text>
                  ))
                ) : (
                  <Text>No environment keys</Text>
                )}
              </Div>
            </>
          ),
        },
      },
    );
  };

  const showCreateDialog = () => {
    showDialog(
      (values: TransformationTemplateCreateInput) => {
        HookdeckAPI.transformation_templates
          .create({
            ...values,
            code,
            env_keys,
          })
          .then((transformation_template) => {
            mutate(
              templates && {
                ...templates,
                count: templates.count + 1,
                models: [transformation_template, ...templates.models],
              },
            );
            addToast('success', 'Template created');
            setActiveTab(values.visibility);
          })
          .catch((error) => addToast('error', 'Failed to create template'));
      },
      () => null,
      {
        title: 'Publish Template',
        submit_label: 'Publish',
        cancel_label: 'Cancel',
        form_props: {
          initial_values: {
            visibility: 'private',
          },
          validate: ({ label }: { label: string }): Partial<{ label: string }> | {} => {
            if (!label || label.length < 0) return { label: 'Required' };
            return {};
          },
          Fields: () => (
            <>
              <TextInput
                name={fieldName('label')}
                label="Template Label"
                m={{ b: 4 }}
                maxlength={255}
                required
                focus
              />
              <SelectInput
                w={100}
                options={[
                  { value: 'private', label: 'Private' },
                  { value: 'team', label: 'Project' },
                  { value: 'organization', label: 'Organization' },
                ]}
                name={fieldName('visibility')}
                label="Visibility"
                m={{ b: 4 }}
              />
              <Text subtitle size="s" m={{ b: 1 }}>
                Transformation Code
              </Text>
              <StyledCard overflow_hidden>
                <Editor
                  prevent_theme_change
                  value={code}
                  language="javascript"
                  display="minimal"
                  height={'150px'}
                />
              </StyledCard>
              <Text subtitle size="s" m={{ b: 1, t: 4 }}>
                Environment Keys
              </Text>
              <Div flex>
                {env_keys.length > 0 ? (
                  env_keys.map((key) => (
                    <Text as="code" key={key} m={{ r: 2 }}>
                      {key}
                    </Text>
                  ))
                ) : (
                  <Text muted>No environment keys</Text>
                )}
              </Div>
            </>
          ),
        },
      },
    );
  };
  return (
    <Dropdown placement="bottom-end" label="Templates" icon="transformation" outline>
      <StyledCardSection muted p={{ t: 2, x: 4 }}>
        <Tabs
          compact
          border={false}
          active_tab={active_tab}
          onTabSelected={(tab) => setActiveTab(tab as TransformationTemplateVisibility)}
          tabs={[
            { key: 'private', label: 'Private' },
            { key: 'team', label: 'Project' },
            { key: 'organization', label: 'Organization' },
          ]}
        />
      </StyledCardSection>

      <StyledCardSection p={{ x: 4, y: 3 }}>
        <Search value={search_term} onChange={setSearchTerm} />
      </StyledCardSection>
      <StyledCardSection
        p={4}
        flex={{ direction: 'column', gap: 2 }}
        max_h={{ px: 300 }}
        scroll
        w={{ px: 420 }}>
        {templates ? (
          templates.models.map((template) => (
            <Div flex={{ gap: 2, grow: true }} w={100} key={template.id}>
              <StyledCard w={{ px: 100 }} flex={{ grow: true }}>
                <ClickableArea
                  p={{ x: 3, y: 1.25 }}
                  flex={{ align: 'center' }}
                  onClick={() => showPreview(template)}>
                  <Text subtitle ellipsis>
                    {template.label}
                  </Text>
                </ClickableArea>
              </StyledCard>
              <DropdownMenu
                icon="vertical_more"
                placement="bottom-end"
                outline
                options={[
                  {
                    label: 'Use Template',
                    onClick: () => onTemplateSelected(template),
                  },
                  ...(template.created_by === user!.id
                    ? [
                        {
                          label: 'Update Template',
                          onClick: () => showUpdateDialog(template),
                        },
                      ]
                    : []),
                  {
                    label: 'Delete Template',
                    onClick: () => showDelete(template),
                    danger: true,
                  },
                ]}
              />
            </Div>
          ))
        ) : (
          <Div m={{ t: 4 }}>
            <Loading />
          </Div>
        )}
        {templates && templates.models.length === 0 && (
          <Text m={{ t: 4 }} muted>
            {search_term
              ? 'There are no template with this label...'
              : 'There are no templates yet...'}
          </Text>
        )}
        {templates && templates.models.length === filters.limit && (
          <Text m={{ t: 4 }} muted>
            Up to {filters.limit} templates are displayed. Search to find more.
          </Text>
        )}
      </StyledCardSection>
      <StyledCardSection p={{ x: 3, y: 2 }} flex={{ justify: 'flex-end' }}>
        <Button outline primary icon="add_circle" onClick={showCreateDialog}>
          Publish Template
        </Button>
      </StyledCardSection>
    </Dropdown>
  );
};

export default TransformationTemplateDropdown;
