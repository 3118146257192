import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Prompt, useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import LINKS from '../../../../../configs/links';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import Button from '../../../../common/base/Button';
import Container from '../../../../common/base/Container';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { withTeamPermission } from '../../../../contexts/TeamPermissionContext';
import { DashboardContext } from '../../DashboardContext';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../../StyledView';
import destination_configuration_form_props from '../Forms/destination_configuration';
import resource_details_form_props from '../Forms/resource_details';
import rule_form_props from '../Forms/rules';
import source_configuration_form_props from '../Forms/source_configuration';
import DestinationSection from './DestinationSection';
import SourceSection from './SourceSection';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import Analytics from '../../../../../client/Analytics';

const sections = [
  {
    label: 'Configure a Source',
    description:
      'Define where your events come from, and Hookdeck will provide a corresponding endpoint URL when your connection is created. Sources can be reused across multiple connections.',
    required: true,
    icon: 'source' as const,
    Component: SourceSection,
  },
  {
    label: 'Configure a Destination',
    description:
      'Define where your events should be sent. Destinations can be reused across multiple connections.',
    required: true,
    icon: 'destination' as const,
    Component: DestinationSection,
  },
  {
    label: 'Set Connection Rules',
    description:
      'Configure rules to change the behavior of events traveling through your connection.',
    icon: 'gavel' as const,
    Component: () => <rule_form_props.Fields prefix="rules" display="full" />,
  },
  {
    label: 'Set Connection Name',
    description:
      'Optionally set a name and description for your connection. Recommended if you have multiple connections with the same source and destination, or if you use the API.',
    icon: 'edit_square' as const,
    Component: () => (
      <resource_details_form_props.Fields
        prefix=""
        placeholder="Enter a connection name or leave empty"
        hide_label
        name_required={false}
      />
    ),
  },
  {
    label: 'Create Connection',
    description: 'Create your connection and all associated resources.',
    icon: 'add_circle' as const,
    submit: true,
  },
];

const StyledProgress = styled.div<{ is_last?: boolean; required?: boolean }>(
  ({ theme, is_last, required }) => css`
    height: auto;
    border-left: ${is_last ? 'none' : `1px solid ${theme.colors.surface.base.variant_surface}`};
    width: 1px;
    position: relative;
    margin-top: 24px;
    margin-bottom: -24px;
    display: flex;
    justify-content: center;
    span {
      position: absolute;
      top: -4px;
      left: -8px;
      color: ${required
        ? theme.colors.on.hue_container.primary
        : theme.colors.on.neutral.primary_neutral};
    }
    ::before {
      content: '';
      position: absolute;
      top: -8px;
      left: -12px;
      display: block;
      border-radius: 50%;
      background-color: ${required
        ? theme.colors.surface.container.primary
        : theme.colors.surface.base.variant_surface};
      height: 24px;
      width: 24px;
    }
  `,
);

const CreateConnectionView = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { mutateHasConnection } = useContext(DashboardContext);
  const history = useHistory();
  const { addToast } = useToasts();
  const { query } = useSearchQuery<{
    source_id: string;
    destination_id: string;
  }>();

  const source_id = query.source_id || null;
  const destination_id = query.destination_id || null;
  const initial_values = {
    source_id: source_id,
    source_use: source_id ? 'select' : 'new',
    source: {
      ...resource_details_form_props.getInitialValues(),
      ...source_configuration_form_props.getInitialValues(),
    },
    destination_id: destination_id,
    destination_use: destination_id ? 'select' : 'new',
    destination: {
      ...resource_details_form_props.getInitialValues(),
      ...destination_configuration_form_props.getInitialValues(),
    },
    rules: rule_form_props.getInitialValues(),
    ...resource_details_form_props.getInitialValues(),
  };

  const validate = async (values: typeof initial_values) => {
    const errors = {
      source_id: values.source_use === 'select' && !values.source_id ? 'Required' : null,
      source:
        values.source_use === 'new' && values.source
          ? {
              ...(await resource_details_form_props.validate(
                values.source,
                HookdeckAPI.sources.nameIsUsed,
              )),
              ...(await source_configuration_form_props.validate(values.source)),
            }
          : null,
      destination_id:
        values.destination_use === 'select' && !values.destination_id ? 'Required' : null,
      destination:
        values.destination_use === 'new' && values.destination
          ? {
              ...(await resource_details_form_props.validate(
                values.destination,
                HookdeckAPI.destinations.nameIsUsed,
              )),
              ...(await destination_configuration_form_props.validate(values.destination)),
            }
          : null,
      rules: await rule_form_props.validate(values.rules, HookdeckAPI),
      ...(await resource_details_form_props.validate(
        values,
        (name) =>
          values?.source_id && name
            ? HookdeckAPI.webhooks.nameIsUsed(values.source_id, name)
            : Promise.resolve(false),
        false,
      )),
    };

    const cleansedErrors = cleanseFormErrorObject(errors);

    Analytics.capture('Create Connection Errors', { errors: cleansedErrors });

    return cleansedErrors;
  };

  const onSubmit = (values) => {
    const input = {
      name: values.name || null,
      description: values.description || null,
      source_id: values.source_use === 'select' ? values.source_id : undefined,
      source:
        values.source_use === 'new'
          ? {
              name: values.source.name,
              description: values.source.description || null,
              ...source_configuration_form_props.postprocessValues(values.source),
            }
          : undefined,
      destination_id: values.destination_use === 'select' ? values.destination_id : undefined,
      destination:
        values.destination_use === 'new'
          ? {
              name: values.destination.name,
              description: values.destination.description || null,
              ...destination_configuration_form_props.postprocessValues(values.destination),
            }
          : undefined,
      rules: rule_form_props.postprocessValues(values.rules),
    };
    return HookdeckAPI.webhooks
      .create(input)
      .then((connection) => {
        mutateHasConnection();
        addToast('success', 'Connection created');
        history.push(`/connections?created=${connection.id}`);
      })
      .catch((error) => {
        addToast('error', 'Connection failed to create');
      });
  };
  return (
    <StyledViewWrapper>
      <StyledViewContent light>
        <PageNav
          breadcrumb={[
            { icon: 'connections', title: 'Connections', path: '/connections' },
            { icon: 'connections', title: 'Create Connection' },
          ]}>
          <Button
            as="a"
            outline
            icon="document"
            href={LINKS.product_docs.connection_guide}
            target="_blank">
            Connections Guide
          </Button>
          <Button outline icon="cancel" to="/connections">
            Cancel
          </Button>
        </PageNav>
        <Container large m={{ y: 14 }}>
          <Formik initialValues={initial_values} validate={validate} onSubmit={onSubmit}>
            {({ submitForm, isSubmitting, dirty, isValid, submitCount, errors }) => (
              <Form>
                <Prompt
                  when={!isSubmitting && dirty}
                  message={(location) =>
                    location.pathname != '/connections/new'
                      ? 'Are you sure you want to quit without saving your work?'
                      : true
                  }
                />
                {sections.map((section, index) => (
                  <Div key={index} p={{ x: 7 }} flex>
                    <StyledProgress
                      is_last={sections.length - 1 === index}
                      required={section.required || section.submit}>
                      <Icon icon={section.icon} />
                    </StyledProgress>
                    <Div p={{ l: 6 }} w={100}>
                      <Div m={{ b: 14 }}>
                        <Div flex={{ justify: 'space-between', align: 'center' }}>
                          <Div>
                            <Text heading size="l" as="h3" m={{ b: 0 }} flex={{ align: 'center' }}>
                              {section.label}
                              {section.required && (
                                <Text heading size="l" danger>
                                  *
                                </Text>
                              )}
                            </Text>
                            <Text as="p" muted m={{ b: 4 }}>
                              {section.description}
                            </Text>
                          </Div>
                          {section.submit && (
                            <Div flex={{ align: 'center', gap: 4 }}>
                              {!isValid && submitCount > 0 && Object.keys(errors).length > 0 && (
                                <Text danger size="s" flex={{ align: 'center' }}>
                                  <Icon icon="error" left={1} />
                                  Fix form errors to create
                                </Text>
                              )}
                              <Button
                                primary
                                icon={isSubmitting ? 'loading' : 'add_circle'}
                                onClick={() => submitForm()}
                                disabled={isSubmitting || (submitCount > 0 && !isValid)}>
                                {!isSubmitting ? 'Create' : 'Saving'}
                              </Button>
                            </Div>
                          )}
                        </Div>
                        {section.Component && <section.Component />}
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Form>
            )}
          </Formik>
        </Container>
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default withTeamPermission(CreateConnectionView, 'member');
