import { addDays, differenceInHours, format } from 'date-fns';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import { extractFromArray, numberWithCommas } from '../../../../utils';
import { getCurrentTimezoneAbreviation } from '../../../../utils/date';
import Alert from '../../../common/base/Alert';
import Badge from '../../../common/base/Badge';
import Button from '../../../common/base/Button';
import { StyledCard } from '../../../common/base/Card';
import Container from '../../../common/base/Container';
import Icon from '../../../common/base/Icon';
import LabelButton from '../../../common/base/LabelButton';
import Link from '../../../common/base/Link';
import Loading from '../../../common/base/Loading';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import DisplayDate from '../../../common/DisplayDate';
import { Div } from '../../../common/helpers/StyledUtils';
import InfoTable from '../../../common/InfoTable';
import { Status } from '../../../common/Status';
import Table from '../../../common/Table';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useCopyASCurl from '../../../hooks/useCopyAsCurl';
import useEventAction from '../../../hooks/useEventActions';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { DashboardContext } from '../DashboardContext';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../StyledView';
import { getErrorCodeLabels } from '../../../../utils/error-codes';
import EventPreview from '../Events/EventPreview';
import { ATTEMPT_TRIGGER_LABELS } from '../../../../utils/attempt';
import NotFound from '../NotFound';
import FullRequestData from '../../../common/Request/FullRequestData';

interface Query {
  selected_attempt_id?: string | string[];
}

const EventView: React.FC = () => {
  const { id: event_id } = useParams();
  const { query, updateSearchQuery } = useSearchQuery<Query>();
  const selected_attempt_id = extractFromArray(query.selected_attempt_id) as string;
  const onAttemptSelected = (selected_attempt_id: string) =>
    updateSearchQuery({ selected_attempt_id });

  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription, view, organization } = useContext(DashboardContext);
  const {
    data: event,
    mutate,
    error,
  } = useSWR(APIMethodKeys.events.get(event_id), () => HookdeckAPI.events.get(event_id), {
    refreshInterval: 5000,
  });
  const { data: webhook, error: webhook_error } = useSWR(
    event && APIMethodKeys.webhooks.get(event.webhook_id),
    () => HookdeckAPI.webhooks.get(event!.webhook_id),
  );

  const { data: attempts, mutate: mutateAttempts } = useSWR(
    APIMethodKeys.attempts.list({ event_id }),
    () => HookdeckAPI.attempts.list({ event_id }),
    {
      refreshInterval: 5000,
    },
  );
  const { data: bookmarks, mutate: mutateBookmarks } = useSWR(
    event && APIMethodKeys.bookmarks.list({ event_data_id: event.event_data_id }),
    () => HookdeckAPI.bookmarks.list({ event_data_id: event!.event_data_id }),
  );

  const { data: cli_clients } = useSWR(
    event && event.cli_id && APIMethodKeys.cli_clients.list({ id: event.cli_id }),
    () => HookdeckAPI.cli_clients.list({ id: event!.cli_id }),
  );

  const copyAsCurl = useCopyASCurl({
    type: 'event',
    id: event_id,
    event_data: event?.data ?? undefined,
    destination: webhook?.destination,
  });

  const { mute, retry, bookmark } = useEventAction((action, event, meta) => {
    switch (action) {
      case 'mute':
        mutate(event);
        break;
      case 'retry':
        mutate();
        mutateAttempts();
        break;
      case 'bookmark':
        mutateBookmarks((bookmarks) => ({
          ...bookmarks!,
          models: [...(bookmarks?.models ?? []), ...(meta?.bookmark ? [meta?.bookmark] : [])],
        }));
        break;
    }
  });

  if (error && error.response?.status === 404) {
    return (
      <NotFound
        title="Event is archived or does not exist"
        description={`Events are automatically archived after your plan ${
          subscription!.retention_days
        } days retention period has passed.`}
        id={event_id}
        link={{ to: `${view === 'cli' ? '/cli' : ''}/events`, text: 'All Events' }}
      />
    );
  }

  if (
    !event ||
    (!webhook && !webhook_error) ||
    !attempts ||
    !bookmarks ||
    (event?.cli_id && !cli_clients)
  ) {
    return (
      <Div flex={{ justify: 'center' }} p={8}>
        <Loading />
      </Div>
    );
  }

  const rows = attempts.models.map((attempt, i) => ({
    id: attempt.id,
    selected: attempt.id === selected_attempt_id,
    fields: [
      <DisplayDate key={`${attempt.id}-date`} date={attempt.created_at} />,
      <Status small key={`${attempt.id}-status`} {...attempt} />,
      <Badge small key={`${attempt.id}-trigger`} muted>
        {ATTEMPT_TRIGGER_LABELS[attempt.trigger]}
      </Badge>,
      <Text key={`${attempt.id}-delivery`} size="s" monospace muted>
        {!attempt.delivery_latency ? '-' : `${numberWithCommas(attempt.delivery_latency)}ms`}
      </Text>,
      <Text key={`${attempt.id}-response`} size="s" monospace muted>
        {!attempt.response_latency ? '-' : `${numberWithCommas(attempt.response_latency)}ms`}
      </Text>,
    ],
  }));

  const archived_at = addDays(new Date(event.created_at), subscription!.retention_days);
  const hours_before_archive = differenceInHours(archived_at, new Date());

  return (
    <StyledViewWrapper>
      <StyledViewContent light>
        <PageNav
          breadcrumb={[
            {
              icon: 'events',
              title: `${view === 'cli' ? 'CLI ' : ''}Events`,
              path: `${view === 'cli' ? '/cli' : ''}/events`,
            },
            { icon: 'events', title: event_id, monospace: true },
          ]}>
          <Tooltip tooltip="Copy request as cURL" placement="bottom-end">
            <Button outline icon="copy" onClick={copyAsCurl}>
              cURL
            </Button>
          </Tooltip>
          <Button.Permission
            role="member"
            onClick={() => retry(event)}
            outline
            icon="retry"
            disabled={webhook_error?.response?.status === 410}>
            Retry
          </Button.Permission>
          <Button.Permission
            role="member"
            outline
            onClick={() => bookmark(event)}
            disabled={bookmarks.models.length > 0}
            icon={bookmarks.models.length > 0 ? 'bookmark' : 'bookmark_add'}>
            {bookmarks.models.length > 0 ? 'Bookmarked' : 'Bookmark'}
          </Button.Permission>
        </PageNav>
        <Container large>
          <Text heading size="l" m={{ t: 16, b: 4 }}>
            Event Details
          </Text>
          <InfoTable
            entries={[
              {
                label: 'Status',
                element: <Status show_response={false} {...event} />,
              },
              {
                label: 'Created At',
                element: (
                  <Text monospace>
                    {format(new Date(event.created_at), `yyyy-MM-dd HH:mm:ss`)}{' '}
                    {getCurrentTimezoneAbreviation()}
                  </Text>
                ),
              },
              ...[
                event.error_code
                  ? {
                      label: 'Latest Error',
                      element: (
                        <Badge danger subtle>
                          {event.error_code}
                        </Badge>
                      ),
                    }
                  : {
                      label: 'Latest Response',
                      element: !event.response_status ? <Text>–</Text> : <Status {...event} />,
                    },
              ],
              {
                label: 'Last Attempt At',
                element: !event.last_attempt_at ? (
                  <Text>–</Text>
                ) : (
                  <Text monospace>
                    {format(new Date(event.last_attempt_at), `yyyy-MM-dd HH:mm:ss`)}{' '}
                    {getCurrentTimezoneAbreviation()}
                  </Text>
                ),
              },
              {
                label: 'Delivery Attempts',
                element: <Badge muted>{event.attempts}</Badge>,
              },
              {
                label: 'Next Attempt At',
                element: !event.next_attempt_at ? (
                  <Text>–</Text>
                ) : (
                  <Text monospace>
                    {format(new Date(event.next_attempt_at), `yyyy-MM-dd HH:mm:ss`)}{' '}
                    {getCurrentTimezoneAbreviation()}
                  </Text>
                ),
              },
              {
                label: 'Connection',
                element: (
                  <>
                    {webhook_error &&
                      (webhook_error?.response?.status === 410 ? (
                        <Text as="p" m={0} muted>
                          Deleted
                        </Text>
                      ) : (
                        <Text as="p" m={0} muted>
                          Error retrieving name
                        </Text>
                      ))}
                    {!webhook_error && (
                      <LabelButton
                        monospace
                        label={webhook!.full_name}
                        neutral
                        to={`/connections/${webhook!.id}`}
                      />
                    )}
                  </>
                ),
              },
              {
                label: 'Successful At',
                element: !event.successful_at ? (
                  <Text>–</Text>
                ) : (
                  <Text monospace>
                    {format(new Date(event.successful_at), `yyyy-MM-dd HH:mm:ss`)}{' '}
                    {getCurrentTimezoneAbreviation()}
                  </Text>
                ),
              },
              {
                label: 'Request',
                element: (
                  <LabelButton
                    monospace
                    label={event.request_id}
                    neutral
                    to={`/requests/${event.request_id}`}
                  />
                ),
              },
              {
                label: 'CLI Client',
                element: <Text>{cli_clients?.models[0]?.device_name || '–'}</Text>,
              },
              {
                label: 'Latest Attempt',
                element: (
                  <LabelButton
                    monospace
                    label={attempts.models[0].id}
                    neutral
                    onClick={() => onAttemptSelected(attempts.models[0].id)}
                  />
                ),
              },
            ]}
          />
          {event.error_code && (
            <Alert inline m={{ t: 4 }} danger>
              {getErrorCodeLabels(subscription)[event.error_code]}
            </Alert>
          )}
          {hours_before_archive <= 24 && hours_before_archive >= 0 && (
            <Alert info inline m={{ t: 4 }}>
              This event will be archived in{' '}
              {hours_before_archive === 0 ? 'less then an hour' : `${hours_before_archive} hours`}.
              {!organization && (
                <Link to="/settings/organization/plans?highlight=retention_days" m={{ l: 1 }}>
                  Upgrade <Icon small icon="upgrade" />
                </Link>
              )}
            </Alert>
          )}
          {event.data ? (
            <>
              <Text heading size="l" m={{ t: 14, b: 4 }}>
                Event Data
              </Text>
              <FullRequestData data={event.data} compact={false} type="event" id={event.id} />
            </>
          ) : (
            <>
              <Text heading size="l" m={{ t: 14, b: 4 }}>
                Event Data
              </Text>
              <Text as="p">
                This event is past your archival period of {subscription!.retention_days} days, the
                request data is no longer available.
              </Text>
              {!organization && (
                <Link to="/settings/organization/plans?highlight=retention_days" icon="upgrade">
                  Upgrade Plan
                </Link>
              )}
            </>
          )}
          <Text heading size="l" m={{ t: 16, b: 4 }}>
            Delivery Attempts
          </Text>
          {attempts && (
            <StyledCard m={{ b: 16 }} overflow_hidden>
              <Table
                headers={[
                  `Attempt Date (${getCurrentTimezoneAbreviation()})`,
                  'Status',
                  'Attempt Trigger',
                  'Delivery Latency',
                  'Response Latency',
                ]}
                widths={[
                  { max: 172, min: 172 },
                  { max: 140, min: 140 },
                  { min: 100 },
                  { min: 128, max: 128 },
                  { max: 172, min: 172 },
                ]}
                onRowSelected={onAttemptSelected}
                rows={rows}
              />
            </StyledCard>
          )}
        </Container>
      </StyledViewContent>
      {selected_attempt_id && (
        <EventPreview
          event_id={event_id}
          attempt_id={selected_attempt_id}
          onClose={() => updateSearchQuery({}, { remove_keys: ['selected_attempt_id'] })}
        />
      )}
    </StyledViewWrapper>
  );
};

export default EventView;
