import { differenceInDays } from 'date-fns';
import { useContext } from 'react';
import { Route } from 'react-router-dom';
import useSWR from 'swr';

import { Grid, GridUnit } from '@hookdeck/theme';

import APIMethodKeys from '../../../../../../client/APIMethodKeys';
import { capitalizeFirstLetter } from '../../../../../../utils';
import { isFreePlan } from '../../../../../../utils/subscription';
import Alert from '../../../../../common/base/Alert';
import Badge from '../../../../../common/base/Badge';
import Button from '../../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../../common/base/Card';
import Divider from '../../../../../common/base/Divider';
import Icon from '../../../../../common/base/Icon';
import Link from '../../../../../common/base/Link';
import Loading from '../../../../../common/base/Loading';
import Text from '../../../../../common/base/Text';
import Tooltip from '../../../../../common/base/Tooltip';
import { Div } from '../../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { useAuthorized } from '../../../../../contexts/TeamPermissionContext';
import { DashboardContext } from '../../../DashboardContext';
import EmailModal from './EmailModal';
import InvoicesModal from './InvoicesModal';
import MemoizedEventsUsage from './MeteredUsage';
import PaymentMethodModal from './PaymentMethodModal';
import { PLAN_FEATURES_DISPLAY_NAMES } from '../../../../../../utils/plan';
import ProjectThroughputs from './ProjectsThroughput';

const OrganizationBilling: React.FC = () => {
  const isAuthorized = useAuthorized('admin');
  const { subscription } = useContext(DashboardContext);
  const { HookdeckAPI } = useContext(GlobalContext);

  const { data: card } = useSWR(APIMethodKeys.billing.getCard(), () =>
    HookdeckAPI.billing.getCard(),
  );

  const { data: subscription_details } = useSWR(
    APIMethodKeys.billing.getSubscriptionDetails(),
    () => HookdeckAPI.billing.getSubscriptionDetails(),
  );

  const { data: billing_email, mutate: mutateBillingEmail } = useSWR(
    APIMethodKeys.billing.getBillingEmail(),
    () => HookdeckAPI.billing.getBillingEmail(),
  );

  if (subscription_details === undefined || billing_email === undefined) {
    return (
      <Div p={8} flex={{ justify: 'center' }}>
        <Loading />
      </Div>
    );
  }

  const display_price = subscription_details.plan.prices.reduce((total, price) => {
    if (price.price_type === 'fixed_price') {
      let amount = Number(price.unit_config?.unit_amount) * price.fixed_price_quantity;
      if (subscription_details.plan.discount?.applies_to_price_ids.includes(price.id)) {
        let discount = 0;
        if (subscription_details.plan.discount.discount_type === 'amount') {
          discount = Number(subscription_details.plan.discount.amount_discount);
        } else if (subscription_details.plan.discount.discount_type === 'percentage') {
          discount = amount * subscription_details.plan.discount.percentage_discount;
        }
        amount = amount - discount;
      }
      return total + amount;
    }

    return total;
  }, 0);

  const plan_features =
    (subscription_details.plan.metadata.features?.length > 0 &&
      subscription_details.plan.metadata.features?.split(',')) ||
    [];
  return (
    <>
      <Route path="/settings/organization/billing/invoices" exact component={InvoicesModal} />
      <Route
        path="/settings/organization/billing/email"
        render={() =>
          isAuthorized && (
            <EmailModal
              email={billing_email.email}
              mutate={(email) => mutateBillingEmail({ email })}
            />
          )
        }
      />
      <Route
        path="/settings/organization/billing/payment-method"
        component={isAuthorized ? PaymentMethodModal : undefined}
      />
      <Div m={{ b: 14 }}>
        <Text heading as="h2" m={{ b: 2 }}>
          Plan Details
        </Text>
        <Divider m={{ b: 4 }} />
        {subscription?.cancel_at && new Date(subscription.cancel_at).getTime() > Date.now() && (
          <Alert warning inline m={{ b: 4 }}>
            You have {differenceInDays(new Date(subscription.cancel_at), new Date())} days left to
            your plan. Afterward your plan will be downgraded to the Developer (free) Plan.
          </Alert>
        )}
        <StyledCard m={{ b: 4 }}>
          <StyledCardSection muted>
            <Grid>
              <GridUnit size={1 / 3}>
                <Div flex={{ justify: 'space-between' }}>
                  <Text muted subtitle size="s" p={{ x: 2, y: 1 }}>
                    Current Plan
                  </Text>
                  <Divider vertical />
                </Div>
              </GridUnit>
              <GridUnit size={1 / 3}>
                <Div flex={{ justify: 'space-between' }}>
                  <Text muted subtitle size="s" p={{ x: 2, y: 1 }}>
                    # of seats
                  </Text>
                  <Divider vertical />
                </Div>
              </GridUnit>
              <GridUnit size={1 / 3}>
                <Text muted subtitle size="s" p={{ x: 2, y: 1 }}>
                  Retention Length
                </Text>
              </GridUnit>
            </Grid>
          </StyledCardSection>
          <StyledCardSection>
            <Grid>
              <GridUnit size={1 / 3}>
                <Div flex={{ justify: 'space-between' }}>
                  <Div p={2}>
                    <Text capitalize heading size="s">
                      {subscription_details.plan.name}
                    </Text>
                    {plan_features && plan_features.length > 0 && (
                      <Text muted size="s">
                        Includes{' '}
                        {plan_features
                          .map((f: string) => PLAN_FEATURES_DISPLAY_NAMES[f].toLowerCase())
                          .join(', ')}
                      </Text>
                    )}
                  </Div>
                  <Divider vertical />
                </Div>
              </GridUnit>
              <GridUnit size={1 / 3}>
                <Div h={100} flex={{ justify: 'space-between' }}>
                  <Div p={2}>
                    <Badge muted>{subscription?.max_users || 'Unlimited'} Seats</Badge>
                  </Div>
                  <Divider vertical />
                </Div>
              </GridUnit>
              <GridUnit size={1 / 3}>
                <Badge muted m={2}>
                  {subscription?.retention_days} Days
                </Badge>
              </GridUnit>
            </Grid>
          </StyledCardSection>
        </StyledCard>
        <Button
          outline
          primary
          to={(location) => ({
            ...location,
            pathname: `/settings/organization/plans`,
            state: { scroll: false },
          })}
          icon={subscription && isFreePlan(subscription.plan) ? 'upgrade' : 'edit'}>
          {subscription && isFreePlan(subscription.plan) ? 'Upgrade Plan' : 'Change Plan'}
        </Button>
      </Div>
      <Div m={{ b: 14 }}>
        <Div flex={{ justify: 'space-between', align: 'center' }} m={{ b: 2 }}>
          <Text heading as="h2" m={0}>
            Billing Details
          </Text>
          <Link
            primary
            to={(location) => ({
              ...location,
              pathname: `/settings/organization/billing/invoices`,
              state: { scroll: false },
            })}>
            View Invoices
          </Link>
        </Div>
        <Divider m={{ b: 4 }} />
        <StyledCard m={{ b: 4 }}>
          <StyledCardSection muted>
            <Grid>
              <GridUnit size={1 / 3}>
                <Div flex={{ justify: 'space-between' }}>
                  <Text muted subtitle size="s" p={{ x: 2, y: 1 }}>
                    Base Plan Price
                  </Text>
                  <Divider vertical />
                </Div>
              </GridUnit>
              <GridUnit size={1 / 3}>
                <Div flex={{ justify: 'space-between' }}>
                  <Text muted subtitle size="s" p={{ x: 2, y: 1 }}>
                    Billing Period
                  </Text>
                  <Divider vertical />
                </Div>
              </GridUnit>
              <GridUnit size={1 / 3}>
                <Text muted subtitle size="s" p={{ x: 2, y: 1 }}>
                  Payment Method
                </Text>
              </GridUnit>
            </Grid>
          </StyledCardSection>
          <StyledCardSection>
            <Grid>
              <GridUnit size={1 / 3}>
                <Div flex={{ justify: 'space-between' }}>
                  <Div p={2}>
                    <Div flex={{ align: 'center' }}>
                      <Text size="s">${display_price} USD</Text>
                      <Tooltip
                        align="left"
                        tooltip="This amount will be charged at the start of your next billing cycle, after pro-rata adjustements, credits and discounts.">
                        <Icon muted right icon="info" />
                      </Tooltip>
                    </Div>
                  </Div>
                  <Divider vertical />
                </Div>
              </GridUnit>
              <GridUnit size={1 / 3}>
                <Div h={100} flex={{ justify: 'space-between' }}>
                  <Div p={2}>
                    <Text size="s">
                      {new Date(
                        subscription_details.current_billing_period_start_date,
                      ).toLocaleDateString('en-US', {
                        weekday: undefined,
                        year: undefined,
                        month: 'short',
                        day: 'numeric',
                      })}
                      {' -> '}
                      {new Date(
                        subscription_details.current_billing_period_end_date,
                      ).toLocaleDateString('en-US', {
                        weekday: undefined,
                        year: undefined,
                        month: 'short',
                        day: 'numeric',
                      })}
                    </Text>
                  </Div>
                  <Divider vertical />
                </Div>
              </GridUnit>
              <GridUnit size={1 / 3}>
                <Div p={2}>
                  {card ? (
                    <Text size="s">
                      {capitalizeFirstLetter(card.brand)} (ending in {card.last4})
                    </Text>
                  ) : (
                    <Text muted>None</Text>
                  )}
                </Div>
              </GridUnit>
            </Grid>
          </StyledCardSection>
        </StyledCard>
        <Button.Permission
          role="admin"
          outline
          primary
          to={(location) => ({
            ...location,
            pathname: `/settings/organization/billing/payment-method`,
            state: { scroll: false },
          })}
          icon="edit">
          Edit Payment Method
        </Button.Permission>
      </Div>
      <MemoizedEventsUsage subscription_details={subscription_details} />
      <Div m={{ b: 14 }}>
        <ProjectThroughputs subscription_details={subscription_details} />
      </Div>
      {billing_email?.email && billing_email.email !== 'placeholder@hookdeck.com' && (
        <Div m={{ b: 14 }}>
          <Text heading as="h2" m={{ b: 2 }}>
            Billing Email
          </Text>
          <Divider m={{ b: 4 }} />
          <Text m={{ b: 4 }}>{billing_email.email}</Text>
          <Button.Permission
            role="admin"
            outline
            primary
            to={(location) => ({
              ...location,
              pathname: `/settings/organization/billing/email`,
              state: { scroll: false },
            })}
            icon="edit">
            Edit
          </Button.Permission>
        </Div>
      )}
    </>
  );
};

export default OrganizationBilling;
